import { IssuePriorityEnums } from '@/constants/issue'
import { PriorityEntity } from '@/repository/PriorityRepository'

export function getIconPriority(priority: PriorityEntity): {
  name: string
  icon: string
  color: string
  backgroundColor: string
  fontColor: string
} {
  switch (priority.group) {
    case IssuePriorityEnums.High:
      return {
        icon: 'mdi-arrow-up-circle-outline',
        color: '#C53127',
        backgroundColor: '#F5DDDB',
        fontColor: '#C53127',
        ...priority,
      }
    case IssuePriorityEnums.Medium:
      return {
        icon: 'mdi-minus-circle-outline',
        color: '#E4B00D',
        backgroundColor: '#F9E0A6',
        fontColor: '#0D0E0F',
        ...priority,
      }
    default:
      return {
        icon: 'mdi-arrow-down-circle-outline',
        color: '#7D8389',
        backgroundColor: '#E1E3E4',
        fontColor: '#0D0E0F',
        ...priority,
      }
  }
}
