/* tslint:disable */
/* eslint-disable */
/**
 * GEMBA Connect API
 * API reference for handling property data provided by GEMBA Connect.  # Authentication  This API is stateful. Therefore, it is necessary to call with the authentication information set in the cookie. More specifically, it authenticates using laravel_token that Laravel keeps in the cookie. 
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AttachmentFile
 */
export interface AttachmentFile {
    /**
     * 
     * @type {number}
     * @memberof AttachmentFile
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AttachmentFile
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentFile
     */
    'mime_type': string;
    /**
     * 
     * @type {number}
     * @memberof AttachmentFile
     */
    'size': number;
    /**
     * 
     * @type {number}
     * @memberof AttachmentFile
     */
    'user_id': number;
    /**
     * 
     * @type {string}
     * @memberof AttachmentFile
     */
    'content_created_at': string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentFile
     */
    'created_at': string;
    /**
     * 
     * @type {number}
     * @memberof AttachmentFile
     */
    'status': number;
    /**
     * 
     * @type {string}
     * @memberof AttachmentFile
     */
    'sas_file': string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentFile
     */
    'sas_thumbnail': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AttachmentFile
     */
    'is_protected': boolean;
    /**
     * Example: read, update, delete
     * @type {Array<string>}
     * @memberof AttachmentFile
     */
    'file_permissions': Array<string>;
}
/**
 * 
 * @export
 * @interface AttachmentOperationsRequest
 */
export interface AttachmentOperationsRequest {
    /**
     * 
     * @type {string}
     * @memberof AttachmentOperationsRequest
     */
    'uuid'?: string;
    /**
     * 
     * @type {Array<FileCreateRequest>}
     * @memberof AttachmentOperationsRequest
     */
    'create_files'?: Array<FileCreateRequest>;
    /**
     * 
     * @type {Array<number>}
     * @memberof AttachmentOperationsRequest
     */
    'delete_files'?: Array<number>;
    /**
     * 
     * @type {Array<FileUpdateRequest>}
     * @memberof AttachmentOperationsRequest
     */
    'update_files'?: Array<FileUpdateRequest>;
}
/**
 * 
 * @export
 * @interface CheckMobileClientVersionResponse
 */
export interface CheckMobileClientVersionResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CheckMobileClientVersionResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CheckMobileClientVersionResponse
     */
    'data': boolean;
}
/**
 * 
 * @export
 * @interface Comment
 */
export interface Comment {
    /**
     * 
     * @type {number}
     * @memberof Comment
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    'body': string;
    /**
     * 
     * @type {number}
     * @memberof Comment
     */
    'created_by': number;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    'updated_at': string;
    /**
     * 
     * @type {Array<AttachmentFile>}
     * @memberof Comment
     */
    'attachment_files': Array<AttachmentFile>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Comment
     */
    'mentions'?: Array<number>;
    /**
     * array of permitted operation name
     * @type {Array<string>}
     * @memberof Comment
     */
    'user_permissions': Array<string>;
}
/**
 * 
 * @export
 * @interface CommentCreateRequest
 */
export interface CommentCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof CommentCreateRequest
     */
    'body'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CommentCreateRequest
     */
    'mentions'?: Array<number>;
    /**
     * 
     * @type {AttachmentOperationsRequest}
     * @memberof CommentCreateRequest
     */
    'attachment_operations'?: AttachmentOperationsRequest;
}
/**
 * 
 * @export
 * @interface CommentListResponse
 */
export interface CommentListResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CommentListResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {Array<Comment>}
     * @memberof CommentListResponse
     */
    'data': Array<Comment>;
}
/**
 * 
 * @export
 * @interface CommentResponse
 */
export interface CommentResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CommentResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {Comment}
     * @memberof CommentResponse
     */
    'data': Comment;
}
/**
 * 
 * @export
 * @interface CommentUpdateRequest
 */
export interface CommentUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof CommentUpdateRequest
     */
    'body'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CommentUpdateRequest
     */
    'mentions'?: Array<number>;
    /**
     * 
     * @type {AttachmentOperationsRequest}
     * @memberof CommentUpdateRequest
     */
    'attachment_operations'?: AttachmentOperationsRequest;
}
/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ErrorResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {Error}
     * @memberof ErrorResponse
     */
    'error': Error;
}
/**
 * 
 * @export
 * @interface Facility
 */
export interface Facility {
    /**
     * 
     * @type {number}
     * @memberof Facility
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    'facility_code': string;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof Facility
     */
    'type': number;
    /**
     * 
     * @type {IssueAttributes}
     * @memberof Facility
     */
    'attributes': IssueAttributes;
    /**
     * 
     * @type {Array<number>}
     * @memberof Facility
     */
    'assignees'?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Facility
     */
    'user_permissions': Array<string>;
    /**
     * YYYY-MM-DD HH:mm:ssZ
     * @type {string}
     * @memberof Facility
     */
    'created_at': string;
    /**
     * YYYY-MM-DD HH:mm:ssZ
     * @type {string}
     * @memberof Facility
     */
    'updated_at': string;
    /**
     * user id
     * @type {number}
     * @memberof Facility
     */
    'created_by': number;
    /**
     * user id
     * @type {number}
     * @memberof Facility
     */
    'updated_by': number;
    /**
     * 
     * @type {FacilityUpdatedStatus}
     * @memberof Facility
     */
    'facility_updated_status'?: FacilityUpdatedStatus;
    /**
     * 
     * @type {FacilityAllOfLinkedFloorPlan}
     * @memberof Facility
     */
    'linked_floor_plan'?: FacilityAllOfLinkedFloorPlan;
    /**
     * 
     * @type {FloorIssueAllOfLinkedShotPoint}
     * @memberof Facility
     */
    'linked_shot_point'?: FloorIssueAllOfLinkedShotPoint;
}
/**
 * 
 * @export
 * @interface FacilityAllOf
 */
export interface FacilityAllOf {
    /**
     * 
     * @type {FacilityAllOfLinkedFloorPlan}
     * @memberof FacilityAllOf
     */
    'linked_floor_plan'?: FacilityAllOfLinkedFloorPlan;
}
/**
 * 
 * @export
 * @interface FacilityAllOfLinkedFloorPlan
 */
export interface FacilityAllOfLinkedFloorPlan {
    /**
     * 
     * @type {number}
     * @memberof FacilityAllOfLinkedFloorPlan
     */
    'floor_plan_id': number;
}
/**
 * 
 * @export
 * @interface FacilityBase
 */
export interface FacilityBase {
    /**
     * 
     * @type {number}
     * @memberof FacilityBase
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FacilityBase
     */
    'facility_code': string;
    /**
     * 
     * @type {string}
     * @memberof FacilityBase
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof FacilityBase
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof FacilityBase
     */
    'type': number;
    /**
     * 
     * @type {IssueAttributes}
     * @memberof FacilityBase
     */
    'attributes': IssueAttributes;
    /**
     * 
     * @type {Array<number>}
     * @memberof FacilityBase
     */
    'assignees'?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FacilityBase
     */
    'user_permissions': Array<string>;
    /**
     * YYYY-MM-DD HH:mm:ssZ
     * @type {string}
     * @memberof FacilityBase
     */
    'created_at': string;
    /**
     * YYYY-MM-DD HH:mm:ssZ
     * @type {string}
     * @memberof FacilityBase
     */
    'updated_at': string;
    /**
     * user id
     * @type {number}
     * @memberof FacilityBase
     */
    'created_by': number;
    /**
     * user id
     * @type {number}
     * @memberof FacilityBase
     */
    'updated_by': number;
    /**
     * 
     * @type {FacilityUpdatedStatus}
     * @memberof FacilityBase
     */
    'facility_updated_status'?: FacilityUpdatedStatus;
}
/**
 * 
 * @export
 * @interface FacilityCreateRequest
 */
export interface FacilityCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof FacilityCreateRequest
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof FacilityCreateRequest
     */
    'type': number;
    /**
     * 
     * @type {string}
     * @memberof FacilityCreateRequest
     */
    'description': string;
    /**
     * 
     * @type {IssueCreateRequestAttributes}
     * @memberof FacilityCreateRequest
     */
    'attributes': IssueCreateRequestAttributes;
    /**
     * 
     * @type {Array<number>}
     * @memberof FacilityCreateRequest
     */
    'assignees'?: Array<number>;
    /**
     * 
     * @type {AttachmentOperationsRequest}
     * @memberof FacilityCreateRequest
     */
    'attachment_operations'?: AttachmentOperationsRequest;
    /**
     * 
     * @type {LinkedPanoramaCreateRequest}
     * @memberof FacilityCreateRequest
     */
    'linked_panorama_operation'?: LinkedPanoramaCreateRequest;
    /**
     * 
     * @type {LinkedFloorPlanCreateRequest}
     * @memberof FacilityCreateRequest
     */
    'linked_floor_plan_operation'?: LinkedFloorPlanCreateRequest;
}
/**
 * 
 * @export
 * @interface FacilityDetail
 */
export interface FacilityDetail {
    /**
     * 
     * @type {number}
     * @memberof FacilityDetail
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FacilityDetail
     */
    'facility_code': string;
    /**
     * 
     * @type {string}
     * @memberof FacilityDetail
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof FacilityDetail
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof FacilityDetail
     */
    'type': number;
    /**
     * 
     * @type {IssueAttributes}
     * @memberof FacilityDetail
     */
    'attributes': IssueAttributes;
    /**
     * 
     * @type {Array<number>}
     * @memberof FacilityDetail
     */
    'assignees'?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FacilityDetail
     */
    'user_permissions': Array<string>;
    /**
     * YYYY-MM-DD HH:mm:ssZ
     * @type {string}
     * @memberof FacilityDetail
     */
    'created_at': string;
    /**
     * YYYY-MM-DD HH:mm:ssZ
     * @type {string}
     * @memberof FacilityDetail
     */
    'updated_at': string;
    /**
     * user id
     * @type {number}
     * @memberof FacilityDetail
     */
    'created_by': number;
    /**
     * user id
     * @type {number}
     * @memberof FacilityDetail
     */
    'updated_by': number;
    /**
     * 
     * @type {FacilityUpdatedStatus}
     * @memberof FacilityDetail
     */
    'facility_updated_status'?: FacilityUpdatedStatus;
    /**
     * 
     * @type {Array<AttachmentFile>}
     * @memberof FacilityDetail
     */
    'attachment_files': Array<AttachmentFile>;
    /**
     * 
     * @type {LinkedPanorama}
     * @memberof FacilityDetail
     */
    'linked_panorama'?: LinkedPanorama;
    /**
     * 
     * @type {LinkedFloorPlan}
     * @memberof FacilityDetail
     */
    'linked_floor_plan'?: LinkedFloorPlan;
    /**
     * 
     * @type {FacilityDetailAllOfLinkedShotPoint}
     * @memberof FacilityDetail
     */
    'linked_shot_point'?: FacilityDetailAllOfLinkedShotPoint;
}
/**
 * 
 * @export
 * @interface FacilityDetailAllOf
 */
export interface FacilityDetailAllOf {
    /**
     * 
     * @type {FacilityDetailAllOfLinkedShotPoint}
     * @memberof FacilityDetailAllOf
     */
    'linked_shot_point'?: FacilityDetailAllOfLinkedShotPoint;
}
/**
 * 
 * @export
 * @interface FacilityDetailAllOfLinkedShotPoint
 */
export interface FacilityDetailAllOfLinkedShotPoint {
    /**
     * 
     * @type {number}
     * @memberof FacilityDetailAllOfLinkedShotPoint
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface FacilityDetailListResponse
 */
export interface FacilityDetailListResponse {
    /**
     * 
     * @type {boolean}
     * @memberof FacilityDetailListResponse
     */
    'ok'?: boolean;
    /**
     * 
     * @type {Array<FacilityDetail>}
     * @memberof FacilityDetailListResponse
     */
    'data'?: Array<FacilityDetail>;
}
/**
 * 
 * @export
 * @interface FacilityDetailResponse
 */
export interface FacilityDetailResponse {
    /**
     * 
     * @type {boolean}
     * @memberof FacilityDetailResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {FacilityDetail}
     * @memberof FacilityDetailResponse
     */
    'data': FacilityDetail;
}
/**
 * 
 * @export
 * @interface FacilityListResponse
 */
export interface FacilityListResponse {
    /**
     * 
     * @type {boolean}
     * @memberof FacilityListResponse
     */
    'ok'?: boolean;
    /**
     * 
     * @type {Array<Facility>}
     * @memberof FacilityListResponse
     */
    'data'?: Array<Facility>;
    /**
     * 
     * @type {Pagination}
     * @memberof FacilityListResponse
     */
    'pagination'?: Pagination;
}
/**
 * 
 * @export
 * @interface FacilityPatchRequest
 */
export interface FacilityPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof FacilityPatchRequest
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof FacilityPatchRequest
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof FacilityPatchRequest
     */
    'type'?: number;
    /**
     * 
     * @type {IssueUpdateRequestAttributes}
     * @memberof FacilityPatchRequest
     */
    'attributes'?: IssueUpdateRequestAttributes;
    /**
     * 
     * @type {Array<number>}
     * @memberof FacilityPatchRequest
     */
    'assignees'?: Array<number>;
    /**
     * 
     * @type {AttachmentOperationsRequest}
     * @memberof FacilityPatchRequest
     */
    'attachment_operations'?: AttachmentOperationsRequest;
    /**
     * 
     * @type {LinkedPanoramaUpdateRequest}
     * @memberof FacilityPatchRequest
     */
    'linked_panorama_operation'?: LinkedPanoramaUpdateRequest;
    /**
     * 
     * @type {LinkedFloorPlanUpdateRequest}
     * @memberof FacilityPatchRequest
     */
    'linked_floor_plan_operation'?: LinkedFloorPlanUpdateRequest;
}
/**
 * 
 * @export
 * @interface FacilityResponse
 */
export interface FacilityResponse {
    /**
     * 
     * @type {boolean}
     * @memberof FacilityResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {Facility}
     * @memberof FacilityResponse
     */
    'data': Facility;
}
/**
 * 
 * @export
 * @interface FacilityType
 */
export interface FacilityType {
    /**
     * 
     * @type {number}
     * @memberof FacilityType
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof FacilityType
     */
    'name': string;
    /**
     * 4 chars (info, chck, warn)
     * @type {string}
     * @memberof FacilityType
     */
    'kind': string;
    /**
     * 
     * @type {FacilityTypeStyle}
     * @memberof FacilityType
     */
    'style': FacilityTypeStyle;
    /**
     * 
     * @type {number}
     * @memberof FacilityType
     */
    'order': number;
}
/**
 * 
 * @export
 * @interface FacilityTypeListResponse
 */
export interface FacilityTypeListResponse {
    /**
     * 
     * @type {boolean}
     * @memberof FacilityTypeListResponse
     */
    'ok'?: boolean;
    /**
     * 
     * @type {Array<FacilityType>}
     * @memberof FacilityTypeListResponse
     */
    'data'?: Array<FacilityType>;
}
/**
 * 
 * @export
 * @interface FacilityTypeStyle
 */
export interface FacilityTypeStyle {
    /**
     * 
     * @type {string}
     * @memberof FacilityTypeStyle
     */
    'background_color': string;
    /**
     * 
     * @type {string}
     * @memberof FacilityTypeStyle
     */
    'font_color': string;
}
/**
 * 
 * @export
 * @interface FacilityUpdatedStatus
 */
export interface FacilityUpdatedStatus {
    /**
     * 
     * @type {number}
     * @memberof FacilityUpdatedStatus
     */
    'facility_id': number;
    /**
     * YYYY-MM-DD HH:mm:ssZ
     * @type {string}
     * @memberof FacilityUpdatedStatus
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface FileCreateRequest
 */
export interface FileCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof FileCreateRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FileCreateRequest
     */
    'path': string;
    /**
     * 
     * @type {boolean}
     * @memberof FileCreateRequest
     */
    'is_protected': boolean;
}
/**
 * 
 * @export
 * @interface FileUpdateRequest
 */
export interface FileUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof FileUpdateRequest
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof FileUpdateRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileUpdateRequest
     */
    'path'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FileUpdateRequest
     */
    'is_protected'?: boolean;
}
/**
 * 
 * @export
 * @interface Floor
 */
export interface Floor {
    /**
     * 
     * @type {number}
     * @memberof Floor
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Floor
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof Floor
     */
    'order': number;
    /**
     * 
     * @type {FloorPlan}
     * @memberof Floor
     */
    'floor_plan'?: FloorPlan;
    /**
     * If there is floorplan, shotpointcount will be returned
     * @type {number}
     * @memberof Floor
     */
    'shot_points_count'?: number | null;
}
/**
 * 
 * @export
 * @interface FloorCreateRequest
 */
export interface FloorCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof FloorCreateRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof FloorCreateRequest
     */
    'order': number;
}
/**
 * 
 * @export
 * @interface FloorDetail
 */
export interface FloorDetail {
    /**
     * 
     * @type {number}
     * @memberof FloorDetail
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof FloorDetail
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof FloorDetail
     */
    'order': number;
    /**
     * 
     * @type {FloorPlan}
     * @memberof FloorDetail
     */
    'floor_plan'?: FloorPlan;
    /**
     * If there is floorplan, shotpointcount will be returned
     * @type {number}
     * @memberof FloorDetail
     */
    'shot_points_count'?: number | null;
    /**
     * 
     * @type {Array<ShotPoint>}
     * @memberof FloorDetail
     */
    'shot_points': Array<ShotPoint>;
}
/**
 * 
 * @export
 * @interface FloorDetailAllOf
 */
export interface FloorDetailAllOf {
    /**
     * 
     * @type {Array<ShotPoint>}
     * @memberof FloorDetailAllOf
     */
    'shot_points': Array<ShotPoint>;
}
/**
 * 
 * @export
 * @interface FloorFacility
 */
export interface FloorFacility {
    /**
     * 
     * @type {number}
     * @memberof FloorFacility
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FloorFacility
     */
    'facility_code': string;
    /**
     * 
     * @type {string}
     * @memberof FloorFacility
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof FloorFacility
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof FloorFacility
     */
    'type': number;
    /**
     * 
     * @type {IssueAttributes}
     * @memberof FloorFacility
     */
    'attributes': IssueAttributes;
    /**
     * 
     * @type {Array<number>}
     * @memberof FloorFacility
     */
    'assignees'?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FloorFacility
     */
    'user_permissions': Array<string>;
    /**
     * YYYY-MM-DD HH:mm:ssZ
     * @type {string}
     * @memberof FloorFacility
     */
    'created_at': string;
    /**
     * YYYY-MM-DD HH:mm:ssZ
     * @type {string}
     * @memberof FloorFacility
     */
    'updated_at': string;
    /**
     * user id
     * @type {number}
     * @memberof FloorFacility
     */
    'created_by': number;
    /**
     * user id
     * @type {number}
     * @memberof FloorFacility
     */
    'updated_by': number;
    /**
     * 
     * @type {FacilityUpdatedStatus}
     * @memberof FloorFacility
     */
    'facility_updated_status'?: FacilityUpdatedStatus;
    /**
     * 
     * @type {LinkedFloorPlan}
     * @memberof FloorFacility
     */
    'linked_floor_plan'?: LinkedFloorPlan;
    /**
     * 
     * @type {FacilityDetailAllOfLinkedShotPoint}
     * @memberof FloorFacility
     */
    'linked_shot_point'?: FacilityDetailAllOfLinkedShotPoint;
}
/**
 * 
 * @export
 * @interface FloorIssue
 */
export interface FloorIssue {
    /**
     * 
     * @type {number}
     * @memberof FloorIssue
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof FloorIssue
     */
    'issue_code': string;
    /**
     * 
     * @type {number}
     * @memberof FloorIssue
     */
    'issue_group_id': number;
    /**
     * 
     * @type {string}
     * @memberof FloorIssue
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof FloorIssue
     */
    'description': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof FloorIssue
     */
    'assignees': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof FloorIssue
     */
    'notification_targets'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof FloorIssue
     */
    'due_date': string | null;
    /**
     * 0: ラベルなし, 1: まもなく締め切り, 2: 期限切れ
     * @type {number}
     * @memberof FloorIssue
     */
    'due_date_alert': number | null;
    /**
     * 
     * @type {number}
     * @memberof FloorIssue
     */
    'status': number;
    /**
     * 
     * @type {number}
     * @memberof FloorIssue
     */
    'priority'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FloorIssue
     */
    'is_bookmarked': boolean;
    /**
     * ID of the person who created the issue.
     * @type {number}
     * @memberof FloorIssue
     */
    'created_by': number;
    /**
     * ID of the person who updated the issue.
     * @type {number}
     * @memberof FloorIssue
     */
    'updated_by': number;
    /**
     * YYYY-MM-DD HH:mm:ssZ
     * @type {string}
     * @memberof FloorIssue
     */
    'created_at': string;
    /**
     * YYYY-MM-DD HH:mm:ssZ
     * @type {string}
     * @memberof FloorIssue
     */
    'updated_at': string;
    /**
     * 
     * @type {IssueAttributes}
     * @memberof FloorIssue
     */
    'attributes': IssueAttributes;
    /**
     * array of permitted operation name
     * @type {Array<string>}
     * @memberof FloorIssue
     */
    'user_permissions': Array<string>;
    /**
     * 
     * @type {IssueUpdatedStatus}
     * @memberof FloorIssue
     */
    'issue_updated_status'?: IssueUpdatedStatus;
    /**
     * 
     * @type {FloorIssueAllOfLinkedShotPoint}
     * @memberof FloorIssue
     */
    'linked_shot_point'?: FloorIssueAllOfLinkedShotPoint;
    /**
     * 
     * @type {LinkedFloorPlan}
     * @memberof FloorIssue
     */
    'linked_floor_plan'?: LinkedFloorPlan;
}
/**
 * 
 * @export
 * @interface FloorIssueAllOf
 */
export interface FloorIssueAllOf {
    /**
     * 
     * @type {FloorIssueAllOfLinkedShotPoint}
     * @memberof FloorIssueAllOf
     */
    'linked_shot_point'?: FloorIssueAllOfLinkedShotPoint;
}
/**
 * 
 * @export
 * @interface FloorIssueAllOfLinkedShotPoint
 */
export interface FloorIssueAllOfLinkedShotPoint {
    /**
     * 
     * @type {number}
     * @memberof FloorIssueAllOfLinkedShotPoint
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface FloorIssueResponse
 */
export interface FloorIssueResponse {
    /**
     * 
     * @type {boolean}
     * @memberof FloorIssueResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {Array<FloorIssue>}
     * @memberof FloorIssueResponse
     */
    'data': Array<FloorIssue>;
}
/**
 * 
 * @export
 * @interface FloorOperationsRequest
 */
export interface FloorOperationsRequest {
    /**
     * 
     * @type {Array<FloorCreateRequest>}
     * @memberof FloorOperationsRequest
     */
    'create_floors'?: Array<FloorCreateRequest>;
    /**
     * 
     * @type {Array<number>}
     * @memberof FloorOperationsRequest
     */
    'delete_floors'?: Array<number>;
    /**
     * 
     * @type {Array<FloorUpdateRequest>}
     * @memberof FloorOperationsRequest
     */
    'update_floors'?: Array<FloorUpdateRequest>;
}
/**
 * 
 * @export
 * @interface FloorPlan
 */
export interface FloorPlan {
    /**
     * 
     * @type {number}
     * @memberof FloorPlan
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof FloorPlan
     */
    'mime_type': string;
    /**
     * 
     * @type {number}
     * @memberof FloorPlan
     */
    'size': number;
    /**
     * 
     * @type {number}
     * @memberof FloorPlan
     */
    'user_id': number;
    /**
     * 
     * @type {string}
     * @memberof FloorPlan
     */
    'content_created_at': string;
    /**
     * 
     * @type {string}
     * @memberof FloorPlan
     */
    'created_at': string;
    /**
     * 
     * @type {number}
     * @memberof FloorPlan
     */
    'status': number;
    /**
     * 
     * @type {string}
     * @memberof FloorPlan
     */
    'sas_file': string;
    /**
     * 
     * @type {string}
     * @memberof FloorPlan
     */
    'sas_thumbnail': string;
    /**
     * 
     * @type {number}
     * @memberof FloorPlan
     */
    'width': number | null;
    /**
     * 
     * @type {number}
     * @memberof FloorPlan
     */
    'height': number | null;
}
/**
 * 
 * @export
 * @interface FloorPlanListResponse
 */
export interface FloorPlanListResponse {
    /**
     * 
     * @type {boolean}
     * @memberof FloorPlanListResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {Array<Floor>}
     * @memberof FloorPlanListResponse
     */
    'data': Array<Floor>;
}
/**
 * 
 * @export
 * @interface FloorPlanObject
 */
export interface FloorPlanObject {
    /**
     * 
     * @type {number}
     * @memberof FloorPlanObject
     */
    'id': number;
    /**
     * 
     * @type {LinkedFloorPlanCoordinate}
     * @memberof FloorPlanObject
     */
    'coordinate': LinkedFloorPlanCoordinate;
    /**
     * 
     * @type {LinkedItemContent}
     * @memberof FloorPlanObject
     */
    'content': LinkedItemContent;
}
/**
 * 
 * @export
 * @interface FloorPlansPatchRequest
 */
export interface FloorPlansPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof FloorPlansPatchRequest
     */
    'uuid': string;
    /**
     * 
     * @type {Array<FloorPlansPatchRequestFloorsInner>}
     * @memberof FloorPlansPatchRequest
     */
    'floors': Array<FloorPlansPatchRequestFloorsInner>;
}
/**
 * 
 * @export
 * @interface FloorPlansPatchRequestFloorsInner
 */
export interface FloorPlansPatchRequestFloorsInner {
    /**
     * 
     * @type {number}
     * @memberof FloorPlansPatchRequestFloorsInner
     */
    'id': number;
    /**
     * 
     * @type {FloorPlansPatchRequestFloorsInnerFloorPlan}
     * @memberof FloorPlansPatchRequestFloorsInner
     */
    'floor_plan': FloorPlansPatchRequestFloorsInnerFloorPlan | null;
}
/**
 * 
 * @export
 * @interface FloorPlansPatchRequestFloorsInnerFloorPlan
 */
export interface FloorPlansPatchRequestFloorsInnerFloorPlan {
    /**
     * 
     * @type {string}
     * @memberof FloorPlansPatchRequestFloorsInnerFloorPlan
     */
    'path'?: string;
}
/**
 * 
 * @export
 * @interface FloorResponse
 */
export interface FloorResponse {
    /**
     * 
     * @type {boolean}
     * @memberof FloorResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {FloorDetail}
     * @memberof FloorResponse
     */
    'data': FloorDetail;
}
/**
 * 
 * @export
 * @interface FloorUpdateRequest
 */
export interface FloorUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof FloorUpdateRequest
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof FloorUpdateRequest
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof FloorUpdateRequest
     */
    'order'?: number;
}
/**
 * 
 * @export
 * @interface GetDocumentResponse
 */
export interface GetDocumentResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetDocumentResponse
     */
    'ok'?: boolean;
    /**
     * 
     * @type {GetDocumentResponseData}
     * @memberof GetDocumentResponse
     */
    'data'?: GetDocumentResponseData;
}
/**
 * 
 * @export
 * @interface GetDocumentResponseData
 */
export interface GetDocumentResponseData {
    /**
     * 
     * @type {string}
     * @memberof GetDocumentResponseData
     */
    'sas_file': string;
}
/**
 * 
 * @export
 * @interface GlobalFacilitySetting
 */
export interface GlobalFacilitySetting {
    /**
     * 
     * @type {string}
     * @memberof GlobalFacilitySetting
     */
    'prefix': string;
}
/**
 * 
 * @export
 * @interface GlobalIssueSetting
 */
export interface GlobalIssueSetting {
    /**
     * 
     * @type {string}
     * @memberof GlobalIssueSetting
     */
    'prefix': string;
    /**
     * 
     * @type {string}
     * @memberof GlobalIssueSetting
     */
    'protected_file_alias': string;
}
/**
 * 
 * @export
 * @interface GlobalIssueSettingResponse
 */
export interface GlobalIssueSettingResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GlobalIssueSettingResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {GlobalIssueSetting}
     * @memberof GlobalIssueSettingResponse
     */
    'data': GlobalIssueSetting;
}
/**
 * 
 * @export
 * @interface GlobalIssueSettingUpdateRequest
 */
export interface GlobalIssueSettingUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof GlobalIssueSettingUpdateRequest
     */
    'prefix'?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalIssueSettingUpdateRequest
     */
    'protected_file_alias'?: string;
}
/**
 * 
 * @export
 * @interface GlobalSettingPatchRequest
 */
export interface GlobalSettingPatchRequest {
    /**
     * 
     * @type {GlobalSettingPatchRequestIssue}
     * @memberof GlobalSettingPatchRequest
     */
    'issue'?: GlobalSettingPatchRequestIssue;
    /**
     * 
     * @type {GlobalSettingPatchRequestFacility}
     * @memberof GlobalSettingPatchRequest
     */
    'facility'?: GlobalSettingPatchRequestFacility;
}
/**
 * 
 * @export
 * @interface GlobalSettingPatchRequestFacility
 */
export interface GlobalSettingPatchRequestFacility {
    /**
     * 
     * @type {string}
     * @memberof GlobalSettingPatchRequestFacility
     */
    'prefix'?: string;
}
/**
 * 
 * @export
 * @interface GlobalSettingPatchRequestIssue
 */
export interface GlobalSettingPatchRequestIssue {
    /**
     * 
     * @type {string}
     * @memberof GlobalSettingPatchRequestIssue
     */
    'prefix'?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalSettingPatchRequestIssue
     */
    'protected_file_alias'?: string;
}
/**
 * 
 * @export
 * @interface GlobalSettingResponse
 */
export interface GlobalSettingResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GlobalSettingResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {GlobalSettingResponseData}
     * @memberof GlobalSettingResponse
     */
    'data': GlobalSettingResponseData;
}
/**
 * 
 * @export
 * @interface GlobalSettingResponseData
 */
export interface GlobalSettingResponseData {
    /**
     * 
     * @type {GlobalIssueSetting}
     * @memberof GlobalSettingResponseData
     */
    'issue': GlobalIssueSetting;
    /**
     * 
     * @type {GlobalFacilitySetting}
     * @memberof GlobalSettingResponseData
     */
    'facility': GlobalFacilitySetting;
}
/**
 * 
 * @export
 * @interface ImageFile
 */
export interface ImageFile {
    /**
     * 
     * @type {number}
     * @memberof ImageFile
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ImageFile
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ImageFile
     */
    'mime_type': string;
    /**
     * 
     * @type {number}
     * @memberof ImageFile
     */
    'size': number;
    /**
     * 
     * @type {number}
     * @memberof ImageFile
     */
    'user_id': number;
    /**
     * 
     * @type {string}
     * @memberof ImageFile
     */
    'content_created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ImageFile
     */
    'created_at': string;
    /**
     * 
     * @type {number}
     * @memberof ImageFile
     */
    'status': number;
    /**
     * 
     * @type {string}
     * @memberof ImageFile
     */
    'sas_file': string;
    /**
     * 
     * @type {string}
     * @memberof ImageFile
     */
    'sas_thumbnail': string;
    /**
     * 
     * @type {number}
     * @memberof ImageFile
     */
    'width': number | null;
    /**
     * 
     * @type {number}
     * @memberof ImageFile
     */
    'height': number | null;
    /**
     * Example: read, update, delete
     * @type {Array<string>}
     * @memberof ImageFile
     */
    'file_permissions': Array<string>;
}
/**
 * 
 * @export
 * @interface ImageOperationsRequest
 */
export interface ImageOperationsRequest {
    /**
     * 
     * @type {FileCreateRequest}
     * @memberof ImageOperationsRequest
     */
    'create_file'?: FileCreateRequest;
    /**
     * 
     * @type {number}
     * @memberof ImageOperationsRequest
     */
    'delete_file'?: number;
    /**
     * 
     * @type {FileUpdateRequest}
     * @memberof ImageOperationsRequest
     */
    'update_file'?: FileUpdateRequest;
}
/**
 * 
 * @export
 * @interface Issue
 */
export interface Issue {
    /**
     * 
     * @type {number}
     * @memberof Issue
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Issue
     */
    'issue_code': string;
    /**
     * 
     * @type {number}
     * @memberof Issue
     */
    'issue_group_id': number;
    /**
     * 
     * @type {string}
     * @memberof Issue
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Issue
     */
    'description': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof Issue
     */
    'assignees': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Issue
     */
    'notification_targets'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof Issue
     */
    'due_date': string | null;
    /**
     * 0: ラベルなし, 1: まもなく締め切り, 2: 期限切れ
     * @type {number}
     * @memberof Issue
     */
    'due_date_alert': number | null;
    /**
     * 
     * @type {number}
     * @memberof Issue
     */
    'status': number;
    /**
     * 
     * @type {number}
     * @memberof Issue
     */
    'priority'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Issue
     */
    'is_bookmarked': boolean;
    /**
     * ID of the person who created the issue.
     * @type {number}
     * @memberof Issue
     */
    'created_by': number;
    /**
     * ID of the person who updated the issue.
     * @type {number}
     * @memberof Issue
     */
    'updated_by': number;
    /**
     * YYYY-MM-DD HH:mm:ssZ
     * @type {string}
     * @memberof Issue
     */
    'created_at': string;
    /**
     * YYYY-MM-DD HH:mm:ssZ
     * @type {string}
     * @memberof Issue
     */
    'updated_at': string;
    /**
     * 
     * @type {IssueAttributes}
     * @memberof Issue
     */
    'attributes': IssueAttributes;
    /**
     * array of permitted operation name
     * @type {Array<string>}
     * @memberof Issue
     */
    'user_permissions': Array<string>;
    /**
     * 
     * @type {IssueUpdatedStatus}
     * @memberof Issue
     */
    'issue_updated_status'?: IssueUpdatedStatus;
}
/**
 * 
 * @export
 * @interface IssueAttributes
 */
export interface IssueAttributes {
    /**
     * 
     * @type {ReferredProperty}
     * @memberof IssueAttributes
     */
    'referred_property': ReferredProperty;
}
/**
 * 
 * @export
 * @interface IssueCreateRequest
 */
export interface IssueCreateRequest {
    /**
     * 
     * @type {number}
     * @memberof IssueCreateRequest
     */
    'issue_group_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IssueCreateRequest
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof IssueCreateRequest
     */
    'description'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof IssueCreateRequest
     */
    'assignees': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof IssueCreateRequest
     */
    'priority'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof IssueCreateRequest
     */
    'notification_targets'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof IssueCreateRequest
     */
    'due_date'?: string;
    /**
     * 
     * @type {IssueCreateRequestAttributes}
     * @memberof IssueCreateRequest
     */
    'attributes': IssueCreateRequestAttributes;
    /**
     * 
     * @type {AttachmentOperationsRequest}
     * @memberof IssueCreateRequest
     */
    'attachment_operations'?: AttachmentOperationsRequest;
    /**
     * 
     * @type {LinkedPanoramaCreateRequest}
     * @memberof IssueCreateRequest
     */
    'linked_panorama_operation'?: LinkedPanoramaCreateRequest;
    /**
     * 
     * @type {LinkedFloorPlanCreateRequest}
     * @memberof IssueCreateRequest
     */
    'linked_floor_plan_operation'?: LinkedFloorPlanCreateRequest;
}
/**
 * 
 * @export
 * @interface IssueCreateRequestAttributes
 */
export interface IssueCreateRequestAttributes {
    /**
     * 
     * @type {ReferredPropertyCreateRequest}
     * @memberof IssueCreateRequestAttributes
     */
    'referred_property': ReferredPropertyCreateRequest;
}
/**
 * 
 * @export
 * @interface IssueDetail
 */
export interface IssueDetail {
    /**
     * 
     * @type {number}
     * @memberof IssueDetail
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof IssueDetail
     */
    'issue_code': string;
    /**
     * 
     * @type {number}
     * @memberof IssueDetail
     */
    'issue_group_id': number;
    /**
     * 
     * @type {string}
     * @memberof IssueDetail
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof IssueDetail
     */
    'description': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof IssueDetail
     */
    'assignees': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof IssueDetail
     */
    'notification_targets'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof IssueDetail
     */
    'due_date': string | null;
    /**
     * 0: ラベルなし, 1: まもなく締め切り, 2: 期限切れ
     * @type {number}
     * @memberof IssueDetail
     */
    'due_date_alert': number | null;
    /**
     * 
     * @type {number}
     * @memberof IssueDetail
     */
    'status': number;
    /**
     * 
     * @type {number}
     * @memberof IssueDetail
     */
    'priority'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof IssueDetail
     */
    'is_bookmarked': boolean;
    /**
     * ID of the person who created the issue.
     * @type {number}
     * @memberof IssueDetail
     */
    'created_by': number;
    /**
     * ID of the person who updated the issue.
     * @type {number}
     * @memberof IssueDetail
     */
    'updated_by': number;
    /**
     * YYYY-MM-DD HH:mm:ssZ
     * @type {string}
     * @memberof IssueDetail
     */
    'created_at': string;
    /**
     * YYYY-MM-DD HH:mm:ssZ
     * @type {string}
     * @memberof IssueDetail
     */
    'updated_at': string;
    /**
     * 
     * @type {IssueAttributes}
     * @memberof IssueDetail
     */
    'attributes': IssueAttributes;
    /**
     * array of permitted operation name
     * @type {Array<string>}
     * @memberof IssueDetail
     */
    'user_permissions': Array<string>;
    /**
     * 
     * @type {IssueUpdatedStatus}
     * @memberof IssueDetail
     */
    'issue_updated_status'?: IssueUpdatedStatus;
    /**
     * 
     * @type {Array<AttachmentFile>}
     * @memberof IssueDetail
     */
    'attachment_files': Array<AttachmentFile>;
    /**
     * 
     * @type {LinkedPanorama}
     * @memberof IssueDetail
     */
    'linked_panorama'?: LinkedPanorama;
    /**
     * 
     * @type {LinkedFloorPlan}
     * @memberof IssueDetail
     */
    'linked_floor_plan'?: LinkedFloorPlan;
}
/**
 * 
 * @export
 * @interface IssueDetailAllOf
 */
export interface IssueDetailAllOf {
    /**
     * 
     * @type {Array<AttachmentFile>}
     * @memberof IssueDetailAllOf
     */
    'attachment_files': Array<AttachmentFile>;
}
/**
 * 
 * @export
 * @interface IssueDetailAllOf1
 */
export interface IssueDetailAllOf1 {
    /**
     * 
     * @type {LinkedPanorama}
     * @memberof IssueDetailAllOf1
     */
    'linked_panorama'?: LinkedPanorama;
}
/**
 * 
 * @export
 * @interface IssueDetailAllOf2
 */
export interface IssueDetailAllOf2 {
    /**
     * 
     * @type {LinkedFloorPlan}
     * @memberof IssueDetailAllOf2
     */
    'linked_floor_plan'?: LinkedFloorPlan;
}
/**
 * 
 * @export
 * @interface IssueDetailAllOf3
 */
export interface IssueDetailAllOf3 {
    /**
     * 
     * @type {IssueUpdatedStatus}
     * @memberof IssueDetailAllOf3
     */
    'issue_updated_status'?: IssueUpdatedStatus;
}
/**
 * 
 * @export
 * @interface IssueDetailResponse
 */
export interface IssueDetailResponse {
    /**
     * 
     * @type {boolean}
     * @memberof IssueDetailResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {IssueDetail}
     * @memberof IssueDetailResponse
     */
    'data': IssueDetail;
}
/**
 * 
 * @export
 * @interface IssueLinkedCount
 */
export interface IssueLinkedCount {
    /**
     * 
     * @type {number}
     * @memberof IssueLinkedCount
     */
    'floor_plan_count': number;
    /**
     * 
     * @type {Array<IssueLinkedCountShotPointsInner>}
     * @memberof IssueLinkedCount
     */
    'shot_points': Array<IssueLinkedCountShotPointsInner>;
}
/**
 * 
 * @export
 * @interface IssueLinkedCountShotPointsInner
 */
export interface IssueLinkedCountShotPointsInner {
    /**
     * 
     * @type {number}
     * @memberof IssueLinkedCountShotPointsInner
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof IssueLinkedCountShotPointsInner
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface IssueListResponse
 */
export interface IssueListResponse {
    /**
     * 
     * @type {boolean}
     * @memberof IssueListResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {Array<Issue>}
     * @memberof IssueListResponse
     */
    'data': Array<Issue>;
    /**
     * 
     * @type {Pagination}
     * @memberof IssueListResponse
     */
    'pagination': Pagination;
}
/**
 * 
 * @export
 * @interface IssueUpdateRequest
 */
export interface IssueUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof IssueUpdateRequest
     */
    'issue_group_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IssueUpdateRequest
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof IssueUpdateRequest
     */
    'description'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof IssueUpdateRequest
     */
    'assignees'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof IssueUpdateRequest
     */
    'notification_targets'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof IssueUpdateRequest
     */
    'due_date'?: string;
    /**
     * 
     * @type {number}
     * @memberof IssueUpdateRequest
     */
    'status'?: number;
    /**
     * 
     * @type {number}
     * @memberof IssueUpdateRequest
     */
    'priority'?: number;
    /**
     * 
     * @type {IssueUpdateRequestAttributes}
     * @memberof IssueUpdateRequest
     */
    'attributes'?: IssueUpdateRequestAttributes;
    /**
     * 
     * @type {AttachmentOperationsRequest}
     * @memberof IssueUpdateRequest
     */
    'attachment_operations'?: AttachmentOperationsRequest;
    /**
     * 
     * @type {LinkedPanoramaUpdateRequest}
     * @memberof IssueUpdateRequest
     */
    'linked_panorama_operation'?: LinkedPanoramaUpdateRequest;
    /**
     * 
     * @type {LinkedFloorPlanUpdateRequest}
     * @memberof IssueUpdateRequest
     */
    'linked_floor_plan_operation'?: LinkedFloorPlanUpdateRequest;
}
/**
 * 
 * @export
 * @interface IssueUpdateRequestAttributes
 */
export interface IssueUpdateRequestAttributes {
    /**
     * 
     * @type {ReferredPropertyUpdateRequest}
     * @memberof IssueUpdateRequestAttributes
     */
    'referred_property'?: ReferredPropertyUpdateRequest;
}
/**
 * 
 * @export
 * @interface IssueUpdatedStatus
 */
export interface IssueUpdatedStatus {
    /**
     * 
     * @type {number}
     * @memberof IssueUpdatedStatus
     */
    'issue_id': number;
    /**
     * YYYY-MM-DD HH:mm:ssZ
     * @type {string}
     * @memberof IssueUpdatedStatus
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface LinkedFloorPlan
 */
export interface LinkedFloorPlan {
    /**
     * 
     * @type {number}
     * @memberof LinkedFloorPlan
     */
    'floor_plan_id': number;
    /**
     * 
     * @type {number}
     * @memberof LinkedFloorPlan
     */
    'floor_plan_object_id': number;
    /**
     * 
     * @type {LinkedFloorPlanCoordinate}
     * @memberof LinkedFloorPlan
     */
    'coordinate': LinkedFloorPlanCoordinate;
}
/**
 * 
 * @export
 * @interface LinkedFloorPlanCoordinate
 */
export interface LinkedFloorPlanCoordinate {
    /**
     * 
     * @type {number}
     * @memberof LinkedFloorPlanCoordinate
     */
    'latitude': number;
    /**
     * 
     * @type {number}
     * @memberof LinkedFloorPlanCoordinate
     */
    'longitude': number;
}
/**
 * 
 * @export
 * @interface LinkedFloorPlanCreateRequest
 */
export interface LinkedFloorPlanCreateRequest {
    /**
     * 
     * @type {LinkedFloorPlanUpdateRequestLink}
     * @memberof LinkedFloorPlanCreateRequest
     */
    'link'?: LinkedFloorPlanUpdateRequestLink;
}
/**
 * 
 * @export
 * @interface LinkedFloorPlanUpdateRequest
 */
export interface LinkedFloorPlanUpdateRequest {
    /**
     * 
     * @type {LinkedFloorPlanUpdateRequestLink}
     * @memberof LinkedFloorPlanUpdateRequest
     */
    'link'?: LinkedFloorPlanUpdateRequestLink;
    /**
     * 
     * @type {LinkedFloorPlanUpdateRequestPatch}
     * @memberof LinkedFloorPlanUpdateRequest
     */
    'patch'?: LinkedFloorPlanUpdateRequestPatch;
    /**
     * 
     * @type {boolean}
     * @memberof LinkedFloorPlanUpdateRequest
     */
    'unlink'?: boolean;
}
/**
 * 
 * @export
 * @interface LinkedFloorPlanUpdateRequestLink
 */
export interface LinkedFloorPlanUpdateRequestLink {
    /**
     * 
     * @type {number}
     * @memberof LinkedFloorPlanUpdateRequestLink
     */
    'floor_plan_id': number;
    /**
     * 
     * @type {MapCoordinate}
     * @memberof LinkedFloorPlanUpdateRequestLink
     */
    'coordinate': MapCoordinate;
}
/**
 * 
 * @export
 * @interface LinkedFloorPlanUpdateRequestPatch
 */
export interface LinkedFloorPlanUpdateRequestPatch {
    /**
     * 
     * @type {MapCoordinate}
     * @memberof LinkedFloorPlanUpdateRequestPatch
     */
    'coordinate': MapCoordinate;
}
/**
 * 
 * @export
 * @interface LinkedItemContent
 */
export interface LinkedItemContent {
    /**
     * 
     * @type {string}
     * @memberof LinkedItemContent
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof LinkedItemContent
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof LinkedItemContent
     */
    'resource_id': string;
    /**
     * for issue = issue status | for facility = facility type
     * @type {number}
     * @memberof LinkedItemContent
     */
    'category': number;
    /**
     * 
     * @type {string}
     * @memberof LinkedItemContent
     */
    'type': LinkedItemContentTypeEnum;
}

export const LinkedItemContentTypeEnum = {
    Issue: 'issue',
    Facility: 'facility'
} as const;

export type LinkedItemContentTypeEnum = typeof LinkedItemContentTypeEnum[keyof typeof LinkedItemContentTypeEnum];

/**
 * 
 * @export
 * @interface LinkedPanorama
 */
export interface LinkedPanorama {
    /**
     * 
     * @type {number}
     * @memberof LinkedPanorama
     */
    'shot_point_id': number;
    /**
     * 
     * @type {number}
     * @memberof LinkedPanorama
     */
    'pvr_object_id': number;
    /**
     * 
     * @type {VrCoordinates}
     * @memberof LinkedPanorama
     */
    'vr_coordinates': VrCoordinates;
    /**
     * 
     * @type {Panorama}
     * @memberof LinkedPanorama
     */
    'latest_panorama': Panorama;
}
/**
 * 
 * @export
 * @interface LinkedPanoramaCreateRequest
 */
export interface LinkedPanoramaCreateRequest {
    /**
     * 
     * @type {LinkedPanoramaUpdateRequestLink}
     * @memberof LinkedPanoramaCreateRequest
     */
    'link': LinkedPanoramaUpdateRequestLink;
}
/**
 * 
 * @export
 * @interface LinkedPanoramaUpdateRequest
 */
export interface LinkedPanoramaUpdateRequest {
    /**
     * 
     * @type {LinkedPanoramaUpdateRequestLink}
     * @memberof LinkedPanoramaUpdateRequest
     */
    'link'?: LinkedPanoramaUpdateRequestLink;
    /**
     * 
     * @type {LinkedPanoramaUpdateRequestPatch}
     * @memberof LinkedPanoramaUpdateRequest
     */
    'patch'?: LinkedPanoramaUpdateRequestPatch;
    /**
     * 
     * @type {boolean}
     * @memberof LinkedPanoramaUpdateRequest
     */
    'unlink'?: boolean;
}
/**
 * 
 * @export
 * @interface LinkedPanoramaUpdateRequestLink
 */
export interface LinkedPanoramaUpdateRequestLink {
    /**
     * 
     * @type {number}
     * @memberof LinkedPanoramaUpdateRequestLink
     */
    'shot_point_id': number;
    /**
     * 
     * @type {VrCoordinates}
     * @memberof LinkedPanoramaUpdateRequestLink
     */
    'vr_coordinates': VrCoordinates;
}
/**
 * 
 * @export
 * @interface LinkedPanoramaUpdateRequestPatch
 */
export interface LinkedPanoramaUpdateRequestPatch {
    /**
     * 
     * @type {VrCoordinates}
     * @memberof LinkedPanoramaUpdateRequestPatch
     */
    'vr_coordinates': VrCoordinates;
}
/**
 * 
 * @export
 * @interface LinkedShotPoint
 */
export interface LinkedShotPoint {
    /**
     * 
     * @type {number}
     * @memberof LinkedShotPoint
     */
    'shot_point_id': number;
    /**
     * 
     * @type {number}
     * @memberof LinkedShotPoint
     */
    'pvr_object_id': number;
    /**
     * 
     * @type {VrCoordinates}
     * @memberof LinkedShotPoint
     */
    'vr_coordinates': VrCoordinates;
}
/**
 * 
 * @export
 * @interface ListFloorFacilityResponse
 */
export interface ListFloorFacilityResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ListFloorFacilityResponse
     */
    'ok'?: boolean;
    /**
     * 
     * @type {Array<FloorFacility>}
     * @memberof ListFloorFacilityResponse
     */
    'data'?: Array<FloorFacility>;
}
/**
 * 
 * @export
 * @interface ListFloorObjectsReponse
 */
export interface ListFloorObjectsReponse {
    /**
     * 
     * @type {boolean}
     * @memberof ListFloorObjectsReponse
     */
    'ok': boolean;
    /**
     * 
     * @type {Array<FloorPlanObject>}
     * @memberof ListFloorObjectsReponse
     */
    'data': Array<FloorPlanObject>;
}
/**
 * 
 * @export
 * @interface MapCoordinate
 */
export interface MapCoordinate {
    /**
     * 
     * @type {number}
     * @memberof MapCoordinate
     */
    'latitude': number;
    /**
     * 
     * @type {number}
     * @memberof MapCoordinate
     */
    'longitude': number;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'code': number;
}
/**
 * 
 * @export
 * @interface NoneDataResponse
 */
export interface NoneDataResponse {
    /**
     * 
     * @type {boolean}
     * @memberof NoneDataResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {any}
     * @memberof NoneDataResponse
     */
    'data': any | null;
}
/**
 * 
 * @export
 * @interface NotificationKeys
 */
export interface NotificationKeys {
    /**
     * POST|DELETE device-tokens
     * @type {string}
     * @memberof NotificationKeys
     */
    'device_token'?: string;
    /**
     * GET|POST notifications
     * @type {string}
     * @memberof NotificationKeys
     */
    'notification'?: string;
    /**
     * GET|PUT users/{userId:int}/notifications
     * @type {string}
     * @memberof NotificationKeys
     */
    'user_notification'?: string;
}
/**
 * 
 * @export
 * @interface NotificationKeysResponse
 */
export interface NotificationKeysResponse {
    /**
     * 
     * @type {boolean}
     * @memberof NotificationKeysResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {NotificationKeys}
     * @memberof NotificationKeysResponse
     */
    'data': NotificationKeys;
}
/**
 * 
 * @export
 * @interface NotificationSettings
 */
export interface NotificationSettings {
    /**
     * 
     * @type {NotificationSettingsType}
     * @memberof NotificationSettings
     */
    'type': NotificationSettingsType;
    /**
     * 
     * @type {NotificationSettingsEvent}
     * @memberof NotificationSettings
     */
    'event': NotificationSettingsEvent;
    /**
     * 
     * @type {NotificationSettingsTimeTriggerTarget}
     * @memberof NotificationSettings
     */
    'time_trigger_target': NotificationSettingsTimeTriggerTarget;
}
/**
 * 
 * @export
 * @interface NotificationSettingsEvent
 */
export interface NotificationSettingsEvent {
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettingsEvent
     */
    'when_assigned_property': boolean;
}
/**
 * 
 * @export
 * @interface NotificationSettingsResponse
 */
export interface NotificationSettingsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettingsResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {NotificationSettings}
     * @memberof NotificationSettingsResponse
     */
    'data': NotificationSettings;
}
/**
 * 
 * @export
 * @interface NotificationSettingsTimeTriggerTarget
 */
export interface NotificationSettingsTimeTriggerTarget {
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettingsTimeTriggerTarget
     */
    'bookmarked_issue': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettingsTimeTriggerTarget
     */
    'owned_issue': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettingsTimeTriggerTarget
     */
    'assigned_issue': boolean;
}
/**
 * 
 * @export
 * @interface NotificationSettingsType
 */
export interface NotificationSettingsType {
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettingsType
     */
    'push_notification': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettingsType
     */
    'mail_notification': boolean;
}
/**
 * 
 * @export
 * @interface NotificationSettingsUpdateRequest
 */
export interface NotificationSettingsUpdateRequest {
    /**
     * 
     * @type {NotificationSettingsUpdateRequestType}
     * @memberof NotificationSettingsUpdateRequest
     */
    'type'?: NotificationSettingsUpdateRequestType;
    /**
     * 
     * @type {NotificationSettingsUpdateRequestEvent}
     * @memberof NotificationSettingsUpdateRequest
     */
    'event'?: NotificationSettingsUpdateRequestEvent;
    /**
     * 
     * @type {NotificationSettingsUpdateRequestTimeTriggerTarget}
     * @memberof NotificationSettingsUpdateRequest
     */
    'time_trigger_target'?: NotificationSettingsUpdateRequestTimeTriggerTarget;
}
/**
 * 
 * @export
 * @interface NotificationSettingsUpdateRequestEvent
 */
export interface NotificationSettingsUpdateRequestEvent {
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettingsUpdateRequestEvent
     */
    'when_assigned_property'?: boolean;
}
/**
 * 
 * @export
 * @interface NotificationSettingsUpdateRequestTimeTriggerTarget
 */
export interface NotificationSettingsUpdateRequestTimeTriggerTarget {
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettingsUpdateRequestTimeTriggerTarget
     */
    'bookmarked_issue'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettingsUpdateRequestTimeTriggerTarget
     */
    'owned_issue'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettingsUpdateRequestTimeTriggerTarget
     */
    'assigned_issue'?: boolean;
}
/**
 * 
 * @export
 * @interface NotificationSettingsUpdateRequestType
 */
export interface NotificationSettingsUpdateRequestType {
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettingsUpdateRequestType
     */
    'push_notification'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettingsUpdateRequestType
     */
    'mail_notification'?: boolean;
}
/**
 * organization info from sensyn id
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * 
     * @type {number}
     * @memberof Organization
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface Pagination
 */
export interface Pagination {
    /**
     * 
     * @type {number}
     * @memberof Pagination
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof Pagination
     */
    'per_page': number;
    /**
     * 
     * @type {number}
     * @memberof Pagination
     */
    'total_count': number;
}
/**
 * This model is supposed to be included in IssueDetail/FacilityDetail\'s linked_panorama.latest_panorama
 * @export
 * @interface Panorama
 */
export interface Panorama {
    /**
     * 
     * @type {number}
     * @memberof Panorama
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Panorama
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Panorama
     */
    'sas_file': string;
    /**
     * 
     * @type {string}
     * @memberof Panorama
     */
    'sas_thumbnail': string;
    /**
     * 
     * @type {string}
     * @memberof Panorama
     */
    'multires_path': string;
    /**
     * 
     * @type {number}
     * @memberof Panorama
     */
    'default_horizontal_degree': number;
    /**
     * 
     * @type {number}
     * @memberof Panorama
     */
    'direction_degree_on_floor_plan': number | null;
    /**
     * 
     * @type {string}
     * @memberof Panorama
     */
    'content_created_at': string;
    /**
     * 
     * @type {number}
     * @memberof Panorama
     */
    'status': number;
}
/**
 * 
 * @export
 * @interface PanoramaDetail
 */
export interface PanoramaDetail {
    /**
     * 
     * @type {number}
     * @memberof PanoramaDetail
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PanoramaDetail
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PanoramaDetail
     */
    'sas_file': string;
    /**
     * 
     * @type {string}
     * @memberof PanoramaDetail
     */
    'sas_thumbnail': string;
    /**
     * 
     * @type {string}
     * @memberof PanoramaDetail
     */
    'multires_path': string;
    /**
     * 
     * @type {number}
     * @memberof PanoramaDetail
     */
    'default_horizontal_degree': number;
    /**
     * 
     * @type {number}
     * @memberof PanoramaDetail
     */
    'direction_degree_on_floor_plan': number | null;
    /**
     * 
     * @type {string}
     * @memberof PanoramaDetail
     */
    'content_created_at': string;
    /**
     * 
     * @type {number}
     * @memberof PanoramaDetail
     */
    'status': number;
    /**
     * 
     * @type {Array<PvrObject>}
     * @memberof PanoramaDetail
     */
    'pvr_objects'?: Array<PvrObject>;
}
/**
 * 
 * @export
 * @interface PanoramaDetailAllOf
 */
export interface PanoramaDetailAllOf {
    /**
     * 
     * @type {Array<PvrObject>}
     * @memberof PanoramaDetailAllOf
     */
    'pvr_objects'?: Array<PvrObject>;
}
/**
 * 
 * @export
 * @interface PanoramaDetailResponse
 */
export interface PanoramaDetailResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PanoramaDetailResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {PanoramaDetail}
     * @memberof PanoramaDetailResponse
     */
    'data': PanoramaDetail;
}
/**
 * 
 * @export
 * @interface PanoramaListResponse
 */
export interface PanoramaListResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PanoramaListResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {Array<Panorama>}
     * @memberof PanoramaListResponse
     */
    'data': Array<Panorama>;
}
/**
 * 
 * @export
 * @interface PrioritiesListResponse
 */
export interface PrioritiesListResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PrioritiesListResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {Array<Priority>}
     * @memberof PrioritiesListResponse
     */
    'data': Array<Priority>;
}
/**
 * Priority
 * @export
 * @interface Priority
 */
export interface Priority {
    /**
     * 
     * @type {number}
     * @memberof Priority
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Priority
     */
    'name': string;
    /**
     * Example: low, medium, high
     * @type {string}
     * @memberof Priority
     */
    'group': string;
    /**
     * 
     * @type {number}
     * @memberof Priority
     */
    'order': number;
}
/**
 * 
 * @export
 * @interface PriorityResponse
 */
export interface PriorityResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PriorityResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {Priority}
     * @memberof PriorityResponse
     */
    'data': Priority;
}
/**
 * 
 * @export
 * @interface Property
 */
export interface Property {
    /**
     * 
     * @type {number}
     * @memberof Property
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'name_kana': string;
    /**
     * この項目は次のようなフォーマットで入力内容を制限しています ・正規表現：^[0-9]{3}-?[0-9]{4} ・入力例：「123-5678」「000-0000」
     * @type {string}
     * @memberof Property
     */
    'zip_code': string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'completion_date': string | null;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof Property
     */
    'above_ground_floors': number;
    /**
     * 
     * @type {number}
     * @memberof Property
     */
    'under_ground_floors': number;
    /**
     * 
     * @type {Array<Floor>}
     * @memberof Property
     */
    'floors': Array<Floor>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Property
     */
    'assignees': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Property
     */
    'accessible_divisions': Array<number>;
    /**
     * 
     * @type {ImageFile}
     * @memberof Property
     */
    'image'?: ImageFile;
    /**
     * array of permitted operation name
     * @type {Array<string>}
     * @memberof Property
     */
    'user_permissions': Array<string>;
    /**
     * 
     * @type {PropertyUpdatedStatus}
     * @memberof Property
     */
    'property_updated_status'?: PropertyUpdatedStatus;
}
/**
 * 
 * @export
 * @interface PropertyCreateRequest
 */
export interface PropertyCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof PropertyCreateRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PropertyCreateRequest
     */
    'name_kana': string;
    /**
     * この項目は次のようなフォーマットで入力内容を制限しています ・正規表現：^[0-9]{3}-?[0-9]{4} ・入力例：「123-5678」「000-0000」
     * @type {string}
     * @memberof PropertyCreateRequest
     */
    'zip_code': string;
    /**
     * 
     * @type {string}
     * @memberof PropertyCreateRequest
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof PropertyCreateRequest
     */
    'completion_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyCreateRequest
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof PropertyCreateRequest
     */
    'above_ground_floors': number;
    /**
     * 
     * @type {number}
     * @memberof PropertyCreateRequest
     */
    'under_ground_floors': number;
    /**
     * 
     * @type {FloorOperationsRequest}
     * @memberof PropertyCreateRequest
     */
    'floor_operations': FloorOperationsRequest;
    /**
     * 
     * @type {Array<number>}
     * @memberof PropertyCreateRequest
     */
    'assignees'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PropertyCreateRequest
     */
    'accessible_divisions'?: Array<number>;
    /**
     * 
     * @type {PropertyCreateRequestImage}
     * @memberof PropertyCreateRequest
     */
    'image'?: PropertyCreateRequestImage;
}
/**
 * 
 * @export
 * @interface PropertyCreateRequestImage
 */
export interface PropertyCreateRequestImage {
    /**
     * 
     * @type {string}
     * @memberof PropertyCreateRequestImage
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PropertyCreateRequestImage
     */
    'path': string;
}
/**
 * 
 * @export
 * @interface PropertyDetail
 */
export interface PropertyDetail {
    /**
     * 
     * @type {number}
     * @memberof PropertyDetail
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PropertyDetail
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDetail
     */
    'name_kana': string;
    /**
     * この項目は次のようなフォーマットで入力内容を制限しています ・正規表現：^[0-9]{3}-?[0-9]{4} ・入力例：「123-5678」「000-0000」
     * @type {string}
     * @memberof PropertyDetail
     */
    'zip_code': string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDetail
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDetail
     */
    'completion_date': string | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyDetail
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof PropertyDetail
     */
    'above_ground_floors': number;
    /**
     * 
     * @type {number}
     * @memberof PropertyDetail
     */
    'under_ground_floors': number;
    /**
     * 
     * @type {Array<PropertyDetailFloor>}
     * @memberof PropertyDetail
     */
    'floors': Array<PropertyDetailFloor>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PropertyDetail
     */
    'assignees': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PropertyDetail
     */
    'accessible_divisions': Array<number>;
    /**
     * 
     * @type {ImageFile}
     * @memberof PropertyDetail
     */
    'image'?: ImageFile;
    /**
     * array of permitted operation name
     * @type {Array<string>}
     * @memberof PropertyDetail
     */
    'user_permissions': Array<string>;
    /**
     * 
     * @type {Array<PropertyDirectory>}
     * @memberof PropertyDetail
     */
    'directories'?: Array<PropertyDirectory>;
    /**
     * 
     * @type {PropertyUpdatedStatus}
     * @memberof PropertyDetail
     */
    'property_updated_status'?: PropertyUpdatedStatus;
}
/**
 * 
 * @export
 * @interface PropertyDetailFloor
 */
export interface PropertyDetailFloor {
    /**
     * 
     * @type {number}
     * @memberof PropertyDetailFloor
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PropertyDetailFloor
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof PropertyDetailFloor
     */
    'order': number;
    /**
     * 
     * @type {FloorPlan}
     * @memberof PropertyDetailFloor
     */
    'floor_plan'?: FloorPlan;
    /**
     * If there is floorplan, shotpointcount will be returned
     * @type {number}
     * @memberof PropertyDetailFloor
     */
    'shot_points_count'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PropertyDetailFloor
     */
    'issue_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof PropertyDetailFloor
     */
    'facility_count'?: number;
}
/**
 * 
 * @export
 * @interface PropertyDetailResponse
 */
export interface PropertyDetailResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PropertyDetailResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {PropertyDetail}
     * @memberof PropertyDetailResponse
     */
    'data': PropertyDetail;
}
/**
 * 
 * @export
 * @interface PropertyDirectory
 */
export interface PropertyDirectory {
    /**
     * 
     * @type {number}
     * @memberof PropertyDirectory
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof PropertyDirectory
     */
    'property_id': number;
    /**
     * 
     * @type {number}
     * @memberof PropertyDirectory
     */
    'master_id': number;
    /**
     * 
     * @type {string}
     * @memberof PropertyDirectory
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDirectory
     */
    'path': string;
    /**
     * 
     * @type {number}
     * @memberof PropertyDirectory
     */
    'order': number;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyDirectory
     */
    'is_protected': boolean;
    /**
     * 
     * @type {number}
     * @memberof PropertyDirectory
     */
    'parent_id'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PropertyDirectory
     */
    'user_permissions': Array<string>;
}
/**
 * 
 * @export
 * @interface PropertyDirectoryAllOf
 */
export interface PropertyDirectoryAllOf {
    /**
     * 
     * @type {number}
     * @memberof PropertyDirectoryAllOf
     */
    'parent_id'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PropertyDirectoryAllOf
     */
    'user_permissions': Array<string>;
}
/**
 * 
 * @export
 * @interface PropertyDirectoryBulkOperationsRequest
 */
export interface PropertyDirectoryBulkOperationsRequest {
    /**
     * 
     * @type {AttachmentOperationsRequest}
     * @memberof PropertyDirectoryBulkOperationsRequest
     */
    'attachment_operations'?: AttachmentOperationsRequest;
}
/**
 * 
 * @export
 * @interface PropertyDirectoryDetail
 */
export interface PropertyDirectoryDetail {
    /**
     * 
     * @type {number}
     * @memberof PropertyDirectoryDetail
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof PropertyDirectoryDetail
     */
    'property_id': number;
    /**
     * 
     * @type {number}
     * @memberof PropertyDirectoryDetail
     */
    'master_id': number;
    /**
     * 
     * @type {string}
     * @memberof PropertyDirectoryDetail
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDirectoryDetail
     */
    'path': string;
    /**
     * 
     * @type {number}
     * @memberof PropertyDirectoryDetail
     */
    'order': number;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyDirectoryDetail
     */
    'is_protected': boolean;
    /**
     * 
     * @type {number}
     * @memberof PropertyDirectoryDetail
     */
    'parent_id'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PropertyDirectoryDetail
     */
    'user_permissions': Array<string>;
    /**
     * 
     * @type {PropertyDirectorySimple}
     * @memberof PropertyDirectoryDetail
     */
    'parent'?: PropertyDirectorySimple;
    /**
     * 
     * @type {Array<PropertyDirectory>}
     * @memberof PropertyDirectoryDetail
     */
    'children'?: Array<PropertyDirectory>;
    /**
     * 
     * @type {Array<AttachmentFile>}
     * @memberof PropertyDirectoryDetail
     */
    'attachment_files'?: Array<AttachmentFile>;
}
/**
 * 
 * @export
 * @interface PropertyDirectoryDetailAllOf
 */
export interface PropertyDirectoryDetailAllOf {
    /**
     * 
     * @type {PropertyDirectorySimple}
     * @memberof PropertyDirectoryDetailAllOf
     */
    'parent'?: PropertyDirectorySimple;
    /**
     * 
     * @type {Array<PropertyDirectory>}
     * @memberof PropertyDirectoryDetailAllOf
     */
    'children'?: Array<PropertyDirectory>;
    /**
     * 
     * @type {Array<AttachmentFile>}
     * @memberof PropertyDirectoryDetailAllOf
     */
    'attachment_files'?: Array<AttachmentFile>;
}
/**
 * 
 * @export
 * @interface PropertyDirectoryDetailResponse
 */
export interface PropertyDirectoryDetailResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PropertyDirectoryDetailResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {PropertyDirectoryDetail}
     * @memberof PropertyDirectoryDetailResponse
     */
    'data': PropertyDirectoryDetail;
}
/**
 * 
 * @export
 * @interface PropertyDirectoryListResponse
 */
export interface PropertyDirectoryListResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PropertyDirectoryListResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {Array<PropertyDirectory>}
     * @memberof PropertyDirectoryListResponse
     */
    'data': Array<PropertyDirectory>;
}
/**
 * 
 * @export
 * @interface PropertyDirectoryMaster
 */
export interface PropertyDirectoryMaster {
    /**
     * 
     * @type {number}
     * @memberof PropertyDirectoryMaster
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PropertyDirectoryMaster
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDirectoryMaster
     */
    'path': string;
    /**
     * 
     * @type {number}
     * @memberof PropertyDirectoryMaster
     */
    'order': number;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyDirectoryMaster
     */
    'is_protected': boolean;
    /**
     * 
     * @type {number}
     * @memberof PropertyDirectoryMaster
     */
    'parent_id'?: number | null;
}
/**
 * 
 * @export
 * @interface PropertyDirectoryMasterBulkUpdateRequest
 */
export interface PropertyDirectoryMasterBulkUpdateRequest {
    /**
     * 
     * @type {Array<PropertyDirectoryMasterBulkUpdateRequestDirectoryMastersInner>}
     * @memberof PropertyDirectoryMasterBulkUpdateRequest
     */
    'directory_masters'?: Array<PropertyDirectoryMasterBulkUpdateRequestDirectoryMastersInner>;
}
/**
 * 
 * @export
 * @interface PropertyDirectoryMasterBulkUpdateRequestDirectoryMastersInner
 */
export interface PropertyDirectoryMasterBulkUpdateRequestDirectoryMastersInner {
    /**
     * 
     * @type {number}
     * @memberof PropertyDirectoryMasterBulkUpdateRequestDirectoryMastersInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PropertyDirectoryMasterBulkUpdateRequestDirectoryMastersInner
     */
    'path'?: string;
    /**
     * 
     * @type {number}
     * @memberof PropertyDirectoryMasterBulkUpdateRequestDirectoryMastersInner
     */
    'order'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyDirectoryMasterBulkUpdateRequestDirectoryMastersInner
     */
    'is_protected'?: boolean;
}
/**
 * 
 * @export
 * @interface PropertyDirectoryMasterBulkUpdateResponse
 */
export interface PropertyDirectoryMasterBulkUpdateResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PropertyDirectoryMasterBulkUpdateResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {Array<PropertyDirectoryMaster>}
     * @memberof PropertyDirectoryMasterBulkUpdateResponse
     */
    'data': Array<PropertyDirectoryMaster>;
}
/**
 * 
 * @export
 * @interface PropertyDirectoryMasterCreateRequest
 */
export interface PropertyDirectoryMasterCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof PropertyDirectoryMasterCreateRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDirectoryMasterCreateRequest
     */
    'path': string;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyDirectoryMasterCreateRequest
     */
    'is_protected': boolean;
}
/**
 * 
 * @export
 * @interface PropertyDirectoryMasterListResponse
 */
export interface PropertyDirectoryMasterListResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PropertyDirectoryMasterListResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {Array<PropertyDirectoryMaster>}
     * @memberof PropertyDirectoryMasterListResponse
     */
    'data': Array<PropertyDirectoryMaster>;
}
/**
 * 
 * @export
 * @interface PropertyDirectoryMasterResponse
 */
export interface PropertyDirectoryMasterResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PropertyDirectoryMasterResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {PropertyDirectoryMaster}
     * @memberof PropertyDirectoryMasterResponse
     */
    'data': PropertyDirectoryMaster;
}
/**
 * 
 * @export
 * @interface PropertyDirectoryMasterUpdateRequest
 */
export interface PropertyDirectoryMasterUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof PropertyDirectoryMasterUpdateRequest
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyDirectoryMasterUpdateRequest
     */
    'is_protected'?: boolean;
}
/**
 * 
 * @export
 * @interface PropertyDirectorySimple
 */
export interface PropertyDirectorySimple {
    /**
     * 
     * @type {number}
     * @memberof PropertyDirectorySimple
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof PropertyDirectorySimple
     */
    'property_id': number;
    /**
     * 
     * @type {number}
     * @memberof PropertyDirectorySimple
     */
    'master_id': number;
    /**
     * 
     * @type {string}
     * @memberof PropertyDirectorySimple
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDirectorySimple
     */
    'path': string;
    /**
     * 
     * @type {number}
     * @memberof PropertyDirectorySimple
     */
    'order': number;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyDirectorySimple
     */
    'is_protected': boolean;
}
/**
 * 
 * @export
 * @interface PropertyListResponse
 */
export interface PropertyListResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PropertyListResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {Array<Property>}
     * @memberof PropertyListResponse
     */
    'data': Array<Property>;
    /**
     * 
     * @type {Pagination}
     * @memberof PropertyListResponse
     */
    'pagination': Pagination;
}
/**
 * 
 * @export
 * @interface PropertyListSimpleResponse
 */
export interface PropertyListSimpleResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PropertyListSimpleResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {Array<PropertySimple>}
     * @memberof PropertyListSimpleResponse
     */
    'data': Array<PropertySimple>;
}
/**
 * 
 * @export
 * @interface PropertyResponse
 */
export interface PropertyResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PropertyResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {Property}
     * @memberof PropertyResponse
     */
    'data': Property;
}
/**
 * 
 * @export
 * @interface PropertySimple
 */
export interface PropertySimple {
    /**
     * 
     * @type {number}
     * @memberof PropertySimple
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PropertySimple
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PropertySimple
     */
    'name_kana': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof PropertySimple
     */
    'assignees': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PropertySimple
     */
    'accessible_divisions': Array<number>;
    /**
     * 
     * @type {Array<Floor>}
     * @memberof PropertySimple
     */
    'floors': Array<Floor>;
}
/**
 * 
 * @export
 * @interface PropertyUpdateRequest
 */
export interface PropertyUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof PropertyUpdateRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyUpdateRequest
     */
    'name_kana'?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyUpdateRequest
     */
    'zip_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyUpdateRequest
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyUpdateRequest
     */
    'completion_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyUpdateRequest
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof PropertyUpdateRequest
     */
    'above_ground_floors'?: number;
    /**
     * 
     * @type {number}
     * @memberof PropertyUpdateRequest
     */
    'under_ground_floors'?: number;
    /**
     * 
     * @type {FloorOperationsRequest}
     * @memberof PropertyUpdateRequest
     */
    'floor_operations'?: FloorOperationsRequest;
    /**
     * 
     * @type {Array<number>}
     * @memberof PropertyUpdateRequest
     */
    'assignees'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PropertyUpdateRequest
     */
    'accessible_divisions'?: Array<number>;
    /**
     * 
     * @type {PropertyUpdateRequestImage}
     * @memberof PropertyUpdateRequest
     */
    'image'?: PropertyUpdateRequestImage;
}
/**
 * 
 * @export
 * @interface PropertyUpdateRequestImage
 */
export interface PropertyUpdateRequestImage {
    /**
     * 
     * @type {string}
     * @memberof PropertyUpdateRequestImage
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PropertyUpdateRequestImage
     */
    'path': string | null;
}
/**
 * 
 * @export
 * @interface PropertyUpdatedStatus
 */
export interface PropertyUpdatedStatus {
    /**
     * 
     * @type {number}
     * @memberof PropertyUpdatedStatus
     */
    'property_id': number;
    /**
     * 
     * @type {string}
     * @memberof PropertyUpdatedStatus
     */
    'last_updated_item': PropertyUpdatedStatusLastUpdatedItemEnum;
    /**
     * YYYY-MM-DD HH:mm:ssZ
     * @type {string}
     * @memberof PropertyUpdatedStatus
     */
    'updated_at': string;
}

export const PropertyUpdatedStatusLastUpdatedItemEnum = {
    Issue: 'issue',
    Facility: 'facility'
} as const;

export type PropertyUpdatedStatusLastUpdatedItemEnum = typeof PropertyUpdatedStatusLastUpdatedItemEnum[keyof typeof PropertyUpdatedStatusLastUpdatedItemEnum];

/**
 * 
 * @export
 * @interface ProtectlessFileCreateRequest
 */
export interface ProtectlessFileCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof ProtectlessFileCreateRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProtectlessFileCreateRequest
     */
    'path': string;
}
/**
 * 
 * @export
 * @interface PublicFacility
 */
export interface PublicFacility {
    /**
     * 
     * @type {boolean}
     * @memberof PublicFacility
     */
    'ok': boolean;
    /**
     * 
     * @type {PublicFacilityData}
     * @memberof PublicFacility
     */
    'data': PublicFacilityData | null;
}
/**
 * 
 * @export
 * @interface PublicFacilityData
 */
export interface PublicFacilityData {
    /**
     * 
     * @type {string}
     * @memberof PublicFacilityData
     */
    'facility_code': string;
}
/**
 * 
 * @export
 * @interface PublicIssue
 */
export interface PublicIssue {
    /**
     * 
     * @type {boolean}
     * @memberof PublicIssue
     */
    'ok': boolean;
    /**
     * 
     * @type {PublicIssueData}
     * @memberof PublicIssue
     */
    'data': PublicIssueData | null;
}
/**
 * 
 * @export
 * @interface PublicIssueData
 */
export interface PublicIssueData {
    /**
     * 
     * @type {string}
     * @memberof PublicIssueData
     */
    'issue_code': string;
}
/**
 * 
 * @export
 * @interface PublicProperty
 */
export interface PublicProperty {
    /**
     * 
     * @type {boolean}
     * @memberof PublicProperty
     */
    'ok': boolean;
    /**
     * 
     * @type {PublicPropertyData}
     * @memberof PublicProperty
     */
    'data': PublicPropertyData | null;
}
/**
 * 
 * @export
 * @interface PublicPropertyData
 */
export interface PublicPropertyData {
    /**
     * 
     * @type {string}
     * @memberof PublicPropertyData
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface PvrObject
 */
export interface PvrObject {
    /**
     * 
     * @type {number}
     * @memberof PvrObject
     */
    'id': number;
    /**
     * 
     * @type {VrCoordinates}
     * @memberof PvrObject
     */
    'vr_coordinates': VrCoordinates;
    /**
     * 
     * @type {LinkedItemContent}
     * @memberof PvrObject
     */
    'content': LinkedItemContent;
    /**
     * 
     * @type {Issue}
     * @memberof PvrObject
     */
    'issue'?: Issue;
    /**
     * 
     * @type {Facility}
     * @memberof PvrObject
     */
    'facility'?: Facility;
}
/**
 * 
 * @export
 * @interface ReferredProperty
 */
export interface ReferredProperty {
    /**
     * 
     * @type {number}
     * @memberof ReferredProperty
     */
    'property_id': number;
    /**
     * 
     * @type {string}
     * @memberof ReferredProperty
     */
    'property_name': string;
    /**
     * 
     * @type {number}
     * @memberof ReferredProperty
     */
    'floor_id': number | null;
    /**
     * 
     * @type {string}
     * @memberof ReferredProperty
     */
    'floor_name': string | null;
}
/**
 * 
 * @export
 * @interface ReferredPropertyCreateRequest
 */
export interface ReferredPropertyCreateRequest {
    /**
     * 
     * @type {number}
     * @memberof ReferredPropertyCreateRequest
     */
    'property_id': number;
    /**
     * 
     * @type {number}
     * @memberof ReferredPropertyCreateRequest
     */
    'floor_id'?: number;
}
/**
 * 
 * @export
 * @interface ReferredPropertyUpdateRequest
 */
export interface ReferredPropertyUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof ReferredPropertyUpdateRequest
     */
    'property_id': number;
    /**
     * 
     * @type {number}
     * @memberof ReferredPropertyUpdateRequest
     */
    'floor_id': number | null;
}
/**
 * 
 * @export
 * @interface Sas
 */
export interface Sas {
    /**
     * 
     * @type {string}
     * @memberof Sas
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Sas
     */
    'path': string;
    /**
     * 
     * @type {string}
     * @memberof Sas
     */
    'sas': string;
}
/**
 * 
 * @export
 * @interface SasResponse
 */
export interface SasResponse {
    /**
     * 
     * @type {boolean}
     * @memberof SasResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {Sas}
     * @memberof SasResponse
     */
    'data': Sas;
}
/**
 * 
 * @export
 * @interface SaveImportExcel400Response
 */
export interface SaveImportExcel400Response {
    /**
     * Status of the operation.
     * @type {boolean}
     * @memberof SaveImportExcel400Response
     */
    'ok'?: boolean;
    /**
     * 
     * @type {SaveImportExcel400ResponseError}
     * @memberof SaveImportExcel400Response
     */
    'error'?: SaveImportExcel400ResponseError;
}
/**
 * Detailed error information.
 * @export
 * @interface SaveImportExcel400ResponseError
 */
export interface SaveImportExcel400ResponseError {
    /**
     * Error message with details.
     * @type {string}
     * @memberof SaveImportExcel400ResponseError
     */
    'message'?: string;
    /**
     * Error code for the specific issue.
     * @type {number}
     * @memberof SaveImportExcel400ResponseError
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface SaveImportExcelResponse
 */
export interface SaveImportExcelResponse {
    /**
     * Status of the operation.
     * @type {boolean}
     * @memberof SaveImportExcelResponse
     */
    'ok'?: boolean;
    /**
     * Details of the import operation.
     * @type {Array<any>}
     * @memberof SaveImportExcelResponse
     */
    'data'?: Array<any>;
}
/**
 * 
 * @export
 * @interface ShotPoint
 */
export interface ShotPoint {
    /**
     * 
     * @type {number}
     * @memberof ShotPoint
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ShotPoint
     */
    'name': string;
    /**
     * 
     * @type {MapCoordinate}
     * @memberof ShotPoint
     */
    'shot_position': MapCoordinate;
    /**
     * 
     * @type {number}
     * @memberof ShotPoint
     */
    'panorama_count': number;
}
/**
 * 
 * @export
 * @interface ShotPointCreateRequest
 */
export interface ShotPointCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof ShotPointCreateRequest
     */
    'name': string;
    /**
     * 
     * @type {ShotPointCreateRequestShotPosition}
     * @memberof ShotPointCreateRequest
     */
    'shot_position': ShotPointCreateRequestShotPosition;
    /**
     * 
     * @type {ShotPointCreateRequestPanorama}
     * @memberof ShotPointCreateRequest
     */
    'panorama': ShotPointCreateRequestPanorama;
}
/**
 * 
 * @export
 * @interface ShotPointCreateRequestPanorama
 */
export interface ShotPointCreateRequestPanorama {
    /**
     * 
     * @type {number}
     * @memberof ShotPointCreateRequestPanorama
     */
    'default_horizontal_degree': number;
    /**
     * 
     * @type {number}
     * @memberof ShotPointCreateRequestPanorama
     */
    'direction_degree_on_floor_plan': number;
    /**
     * 
     * @type {ProtectlessFileCreateRequest}
     * @memberof ShotPointCreateRequestPanorama
     */
    'file': ProtectlessFileCreateRequest;
    /**
     * 
     * @type {boolean}
     * @memberof ShotPointCreateRequestPanorama
     */
    'is_auto_corrected': boolean;
}
/**
 * 
 * @export
 * @interface ShotPointCreateRequestShotPosition
 */
export interface ShotPointCreateRequestShotPosition {
    /**
     * 
     * @type {number}
     * @memberof ShotPointCreateRequestShotPosition
     */
    'latitude': number;
    /**
     * 
     * @type {number}
     * @memberof ShotPointCreateRequestShotPosition
     */
    'longitude': number;
}
/**
 * 
 * @export
 * @interface ShotPointDetail
 */
export interface ShotPointDetail {
    /**
     * 
     * @type {number}
     * @memberof ShotPointDetail
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ShotPointDetail
     */
    'name': string;
    /**
     * 
     * @type {MapCoordinate}
     * @memberof ShotPointDetail
     */
    'shot_position': MapCoordinate;
    /**
     * 
     * @type {number}
     * @memberof ShotPointDetail
     */
    'panorama_count': number;
    /**
     * 
     * @type {PanoramaDetail}
     * @memberof ShotPointDetail
     */
    'latest_panorama': PanoramaDetail;
}
/**
 * 
 * @export
 * @interface ShotPointDetailAllOf
 */
export interface ShotPointDetailAllOf {
    /**
     * 
     * @type {PanoramaDetail}
     * @memberof ShotPointDetailAllOf
     */
    'latest_panorama': PanoramaDetail;
}
/**
 * 
 * @export
 * @interface ShotPointResponse
 */
export interface ShotPointResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ShotPointResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {ShotPointDetail}
     * @memberof ShotPointResponse
     */
    'data': ShotPointDetail;
}
/**
 * 
 * @export
 * @interface ShotPointUpdateRequest
 */
export interface ShotPointUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ShotPointUpdateRequest
     */
    'name'?: string;
    /**
     * 
     * @type {ShotPointUpdateRequestShotPosition}
     * @memberof ShotPointUpdateRequest
     */
    'shot_position'?: ShotPointUpdateRequestShotPosition;
    /**
     * 
     * @type {ShotPointUpdateRequestPanorama}
     * @memberof ShotPointUpdateRequest
     */
    'panorama'?: ShotPointUpdateRequestPanorama;
}
/**
 * 
 * @export
 * @interface ShotPointUpdateRequestPanorama
 */
export interface ShotPointUpdateRequestPanorama {
    /**
     * 
     * @type {number}
     * @memberof ShotPointUpdateRequestPanorama
     */
    'default_horizontal_degree'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShotPointUpdateRequestPanorama
     */
    'direction_degree_on_floor_plan'?: number;
    /**
     * 
     * @type {ProtectlessFileCreateRequest}
     * @memberof ShotPointUpdateRequestPanorama
     */
    'file'?: ProtectlessFileCreateRequest;
    /**
     * 
     * @type {boolean}
     * @memberof ShotPointUpdateRequestPanorama
     */
    'is_auto_corrected'?: boolean;
}
/**
 * 
 * @export
 * @interface ShotPointUpdateRequestShotPosition
 */
export interface ShotPointUpdateRequestShotPosition {
    /**
     * 
     * @type {number}
     * @memberof ShotPointUpdateRequestShotPosition
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShotPointUpdateRequestShotPosition
     */
    'longitude'?: number;
}
/**
 * 
 * @export
 * @interface Status
 */
export interface Status {
    /**
     * 
     * @type {number}
     * @memberof Status
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Status
     */
    'name': string;
    /**
     * Example: todo, progress, done
     * @type {string}
     * @memberof Status
     */
    'group': string;
    /**
     * 
     * @type {number}
     * @memberof Status
     */
    'order': number;
    /**
     * 
     * @type {StatusStyle}
     * @memberof Status
     */
    'style': StatusStyle;
}
/**
 * 
 * @export
 * @interface StatusCreateRequest
 */
export interface StatusCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof StatusCreateRequest
     */
    'name': string;
    /**
     * 
     * @type {StatusCreateRequestStyle}
     * @memberof StatusCreateRequest
     */
    'style'?: StatusCreateRequestStyle;
    /**
     * 
     * @type {number}
     * @memberof StatusCreateRequest
     */
    'base_status_id': number;
}
/**
 * 
 * @export
 * @interface StatusCreateRequestStyle
 */
export interface StatusCreateRequestStyle {
    /**
     * 
     * @type {string}
     * @memberof StatusCreateRequestStyle
     */
    'background_color'?: string;
    /**
     * 
     * @type {string}
     * @memberof StatusCreateRequestStyle
     */
    'font_color'?: string;
}
/**
 * 
 * @export
 * @interface StatusListResponse
 */
export interface StatusListResponse {
    /**
     * 
     * @type {boolean}
     * @memberof StatusListResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {Array<Status>}
     * @memberof StatusListResponse
     */
    'data': Array<Status>;
}
/**
 * 
 * @export
 * @interface StatusResponse
 */
export interface StatusResponse {
    /**
     * 
     * @type {boolean}
     * @memberof StatusResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {Status}
     * @memberof StatusResponse
     */
    'data': Status;
}
/**
 * 
 * @export
 * @interface StatusStyle
 */
export interface StatusStyle {
    /**
     * 
     * @type {string}
     * @memberof StatusStyle
     */
    'background_color': string;
    /**
     * 
     * @type {string}
     * @memberof StatusStyle
     */
    'font_color': string;
}
/**
 * 
 * @export
 * @interface StatusUpdateRequest
 */
export interface StatusUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof StatusUpdateRequest
     */
    'name'?: string;
    /**
     * 
     * @type {StatusCreateRequestStyle}
     * @memberof StatusUpdateRequest
     */
    'style'?: StatusCreateRequestStyle;
}
/**
 * 
 * @export
 * @interface Upload
 */
export interface Upload {
    /**
     * 
     * @type {string}
     * @memberof Upload
     */
    'uuid': string;
}
/**
 * user info from sensyn id
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'name_kana': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'icon_url': string;
    /**
     * 
     * @type {UserRole}
     * @memberof User
     */
    'role'?: UserRole;
    /**
     * 
     * @type {UserDivision}
     * @memberof User
     */
    'division'?: UserDivision;
    /**
     * 
     * @type {Organization}
     * @memberof User
     */
    'organization'?: Organization;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'is_deleted': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'is_super_user': boolean;
    /**
     * only returned on /users/me
     * @type {boolean}
     * @memberof User
     */
    'is_showing_directory'?: boolean;
}
/**
 * user division info from sensyn id
 * @export
 * @interface UserDivision
 */
export interface UserDivision {
    /**
     * 
     * @type {number}
     * @memberof UserDivision
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserDivision
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UserDivisionListResponse
 */
export interface UserDivisionListResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UserDivisionListResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {Array<UserDivision>}
     * @memberof UserDivisionListResponse
     */
    'data': Array<UserDivision>;
}
/**
 * 
 * @export
 * @interface UserListResponse
 */
export interface UserListResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UserListResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {Array<User>}
     * @memberof UserListResponse
     */
    'data': Array<User>;
}
/**
 * 
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UserResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {User}
     * @memberof UserResponse
     */
    'data': User;
}
/**
 * user role info from sensyn id
 * @export
 * @interface UserRole
 */
export interface UserRole {
    /**
     * 
     * @type {number}
     * @memberof UserRole
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserRole
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UserRoleListResponse
 */
export interface UserRoleListResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UserRoleListResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {Array<UserRole>}
     * @memberof UserRoleListResponse
     */
    'data': Array<UserRole>;
}
/**
 * 
 * @export
 * @interface Uuid
 */
export interface Uuid {
    /**
     * 
     * @type {string}
     * @memberof Uuid
     */
    'uuid': string;
}
/**
 * 
 * @export
 * @interface UuidResponse
 */
export interface UuidResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UuidResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {Uuid}
     * @memberof UuidResponse
     */
    'data': Uuid;
}
/**
 * 
 * @export
 * @interface ValidateImportExcelResponse
 */
export interface ValidateImportExcelResponse {
    /**
     * Indicates if the validation was successful
     * @type {boolean}
     * @memberof ValidateImportExcelResponse
     */
    'ok': boolean;
    /**
     * Error message if validation fails
     * @type {string}
     * @memberof ValidateImportExcelResponse
     */
    'error'?: string | null;
    /**
     * Validation details for each row
     * @type {{ [key: string]: Array<ValidateImportExcelResponseDataValueInner>; }}
     * @memberof ValidateImportExcelResponse
     */
    'data': { [key: string]: Array<ValidateImportExcelResponseDataValueInner>; };
}
/**
 * 
 * @export
 * @interface ValidateImportExcelResponseDataValueInner
 */
export interface ValidateImportExcelResponseDataValueInner {
    /**
     * The row number in the Excel file
     * @type {number}
     * @memberof ValidateImportExcelResponseDataValueInner
     */
    'row': number;
    /**
     * The column name in the Excel file
     * @type {string}
     * @memberof ValidateImportExcelResponseDataValueInner
     */
    'column': string;
    /**
     * The value in the cell
     * @type {string}
     * @memberof ValidateImportExcelResponseDataValueInner
     */
    'value'?: string | null;
    /**
     * Validation error message for the cell, if any
     * @type {string}
     * @memberof ValidateImportExcelResponseDataValueInner
     */
    'error'?: string | null;
}
/**
 * 
 * @export
 * @interface VrCoordinates
 */
export interface VrCoordinates {
    /**
     * 
     * @type {number}
     * @memberof VrCoordinates
     */
    'yaw': number;
    /**
     * 
     * @type {number}
     * @memberof VrCoordinates
     */
    'pitch': number;
}

/**
 * AppKeysApi - axios parameter creator
 * @export
 */
export const AppKeysApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Notification Keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommonNotificationKeys: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/common/notification_keys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppKeysApi - functional programming interface
 * @export
 */
export const AppKeysApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppKeysApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Notification Keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommonNotificationKeys(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationKeysResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommonNotificationKeys(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppKeysApi - factory interface
 * @export
 */
export const AppKeysApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppKeysApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Notification Keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommonNotificationKeys(options?: any): AxiosPromise<NotificationKeysResponse> {
            return localVarFp.getCommonNotificationKeys(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppKeysApi - object-oriented interface
 * @export
 * @class AppKeysApi
 * @extends {BaseAPI}
 */
export class AppKeysApi extends BaseAPI {
    /**
     * 
     * @summary Get Notification Keys
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppKeysApi
     */
    public getCommonNotificationKeys(options?: AxiosRequestConfig) {
        return AppKeysApiFp(this.configuration).getCommonNotificationKeys(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CommentApi - axios parameter creator
 * @export
 */
export const CommentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Facility Comment
         * @param {number} facilityId 
         * @param {CommentCreateRequest} [commentCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFacilityComment: async (facilityId: number, commentCreateRequest?: CommentCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('createFacilityComment', 'facilityId', facilityId)
            const localVarPath = `/facilities/{facility_id}/comments`
                .replace(`{${"facility_id"}}`, encodeURIComponent(String(facilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create issue comment
         * @summary Create Comment
         * @param {number} issueId issue id
         * @param {CommentCreateRequest} [commentCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIssueComment: async (issueId: number, commentCreateRequest?: CommentCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issueId' is not null or undefined
            assertParamExists('createIssueComment', 'issueId', issueId)
            const localVarPath = `/issues/{issue_id}/comments`
                .replace(`{${"issue_id"}}`, encodeURIComponent(String(issueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete issue comment
         * @summary Delete Comment
         * @param {number} issueId issue id
         * @param {number} commentId comment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIssueComment: async (issueId: number, commentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issueId' is not null or undefined
            assertParamExists('deleteIssueComment', 'issueId', issueId)
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('deleteIssueComment', 'commentId', commentId)
            const localVarPath = `/issues/{issue_id}/comments/{comment_id}`
                .replace(`{${"issue_id"}}`, encodeURIComponent(String(issueId)))
                .replace(`{${"comment_id"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Facility Comments
         * @param {number} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilityComments: async (facilityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('getFacilityComments', 'facilityId', facilityId)
            const localVarPath = `/facilities/{facility_id}/comments`
                .replace(`{${"facility_id"}}`, encodeURIComponent(String(facilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get issue comments
         * @summary List Comments
         * @param {number} issueId issue id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIssueComments: async (issueId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issueId' is not null or undefined
            assertParamExists('getIssueComments', 'issueId', issueId)
            const localVarPath = `/issues/{issue_id}/comments`
                .replace(`{${"issue_id"}}`, encodeURIComponent(String(issueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Facility Comment
         * @param {number} facilityId 
         * @param {number} commentId 
         * @param {CommentUpdateRequest} [commentUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchFacilityComment: async (facilityId: number, commentId: number, commentUpdateRequest?: CommentUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('patchFacilityComment', 'facilityId', facilityId)
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('patchFacilityComment', 'commentId', commentId)
            const localVarPath = `/facilities/{facility_id}/comments/{comment_id}`
                .replace(`{${"facility_id"}}`, encodeURIComponent(String(facilityId)))
                .replace(`{${"comment_id"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update issue comment
         * @summary Update Comment
         * @param {number} issueId issue id
         * @param {number} commentId comment id
         * @param {CommentUpdateRequest} [commentUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIssueComment: async (issueId: number, commentId: number, commentUpdateRequest?: CommentUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issueId' is not null or undefined
            assertParamExists('updateIssueComment', 'issueId', issueId)
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('updateIssueComment', 'commentId', commentId)
            const localVarPath = `/issues/{issue_id}/comments/{comment_id}`
                .replace(`{${"issue_id"}}`, encodeURIComponent(String(issueId)))
                .replace(`{${"comment_id"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommentApi - functional programming interface
 * @export
 */
export const CommentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Facility Comment
         * @param {number} facilityId 
         * @param {CommentCreateRequest} [commentCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFacilityComment(facilityId: number, commentCreateRequest?: CommentCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFacilityComment(facilityId, commentCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create issue comment
         * @summary Create Comment
         * @param {number} issueId issue id
         * @param {CommentCreateRequest} [commentCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createIssueComment(issueId: number, commentCreateRequest?: CommentCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createIssueComment(issueId, commentCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete issue comment
         * @summary Delete Comment
         * @param {number} issueId issue id
         * @param {number} commentId comment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteIssueComment(issueId: number, commentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoneDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteIssueComment(issueId, commentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Facility Comments
         * @param {number} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFacilityComments(facilityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFacilityComments(facilityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get issue comments
         * @summary List Comments
         * @param {number} issueId issue id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIssueComments(issueId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIssueComments(issueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch Facility Comment
         * @param {number} facilityId 
         * @param {number} commentId 
         * @param {CommentUpdateRequest} [commentUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchFacilityComment(facilityId: number, commentId: number, commentUpdateRequest?: CommentUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchFacilityComment(facilityId, commentId, commentUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * update issue comment
         * @summary Update Comment
         * @param {number} issueId issue id
         * @param {number} commentId comment id
         * @param {CommentUpdateRequest} [commentUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIssueComment(issueId: number, commentId: number, commentUpdateRequest?: CommentUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIssueComment(issueId, commentId, commentUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommentApi - factory interface
 * @export
 */
export const CommentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommentApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Facility Comment
         * @param {number} facilityId 
         * @param {CommentCreateRequest} [commentCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFacilityComment(facilityId: number, commentCreateRequest?: CommentCreateRequest, options?: any): AxiosPromise<CommentResponse> {
            return localVarFp.createFacilityComment(facilityId, commentCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * create issue comment
         * @summary Create Comment
         * @param {number} issueId issue id
         * @param {CommentCreateRequest} [commentCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIssueComment(issueId: number, commentCreateRequest?: CommentCreateRequest, options?: any): AxiosPromise<CommentResponse> {
            return localVarFp.createIssueComment(issueId, commentCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * delete issue comment
         * @summary Delete Comment
         * @param {number} issueId issue id
         * @param {number} commentId comment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIssueComment(issueId: number, commentId: number, options?: any): AxiosPromise<NoneDataResponse> {
            return localVarFp.deleteIssueComment(issueId, commentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Facility Comments
         * @param {number} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilityComments(facilityId: number, options?: any): AxiosPromise<CommentListResponse> {
            return localVarFp.getFacilityComments(facilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * get issue comments
         * @summary List Comments
         * @param {number} issueId issue id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIssueComments(issueId: number, options?: any): AxiosPromise<CommentListResponse> {
            return localVarFp.getIssueComments(issueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Facility Comment
         * @param {number} facilityId 
         * @param {number} commentId 
         * @param {CommentUpdateRequest} [commentUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchFacilityComment(facilityId: number, commentId: number, commentUpdateRequest?: CommentUpdateRequest, options?: any): AxiosPromise<CommentResponse> {
            return localVarFp.patchFacilityComment(facilityId, commentId, commentUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * update issue comment
         * @summary Update Comment
         * @param {number} issueId issue id
         * @param {number} commentId comment id
         * @param {CommentUpdateRequest} [commentUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIssueComment(issueId: number, commentId: number, commentUpdateRequest?: CommentUpdateRequest, options?: any): AxiosPromise<CommentResponse> {
            return localVarFp.updateIssueComment(issueId, commentId, commentUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommentApi - object-oriented interface
 * @export
 * @class CommentApi
 * @extends {BaseAPI}
 */
export class CommentApi extends BaseAPI {
    /**
     * 
     * @summary Create Facility Comment
     * @param {number} facilityId 
     * @param {CommentCreateRequest} [commentCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public createFacilityComment(facilityId: number, commentCreateRequest?: CommentCreateRequest, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).createFacilityComment(facilityId, commentCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create issue comment
     * @summary Create Comment
     * @param {number} issueId issue id
     * @param {CommentCreateRequest} [commentCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public createIssueComment(issueId: number, commentCreateRequest?: CommentCreateRequest, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).createIssueComment(issueId, commentCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete issue comment
     * @summary Delete Comment
     * @param {number} issueId issue id
     * @param {number} commentId comment id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public deleteIssueComment(issueId: number, commentId: number, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).deleteIssueComment(issueId, commentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Facility Comments
     * @param {number} facilityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public getFacilityComments(facilityId: number, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).getFacilityComments(facilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get issue comments
     * @summary List Comments
     * @param {number} issueId issue id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public getIssueComments(issueId: number, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).getIssueComments(issueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Facility Comment
     * @param {number} facilityId 
     * @param {number} commentId 
     * @param {CommentUpdateRequest} [commentUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public patchFacilityComment(facilityId: number, commentId: number, commentUpdateRequest?: CommentUpdateRequest, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).patchFacilityComment(facilityId, commentId, commentUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update issue comment
     * @summary Update Comment
     * @param {number} issueId issue id
     * @param {number} commentId comment id
     * @param {CommentUpdateRequest} [commentUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public updateIssueComment(issueId: number, commentId: number, commentUpdateRequest?: CommentUpdateRequest, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).updateIssueComment(issueId, commentId, commentUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FacilityApi - axios parameter creator
 * @export
 */
export const FacilityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create Facility
         * @summary Create Facility
         * @param {FacilityCreateRequest} [facilityCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFacility: async (facilityCreateRequest?: FacilityCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/facilities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(facilityCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Validates the contents of an uploaded Excel file against the specified property.
         * @summary Validate an Excel file import for facilities
         * @param {File} file The Excel file to be validated
         * @param {string} propertyId The ID of the property to validate the Excel file against
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createValidateImportExcel: async (file: File, propertyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('createValidateImportExcel', 'file', file)
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('createValidateImportExcel', 'propertyId', propertyId)
            const localVarPath = `/facilities/validate-import-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (propertyId !== undefined) { 
                localVarFormParams.append('property_id', propertyId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete facility
         * @summary Delete Facility
         * @param {number} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFacility: async (facilityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('deleteFacility', 'facilityId', facilityId)
            const localVarPath = `/facilities/{facility_id}`
                .replace(`{${"facility_id"}}`, encodeURIComponent(String(facilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Download the facility bulk format for a specific property
         * @summary Get facility bulk registration file download
         * @param {number} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadFacilityFormat: async (propertyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('getDownloadFacilityFormat', 'propertyId', propertyId)
            const localVarPath = `/facilities/download-facility-format/property/{property_id}`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List Facilities
         * @summary List Facilities
         * @param {string} [title] 
         * @param {number} [property] filter: property id
         * @param {Array<number>} [type] filter: facility type ids
         * @param {Array<number>} [assignees] filter: assigned PICs
         * @param {number} [page] pagination
         * @param {number} [perPage] pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilities: async (title?: string, property?: number, type?: Array<number>, assignees?: Array<number>, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/facilities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (property !== undefined) {
                localVarQueryParameter['property'] = property;
            }

            if (type) {
                localVarQueryParameter['type'] = type.join(COLLECTION_FORMATS.csv);
            }

            if (assignees) {
                localVarQueryParameter['assignees'] = assignees.join(COLLECTION_FORMATS.csv);
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List Facilities in Floor
         * @summary List Facilities in Floor
         * @param {string} propertyId 
         * @param {string} floorId 
         * @param {Array<number>} [type] filter: facility type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilitiesInFloor: async (propertyId: string, floorId: string, type?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('getFacilitiesInFloor', 'propertyId', propertyId)
            // verify required parameter 'floorId' is not null or undefined
            assertParamExists('getFacilitiesInFloor', 'floorId', floorId)
            const localVarPath = `/properties/{property_id}/floors/{floor_id}/facilities`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)))
                .replace(`{${"floor_id"}}`, encodeURIComponent(String(floorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get available facility types
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilitiesTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/facilities/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get facility
         * @summary Get Facility
         * @param {number} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacility: async (facilityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('getFacility', 'facilityId', facilityId)
            const localVarPath = `/facilities/{facility_id}`
                .replace(`{${"facility_id"}}`, encodeURIComponent(String(facilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch Facility
         * @summary Patch Facility
         * @param {number} facilityId 
         * @param {FacilityPatchRequest} [facilityPatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchFacility: async (facilityId: number, facilityPatchRequest?: FacilityPatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('patchFacility', 'facilityId', facilityId)
            const localVarPath = `/facilities/{facility_id}`
                .replace(`{${"facility_id"}}`, encodeURIComponent(String(facilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(facilityPatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint allows importing facility data from an Excel file and associates it with a property ID.
         * @summary Import facilities from an Excel file
         * @param {File} file The Excel file to be validated
         * @param {string} propertyId The ID of the property to validate the Excel file against
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveImportExcel: async (file: File, propertyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('saveImportExcel', 'file', file)
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('saveImportExcel', 'propertyId', propertyId)
            const localVarPath = `/facilities/import-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (propertyId !== undefined) { 
                localVarFormParams.append('property_id', propertyId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FacilityApi - functional programming interface
 * @export
 */
export const FacilityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FacilityApiAxiosParamCreator(configuration)
    return {
        /**
         * Create Facility
         * @summary Create Facility
         * @param {FacilityCreateRequest} [facilityCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFacility(facilityCreateRequest?: FacilityCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacilityDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFacility(facilityCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Validates the contents of an uploaded Excel file against the specified property.
         * @summary Validate an Excel file import for facilities
         * @param {File} file The Excel file to be validated
         * @param {string} propertyId The ID of the property to validate the Excel file against
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createValidateImportExcel(file: File, propertyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateImportExcelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createValidateImportExcel(file, propertyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete facility
         * @summary Delete Facility
         * @param {number} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFacility(facilityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoneDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFacility(facilityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Download the facility bulk format for a specific property
         * @summary Get facility bulk registration file download
         * @param {number} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDownloadFacilityFormat(propertyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDownloadFacilityFormat(propertyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List Facilities
         * @summary List Facilities
         * @param {string} [title] 
         * @param {number} [property] filter: property id
         * @param {Array<number>} [type] filter: facility type ids
         * @param {Array<number>} [assignees] filter: assigned PICs
         * @param {number} [page] pagination
         * @param {number} [perPage] pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFacilities(title?: string, property?: number, type?: Array<number>, assignees?: Array<number>, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacilityListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFacilities(title, property, type, assignees, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List Facilities in Floor
         * @summary List Facilities in Floor
         * @param {string} propertyId 
         * @param {string} floorId 
         * @param {Array<number>} [type] filter: facility type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFacilitiesInFloor(propertyId: string, floorId: string, type?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListFloorFacilityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFacilitiesInFloor(propertyId, floorId, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get available facility types
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFacilitiesTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacilityTypeListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFacilitiesTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get facility
         * @summary Get Facility
         * @param {number} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFacility(facilityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacilityDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFacility(facilityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch Facility
         * @summary Patch Facility
         * @param {number} facilityId 
         * @param {FacilityPatchRequest} [facilityPatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchFacility(facilityId: number, facilityPatchRequest?: FacilityPatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacilityDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchFacility(facilityId, facilityPatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint allows importing facility data from an Excel file and associates it with a property ID.
         * @summary Import facilities from an Excel file
         * @param {File} file The Excel file to be validated
         * @param {string} propertyId The ID of the property to validate the Excel file against
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveImportExcel(file: File, propertyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveImportExcelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveImportExcel(file, propertyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FacilityApi - factory interface
 * @export
 */
export const FacilityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FacilityApiFp(configuration)
    return {
        /**
         * Create Facility
         * @summary Create Facility
         * @param {FacilityCreateRequest} [facilityCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFacility(facilityCreateRequest?: FacilityCreateRequest, options?: any): AxiosPromise<FacilityDetailResponse> {
            return localVarFp.createFacility(facilityCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Validates the contents of an uploaded Excel file against the specified property.
         * @summary Validate an Excel file import for facilities
         * @param {File} file The Excel file to be validated
         * @param {string} propertyId The ID of the property to validate the Excel file against
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createValidateImportExcel(file: File, propertyId: string, options?: any): AxiosPromise<ValidateImportExcelResponse> {
            return localVarFp.createValidateImportExcel(file, propertyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete facility
         * @summary Delete Facility
         * @param {number} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFacility(facilityId: number, options?: any): AxiosPromise<NoneDataResponse> {
            return localVarFp.deleteFacility(facilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * Download the facility bulk format for a specific property
         * @summary Get facility bulk registration file download
         * @param {number} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadFacilityFormat(propertyId: number, options?: any): AxiosPromise<File> {
            return localVarFp.getDownloadFacilityFormat(propertyId, options).then((request) => request(axios, basePath));
        },
        /**
         * List Facilities
         * @summary List Facilities
         * @param {string} [title] 
         * @param {number} [property] filter: property id
         * @param {Array<number>} [type] filter: facility type ids
         * @param {Array<number>} [assignees] filter: assigned PICs
         * @param {number} [page] pagination
         * @param {number} [perPage] pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilities(title?: string, property?: number, type?: Array<number>, assignees?: Array<number>, page?: number, perPage?: number, options?: any): AxiosPromise<FacilityListResponse> {
            return localVarFp.getFacilities(title, property, type, assignees, page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * List Facilities in Floor
         * @summary List Facilities in Floor
         * @param {string} propertyId 
         * @param {string} floorId 
         * @param {Array<number>} [type] filter: facility type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilitiesInFloor(propertyId: string, floorId: string, type?: Array<number>, options?: any): AxiosPromise<ListFloorFacilityResponse> {
            return localVarFp.getFacilitiesInFloor(propertyId, floorId, type, options).then((request) => request(axios, basePath));
        },
        /**
         * Get available facility types
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilitiesTypes(options?: any): AxiosPromise<FacilityTypeListResponse> {
            return localVarFp.getFacilitiesTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * Get facility
         * @summary Get Facility
         * @param {number} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacility(facilityId: number, options?: any): AxiosPromise<FacilityDetailResponse> {
            return localVarFp.getFacility(facilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch Facility
         * @summary Patch Facility
         * @param {number} facilityId 
         * @param {FacilityPatchRequest} [facilityPatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchFacility(facilityId: number, facilityPatchRequest?: FacilityPatchRequest, options?: any): AxiosPromise<FacilityDetailResponse> {
            return localVarFp.patchFacility(facilityId, facilityPatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint allows importing facility data from an Excel file and associates it with a property ID.
         * @summary Import facilities from an Excel file
         * @param {File} file The Excel file to be validated
         * @param {string} propertyId The ID of the property to validate the Excel file against
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveImportExcel(file: File, propertyId: string, options?: any): AxiosPromise<SaveImportExcelResponse> {
            return localVarFp.saveImportExcel(file, propertyId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FacilityApi - object-oriented interface
 * @export
 * @class FacilityApi
 * @extends {BaseAPI}
 */
export class FacilityApi extends BaseAPI {
    /**
     * Create Facility
     * @summary Create Facility
     * @param {FacilityCreateRequest} [facilityCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityApi
     */
    public createFacility(facilityCreateRequest?: FacilityCreateRequest, options?: AxiosRequestConfig) {
        return FacilityApiFp(this.configuration).createFacility(facilityCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Validates the contents of an uploaded Excel file against the specified property.
     * @summary Validate an Excel file import for facilities
     * @param {File} file The Excel file to be validated
     * @param {string} propertyId The ID of the property to validate the Excel file against
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityApi
     */
    public createValidateImportExcel(file: File, propertyId: string, options?: AxiosRequestConfig) {
        return FacilityApiFp(this.configuration).createValidateImportExcel(file, propertyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete facility
     * @summary Delete Facility
     * @param {number} facilityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityApi
     */
    public deleteFacility(facilityId: number, options?: AxiosRequestConfig) {
        return FacilityApiFp(this.configuration).deleteFacility(facilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Download the facility bulk format for a specific property
     * @summary Get facility bulk registration file download
     * @param {number} propertyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityApi
     */
    public getDownloadFacilityFormat(propertyId: number, options?: AxiosRequestConfig) {
        return FacilityApiFp(this.configuration).getDownloadFacilityFormat(propertyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List Facilities
     * @summary List Facilities
     * @param {string} [title] 
     * @param {number} [property] filter: property id
     * @param {Array<number>} [type] filter: facility type ids
     * @param {Array<number>} [assignees] filter: assigned PICs
     * @param {number} [page] pagination
     * @param {number} [perPage] pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityApi
     */
    public getFacilities(title?: string, property?: number, type?: Array<number>, assignees?: Array<number>, page?: number, perPage?: number, options?: AxiosRequestConfig) {
        return FacilityApiFp(this.configuration).getFacilities(title, property, type, assignees, page, perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List Facilities in Floor
     * @summary List Facilities in Floor
     * @param {string} propertyId 
     * @param {string} floorId 
     * @param {Array<number>} [type] filter: facility type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityApi
     */
    public getFacilitiesInFloor(propertyId: string, floorId: string, type?: Array<number>, options?: AxiosRequestConfig) {
        return FacilityApiFp(this.configuration).getFacilitiesInFloor(propertyId, floorId, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get available facility types
     * @summary Your GET endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityApi
     */
    public getFacilitiesTypes(options?: AxiosRequestConfig) {
        return FacilityApiFp(this.configuration).getFacilitiesTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get facility
     * @summary Get Facility
     * @param {number} facilityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityApi
     */
    public getFacility(facilityId: number, options?: AxiosRequestConfig) {
        return FacilityApiFp(this.configuration).getFacility(facilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch Facility
     * @summary Patch Facility
     * @param {number} facilityId 
     * @param {FacilityPatchRequest} [facilityPatchRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityApi
     */
    public patchFacility(facilityId: number, facilityPatchRequest?: FacilityPatchRequest, options?: AxiosRequestConfig) {
        return FacilityApiFp(this.configuration).patchFacility(facilityId, facilityPatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint allows importing facility data from an Excel file and associates it with a property ID.
     * @summary Import facilities from an Excel file
     * @param {File} file The Excel file to be validated
     * @param {string} propertyId The ID of the property to validate the Excel file against
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityApi
     */
    public saveImportExcel(file: File, propertyId: string, options?: AxiosRequestConfig) {
        return FacilityApiFp(this.configuration).saveImportExcel(file, propertyId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FileApi - axios parameter creator
 * @export
 */
export const FileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get sas for upload
         * @summary Get Sas
         * @param {string} uuid uuid for upload
         * @param {string} name filename for upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSas: async (uuid: string, name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getSas', 'uuid', uuid)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getSas', 'name', name)
            const localVarPath = `/files/sas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get uuid for upload
         * @summary Get Uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUuid: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/files/uuid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileApi - functional programming interface
 * @export
 */
export const FileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileApiAxiosParamCreator(configuration)
    return {
        /**
         * get sas for upload
         * @summary Get Sas
         * @param {string} uuid uuid for upload
         * @param {string} name filename for upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSas(uuid: string, name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SasResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSas(uuid, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get uuid for upload
         * @summary Get Uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUuid(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UuidResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUuid(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileApi - factory interface
 * @export
 */
export const FileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileApiFp(configuration)
    return {
        /**
         * get sas for upload
         * @summary Get Sas
         * @param {string} uuid uuid for upload
         * @param {string} name filename for upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSas(uuid: string, name: string, options?: any): AxiosPromise<SasResponse> {
            return localVarFp.getSas(uuid, name, options).then((request) => request(axios, basePath));
        },
        /**
         * get uuid for upload
         * @summary Get Uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUuid(options?: any): AxiosPromise<UuidResponse> {
            return localVarFp.getUuid(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileApi - object-oriented interface
 * @export
 * @class FileApi
 * @extends {BaseAPI}
 */
export class FileApi extends BaseAPI {
    /**
     * get sas for upload
     * @summary Get Sas
     * @param {string} uuid uuid for upload
     * @param {string} name filename for upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public getSas(uuid: string, name: string, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).getSas(uuid, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get uuid for upload
     * @summary Get Uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public getUuid(options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).getUuid(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FloorApi - axios parameter creator
 * @export
 */
export const FloorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get Floor detail info
         * @summary Get Floor
         * @param {number} propertyId 
         * @param {number} floorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFloor: async (propertyId: number, floorId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('getFloor', 'propertyId', propertyId)
            // verify required parameter 'floorId' is not null or undefined
            assertParamExists('getFloor', 'floorId', floorId)
            const localVarPath = `/properties/{property_id}/floors/{floor_id}`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)))
                .replace(`{${"floor_id"}}`, encodeURIComponent(String(floorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get objects of floorplans in floor
         * @summary Get FloorPlan Objects
         * @param {number} propertyId 
         * @param {number} floorId 
         * @param {Array<number>} [issueStatus] status of issues to be filtered
         * @param {Array<number>} [facilityType] type of facility to be filtered
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFloorFloorplanObjects: async (propertyId: number, floorId: number, issueStatus?: Array<number>, facilityType?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('getFloorFloorplanObjects', 'propertyId', propertyId)
            // verify required parameter 'floorId' is not null or undefined
            assertParamExists('getFloorFloorplanObjects', 'floorId', floorId)
            const localVarPath = `/properties/{property_id}/floors/{floor_id}/objects`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)))
                .replace(`{${"floor_id"}}`, encodeURIComponent(String(floorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (issueStatus) {
                localVarQueryParameter['issue_status'] = issueStatus.join(COLLECTION_FORMATS.csv);
            }

            if (facilityType) {
                localVarQueryParameter['facility_type'] = facilityType.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get floor issue
         * @summary Get Floor Issue
         * @param {number} propertyId 
         * @param {number} floorId 
         * @param {Array<number>} [status] status of issues to be filtered
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFloorIssue: async (propertyId: number, floorId: number, status?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('getFloorIssue', 'propertyId', propertyId)
            // verify required parameter 'floorId' is not null or undefined
            assertParamExists('getFloorIssue', 'floorId', floorId)
            const localVarPath = `/properties/{property_id}/floors/{floor_id}/issues`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)))
                .replace(`{${"floor_id"}}`, encodeURIComponent(String(floorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status) {
                localVarQueryParameter['status'] = status.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Register or Unregister the FloorPlan of floors
         * @summary Patch FloorPlans
         * @param {number} propertyId 
         * @param {FloorPlansPatchRequest} [floorPlansPatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchFloorPlans: async (propertyId: number, floorPlansPatchRequest?: FloorPlansPatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('patchFloorPlans', 'propertyId', propertyId)
            const localVarPath = `/properties/{property_id}/floors`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(floorPlansPatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FloorApi - functional programming interface
 * @export
 */
export const FloorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FloorApiAxiosParamCreator(configuration)
    return {
        /**
         * Get Floor detail info
         * @summary Get Floor
         * @param {number} propertyId 
         * @param {number} floorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFloor(propertyId: number, floorId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FloorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFloor(propertyId, floorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get objects of floorplans in floor
         * @summary Get FloorPlan Objects
         * @param {number} propertyId 
         * @param {number} floorId 
         * @param {Array<number>} [issueStatus] status of issues to be filtered
         * @param {Array<number>} [facilityType] type of facility to be filtered
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFloorFloorplanObjects(propertyId: number, floorId: number, issueStatus?: Array<number>, facilityType?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListFloorObjectsReponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFloorFloorplanObjects(propertyId, floorId, issueStatus, facilityType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get floor issue
         * @summary Get Floor Issue
         * @param {number} propertyId 
         * @param {number} floorId 
         * @param {Array<number>} [status] status of issues to be filtered
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFloorIssue(propertyId: number, floorId: number, status?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FloorIssueResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFloorIssue(propertyId, floorId, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Register or Unregister the FloorPlan of floors
         * @summary Patch FloorPlans
         * @param {number} propertyId 
         * @param {FloorPlansPatchRequest} [floorPlansPatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchFloorPlans(propertyId: number, floorPlansPatchRequest?: FloorPlansPatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FloorPlanListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchFloorPlans(propertyId, floorPlansPatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FloorApi - factory interface
 * @export
 */
export const FloorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FloorApiFp(configuration)
    return {
        /**
         * Get Floor detail info
         * @summary Get Floor
         * @param {number} propertyId 
         * @param {number} floorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFloor(propertyId: number, floorId: number, options?: any): AxiosPromise<FloorResponse> {
            return localVarFp.getFloor(propertyId, floorId, options).then((request) => request(axios, basePath));
        },
        /**
         * get objects of floorplans in floor
         * @summary Get FloorPlan Objects
         * @param {number} propertyId 
         * @param {number} floorId 
         * @param {Array<number>} [issueStatus] status of issues to be filtered
         * @param {Array<number>} [facilityType] type of facility to be filtered
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFloorFloorplanObjects(propertyId: number, floorId: number, issueStatus?: Array<number>, facilityType?: Array<number>, options?: any): AxiosPromise<ListFloorObjectsReponse> {
            return localVarFp.getFloorFloorplanObjects(propertyId, floorId, issueStatus, facilityType, options).then((request) => request(axios, basePath));
        },
        /**
         * get floor issue
         * @summary Get Floor Issue
         * @param {number} propertyId 
         * @param {number} floorId 
         * @param {Array<number>} [status] status of issues to be filtered
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFloorIssue(propertyId: number, floorId: number, status?: Array<number>, options?: any): AxiosPromise<FloorIssueResponse> {
            return localVarFp.getFloorIssue(propertyId, floorId, status, options).then((request) => request(axios, basePath));
        },
        /**
         * Register or Unregister the FloorPlan of floors
         * @summary Patch FloorPlans
         * @param {number} propertyId 
         * @param {FloorPlansPatchRequest} [floorPlansPatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchFloorPlans(propertyId: number, floorPlansPatchRequest?: FloorPlansPatchRequest, options?: any): AxiosPromise<FloorPlanListResponse> {
            return localVarFp.patchFloorPlans(propertyId, floorPlansPatchRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FloorApi - object-oriented interface
 * @export
 * @class FloorApi
 * @extends {BaseAPI}
 */
export class FloorApi extends BaseAPI {
    /**
     * Get Floor detail info
     * @summary Get Floor
     * @param {number} propertyId 
     * @param {number} floorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloorApi
     */
    public getFloor(propertyId: number, floorId: number, options?: AxiosRequestConfig) {
        return FloorApiFp(this.configuration).getFloor(propertyId, floorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get objects of floorplans in floor
     * @summary Get FloorPlan Objects
     * @param {number} propertyId 
     * @param {number} floorId 
     * @param {Array<number>} [issueStatus] status of issues to be filtered
     * @param {Array<number>} [facilityType] type of facility to be filtered
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloorApi
     */
    public getFloorFloorplanObjects(propertyId: number, floorId: number, issueStatus?: Array<number>, facilityType?: Array<number>, options?: AxiosRequestConfig) {
        return FloorApiFp(this.configuration).getFloorFloorplanObjects(propertyId, floorId, issueStatus, facilityType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get floor issue
     * @summary Get Floor Issue
     * @param {number} propertyId 
     * @param {number} floorId 
     * @param {Array<number>} [status] status of issues to be filtered
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloorApi
     */
    public getFloorIssue(propertyId: number, floorId: number, status?: Array<number>, options?: AxiosRequestConfig) {
        return FloorApiFp(this.configuration).getFloorIssue(propertyId, floorId, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Register or Unregister the FloorPlan of floors
     * @summary Patch FloorPlans
     * @param {number} propertyId 
     * @param {FloorPlansPatchRequest} [floorPlansPatchRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloorApi
     */
    public patchFloorPlans(propertyId: number, floorPlansPatchRequest?: FloorPlansPatchRequest, options?: AxiosRequestConfig) {
        return FloorApiFp(this.configuration).patchFloorPlans(propertyId, floorPlansPatchRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IssueApi - axios parameter creator
 * @export
 */
export const IssueApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * create issue
         * @summary Create Issue
         * @param {IssueCreateRequest} [issueCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIssue: async (issueCreateRequest?: IssueCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/issues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(issueCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete issue
         * @summary Delete Issue
         * @param {number} issueId issue id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIssue: async (issueId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issueId' is not null or undefined
            assertParamExists('deleteIssue', 'issueId', issueId)
            const localVarPath = `/issues/{issue_id}`
                .replace(`{${"issue_id"}}`, encodeURIComponent(String(issueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete issue comment
         * @summary Delete Comment
         * @param {number} issueId issue id
         * @param {number} commentId comment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIssueComment: async (issueId: number, commentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issueId' is not null or undefined
            assertParamExists('deleteIssueComment', 'issueId', issueId)
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('deleteIssueComment', 'commentId', commentId)
            const localVarPath = `/issues/{issue_id}/comments/{comment_id}`
                .replace(`{${"issue_id"}}`, encodeURIComponent(String(issueId)))
                .replace(`{${"comment_id"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * download csv search issues by query parameters
         * @summary Download Csv Issues
         * @param {boolean} [isBookmarked] 
         * @param {Array<number>} [status] filter: issue status ids
         * @param {Array<number>} [assignee] Filter by assignee. If unspecified, no filtering.
         * @param {Array<number>} [createdBy] 
         * @param {Array<number>} [property] 
         * @param {Array<number>} [floor] 
         * @param {string} [dueDateMin] 
         * @param {string} [dueDateMax] 
         * @param {string} [orderBy] due_date or updated_at (Default is updated_at)
         * @param {string} [sort] asc or desc (Default is desc)
         * @param {number} [page] Default is 1.
         * @param {number} [perPage] Default is 100. If it is less than 1, it returns all issues.
         * @param {string} [title] Search by issue title.
         * @param {string} [fromCreatedAt] 
         * @param {string} [toCreatedAt] 
         * @param {string} [fromUpdatedAt] 
         * @param {string} [toUpdatedAt] 
         * @param {boolean} [isAssignedMe] 
         * @param {boolean} [hasNoAssignee] Search issues which has no assignee. If unspecified or false, issue is not filtered.
         * @param {boolean} [hasNoDueDate] Issue has no due date set, when due_date based search done it will run based on with OR logic gate
         * @param {boolean} [isDueDateExceeded] this will filter the exceeded issue with status is not done
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadIssues: async (isBookmarked?: boolean, status?: Array<number>, assignee?: Array<number>, createdBy?: Array<number>, property?: Array<number>, floor?: Array<number>, dueDateMin?: string, dueDateMax?: string, orderBy?: string, sort?: string, page?: number, perPage?: number, title?: string, fromCreatedAt?: string, toCreatedAt?: string, fromUpdatedAt?: string, toUpdatedAt?: string, isAssignedMe?: boolean, hasNoAssignee?: boolean, hasNoDueDate?: boolean, isDueDateExceeded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/issues/download/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (isBookmarked !== undefined) {
                localVarQueryParameter['is_bookmarked'] = isBookmarked;
            }

            if (status) {
                localVarQueryParameter['status'] = status.join(COLLECTION_FORMATS.csv);
            }

            if (assignee) {
                localVarQueryParameter['assignee'] = assignee.join(COLLECTION_FORMATS.csv);
            }

            if (createdBy) {
                localVarQueryParameter['created_by'] = createdBy.join(COLLECTION_FORMATS.csv);
            }

            if (property) {
                localVarQueryParameter['property'] = property.join(COLLECTION_FORMATS.csv);
            }

            if (floor) {
                localVarQueryParameter['floor'] = floor.join(COLLECTION_FORMATS.csv);
            }

            if (dueDateMin !== undefined) {
                localVarQueryParameter['due_date_min'] = dueDateMin;
            }

            if (dueDateMax !== undefined) {
                localVarQueryParameter['due_date_max'] = dueDateMax;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (fromCreatedAt !== undefined) {
                localVarQueryParameter['from_created_at'] = fromCreatedAt;
            }

            if (toCreatedAt !== undefined) {
                localVarQueryParameter['to_created_at'] = toCreatedAt;
            }

            if (fromUpdatedAt !== undefined) {
                localVarQueryParameter['from_updated_at'] = fromUpdatedAt;
            }

            if (toUpdatedAt !== undefined) {
                localVarQueryParameter['to_updated_at'] = toUpdatedAt;
            }

            if (isAssignedMe !== undefined) {
                localVarQueryParameter['is_assigned_me'] = isAssignedMe;
            }

            if (hasNoAssignee !== undefined) {
                localVarQueryParameter['has_no_assignee'] = hasNoAssignee;
            }

            if (hasNoDueDate !== undefined) {
                localVarQueryParameter['has_no_due_date'] = hasNoDueDate;
            }

            if (isDueDateExceeded !== undefined) {
                localVarQueryParameter['is_due_date_exceeded'] = isDueDateExceeded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get issue
         * @summary Get Issue
         * @param {number} issueId issue id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIssue: async (issueId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issueId' is not null or undefined
            assertParamExists('getIssue', 'issueId', issueId)
            const localVarPath = `/issues/{issue_id}`
                .replace(`{${"issue_id"}}`, encodeURIComponent(String(issueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * regist issue bookmark
         * @summary Register Issue Bookmark
         * @param {number} issueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerIssueBookmark: async (issueId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issueId' is not null or undefined
            assertParamExists('registerIssueBookmark', 'issueId', issueId)
            const localVarPath = `/issues/{issue_id}/bookmark`
                .replace(`{${"issue_id"}}`, encodeURIComponent(String(issueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * search issues by query parameters
         * @summary List Issues
         * @param {boolean} [isBookmarked] 
         * @param {Array<number>} [status] filter: issue status ids
         * @param {Array<number>} [assignee] Filter by assignee. If unspecified, no filtering.
         * @param {Array<number>} [createdBy] 
         * @param {Array<number>} [property] 
         * @param {Array<number>} [floor] 
         * @param {Array<number>} [priority] filter: issue priority ids
         * @param {string} [dueDateMin] 
         * @param {string} [dueDateMax] 
         * @param {string} [orderBy] due_date or updated_at (Default is updated_at)
         * @param {string} [sort] asc or desc (Default is desc)
         * @param {number} [page] Default is 1.
         * @param {number} [perPage] Default is 100. If it is less than 1, it returns all issues.
         * @param {string} [title] Search by issue title.
         * @param {string} [fromCreatedAt] 
         * @param {string} [toCreatedAt] 
         * @param {string} [fromUpdatedAt] 
         * @param {string} [toUpdatedAt] 
         * @param {boolean} [isAssignedMe] 
         * @param {boolean} [hasNoAssignee] Search issues which has no assignee. If unspecified or false, issue is not filtered.
         * @param {boolean} [hasNoDueDate] Issue has no due date set, when due_date based search done it will run based on with OR logic gate
         * @param {boolean} [isDueDateExceeded] this will filter the exceeded issue with status is not done
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchIssues: async (isBookmarked?: boolean, status?: Array<number>, assignee?: Array<number>, createdBy?: Array<number>, property?: Array<number>, floor?: Array<number>, priority?: Array<number>, dueDateMin?: string, dueDateMax?: string, orderBy?: string, sort?: string, page?: number, perPage?: number, title?: string, fromCreatedAt?: string, toCreatedAt?: string, fromUpdatedAt?: string, toUpdatedAt?: string, isAssignedMe?: boolean, hasNoAssignee?: boolean, hasNoDueDate?: boolean, isDueDateExceeded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/issues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (isBookmarked !== undefined) {
                localVarQueryParameter['is_bookmarked'] = isBookmarked;
            }

            if (status) {
                localVarQueryParameter['status'] = status.join(COLLECTION_FORMATS.csv);
            }

            if (assignee) {
                localVarQueryParameter['assignee'] = assignee.join(COLLECTION_FORMATS.csv);
            }

            if (createdBy) {
                localVarQueryParameter['created_by'] = createdBy.join(COLLECTION_FORMATS.csv);
            }

            if (property) {
                localVarQueryParameter['property'] = property.join(COLLECTION_FORMATS.csv);
            }

            if (floor) {
                localVarQueryParameter['floor'] = floor.join(COLLECTION_FORMATS.csv);
            }

            if (priority) {
                localVarQueryParameter['priority'] = priority.join(COLLECTION_FORMATS.csv);
            }

            if (dueDateMin !== undefined) {
                localVarQueryParameter['due_date_min'] = dueDateMin;
            }

            if (dueDateMax !== undefined) {
                localVarQueryParameter['due_date_max'] = dueDateMax;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (fromCreatedAt !== undefined) {
                localVarQueryParameter['from_created_at'] = fromCreatedAt;
            }

            if (toCreatedAt !== undefined) {
                localVarQueryParameter['to_created_at'] = toCreatedAt;
            }

            if (fromUpdatedAt !== undefined) {
                localVarQueryParameter['from_updated_at'] = fromUpdatedAt;
            }

            if (toUpdatedAt !== undefined) {
                localVarQueryParameter['to_updated_at'] = toUpdatedAt;
            }

            if (isAssignedMe !== undefined) {
                localVarQueryParameter['is_assigned_me'] = isAssignedMe;
            }

            if (hasNoAssignee !== undefined) {
                localVarQueryParameter['has_no_assignee'] = hasNoAssignee;
            }

            if (hasNoDueDate !== undefined) {
                localVarQueryParameter['has_no_due_date'] = hasNoDueDate;
            }

            if (isDueDateExceeded !== undefined) {
                localVarQueryParameter['is_due_date_exceeded'] = isDueDateExceeded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * unregist issue bookmark
         * @summary Unregister Issue Bookmark
         * @param {number} issueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unregisterIssueBookmark: async (issueId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issueId' is not null or undefined
            assertParamExists('unregisterIssueBookmark', 'issueId', issueId)
            const localVarPath = `/issues/{issue_id}/bookmark`
                .replace(`{${"issue_id"}}`, encodeURIComponent(String(issueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update issue
         * @summary Update Issue
         * @param {number} issueId issue id
         * @param {IssueUpdateRequest} [issueUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIssue: async (issueId: number, issueUpdateRequest?: IssueUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issueId' is not null or undefined
            assertParamExists('updateIssue', 'issueId', issueId)
            const localVarPath = `/issues/{issue_id}`
                .replace(`{${"issue_id"}}`, encodeURIComponent(String(issueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(issueUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update issue comment
         * @summary Update Comment
         * @param {number} issueId issue id
         * @param {number} commentId comment id
         * @param {CommentUpdateRequest} [commentUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIssueComment: async (issueId: number, commentId: number, commentUpdateRequest?: CommentUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issueId' is not null or undefined
            assertParamExists('updateIssueComment', 'issueId', issueId)
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('updateIssueComment', 'commentId', commentId)
            const localVarPath = `/issues/{issue_id}/comments/{comment_id}`
                .replace(`{${"issue_id"}}`, encodeURIComponent(String(issueId)))
                .replace(`{${"comment_id"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IssueApi - functional programming interface
 * @export
 */
export const IssueApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IssueApiAxiosParamCreator(configuration)
    return {
        /**
         * create issue
         * @summary Create Issue
         * @param {IssueCreateRequest} [issueCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createIssue(issueCreateRequest?: IssueCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IssueDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createIssue(issueCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete issue
         * @summary Delete Issue
         * @param {number} issueId issue id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteIssue(issueId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoneDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteIssue(issueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete issue comment
         * @summary Delete Comment
         * @param {number} issueId issue id
         * @param {number} commentId comment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteIssueComment(issueId: number, commentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoneDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteIssueComment(issueId, commentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * download csv search issues by query parameters
         * @summary Download Csv Issues
         * @param {boolean} [isBookmarked] 
         * @param {Array<number>} [status] filter: issue status ids
         * @param {Array<number>} [assignee] Filter by assignee. If unspecified, no filtering.
         * @param {Array<number>} [createdBy] 
         * @param {Array<number>} [property] 
         * @param {Array<number>} [floor] 
         * @param {string} [dueDateMin] 
         * @param {string} [dueDateMax] 
         * @param {string} [orderBy] due_date or updated_at (Default is updated_at)
         * @param {string} [sort] asc or desc (Default is desc)
         * @param {number} [page] Default is 1.
         * @param {number} [perPage] Default is 100. If it is less than 1, it returns all issues.
         * @param {string} [title] Search by issue title.
         * @param {string} [fromCreatedAt] 
         * @param {string} [toCreatedAt] 
         * @param {string} [fromUpdatedAt] 
         * @param {string} [toUpdatedAt] 
         * @param {boolean} [isAssignedMe] 
         * @param {boolean} [hasNoAssignee] Search issues which has no assignee. If unspecified or false, issue is not filtered.
         * @param {boolean} [hasNoDueDate] Issue has no due date set, when due_date based search done it will run based on with OR logic gate
         * @param {boolean} [isDueDateExceeded] this will filter the exceeded issue with status is not done
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadIssues(isBookmarked?: boolean, status?: Array<number>, assignee?: Array<number>, createdBy?: Array<number>, property?: Array<number>, floor?: Array<number>, dueDateMin?: string, dueDateMax?: string, orderBy?: string, sort?: string, page?: number, perPage?: number, title?: string, fromCreatedAt?: string, toCreatedAt?: string, fromUpdatedAt?: string, toUpdatedAt?: string, isAssignedMe?: boolean, hasNoAssignee?: boolean, hasNoDueDate?: boolean, isDueDateExceeded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadIssues(isBookmarked, status, assignee, createdBy, property, floor, dueDateMin, dueDateMax, orderBy, sort, page, perPage, title, fromCreatedAt, toCreatedAt, fromUpdatedAt, toUpdatedAt, isAssignedMe, hasNoAssignee, hasNoDueDate, isDueDateExceeded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get issue
         * @summary Get Issue
         * @param {number} issueId issue id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIssue(issueId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IssueDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIssue(issueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * regist issue bookmark
         * @summary Register Issue Bookmark
         * @param {number} issueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerIssueBookmark(issueId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoneDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerIssueBookmark(issueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * search issues by query parameters
         * @summary List Issues
         * @param {boolean} [isBookmarked] 
         * @param {Array<number>} [status] filter: issue status ids
         * @param {Array<number>} [assignee] Filter by assignee. If unspecified, no filtering.
         * @param {Array<number>} [createdBy] 
         * @param {Array<number>} [property] 
         * @param {Array<number>} [floor] 
         * @param {Array<number>} [priority] filter: issue priority ids
         * @param {string} [dueDateMin] 
         * @param {string} [dueDateMax] 
         * @param {string} [orderBy] due_date or updated_at (Default is updated_at)
         * @param {string} [sort] asc or desc (Default is desc)
         * @param {number} [page] Default is 1.
         * @param {number} [perPage] Default is 100. If it is less than 1, it returns all issues.
         * @param {string} [title] Search by issue title.
         * @param {string} [fromCreatedAt] 
         * @param {string} [toCreatedAt] 
         * @param {string} [fromUpdatedAt] 
         * @param {string} [toUpdatedAt] 
         * @param {boolean} [isAssignedMe] 
         * @param {boolean} [hasNoAssignee] Search issues which has no assignee. If unspecified or false, issue is not filtered.
         * @param {boolean} [hasNoDueDate] Issue has no due date set, when due_date based search done it will run based on with OR logic gate
         * @param {boolean} [isDueDateExceeded] this will filter the exceeded issue with status is not done
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchIssues(isBookmarked?: boolean, status?: Array<number>, assignee?: Array<number>, createdBy?: Array<number>, property?: Array<number>, floor?: Array<number>, priority?: Array<number>, dueDateMin?: string, dueDateMax?: string, orderBy?: string, sort?: string, page?: number, perPage?: number, title?: string, fromCreatedAt?: string, toCreatedAt?: string, fromUpdatedAt?: string, toUpdatedAt?: string, isAssignedMe?: boolean, hasNoAssignee?: boolean, hasNoDueDate?: boolean, isDueDateExceeded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IssueListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchIssues(isBookmarked, status, assignee, createdBy, property, floor, priority, dueDateMin, dueDateMax, orderBy, sort, page, perPage, title, fromCreatedAt, toCreatedAt, fromUpdatedAt, toUpdatedAt, isAssignedMe, hasNoAssignee, hasNoDueDate, isDueDateExceeded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * unregist issue bookmark
         * @summary Unregister Issue Bookmark
         * @param {number} issueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unregisterIssueBookmark(issueId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoneDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unregisterIssueBookmark(issueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * update issue
         * @summary Update Issue
         * @param {number} issueId issue id
         * @param {IssueUpdateRequest} [issueUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIssue(issueId: number, issueUpdateRequest?: IssueUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IssueDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIssue(issueId, issueUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * update issue comment
         * @summary Update Comment
         * @param {number} issueId issue id
         * @param {number} commentId comment id
         * @param {CommentUpdateRequest} [commentUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIssueComment(issueId: number, commentId: number, commentUpdateRequest?: CommentUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIssueComment(issueId, commentId, commentUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IssueApi - factory interface
 * @export
 */
export const IssueApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IssueApiFp(configuration)
    return {
        /**
         * create issue
         * @summary Create Issue
         * @param {IssueCreateRequest} [issueCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIssue(issueCreateRequest?: IssueCreateRequest, options?: any): AxiosPromise<IssueDetailResponse> {
            return localVarFp.createIssue(issueCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * delete issue
         * @summary Delete Issue
         * @param {number} issueId issue id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIssue(issueId: number, options?: any): AxiosPromise<NoneDataResponse> {
            return localVarFp.deleteIssue(issueId, options).then((request) => request(axios, basePath));
        },
        /**
         * delete issue comment
         * @summary Delete Comment
         * @param {number} issueId issue id
         * @param {number} commentId comment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIssueComment(issueId: number, commentId: number, options?: any): AxiosPromise<NoneDataResponse> {
            return localVarFp.deleteIssueComment(issueId, commentId, options).then((request) => request(axios, basePath));
        },
        /**
         * download csv search issues by query parameters
         * @summary Download Csv Issues
         * @param {boolean} [isBookmarked] 
         * @param {Array<number>} [status] filter: issue status ids
         * @param {Array<number>} [assignee] Filter by assignee. If unspecified, no filtering.
         * @param {Array<number>} [createdBy] 
         * @param {Array<number>} [property] 
         * @param {Array<number>} [floor] 
         * @param {string} [dueDateMin] 
         * @param {string} [dueDateMax] 
         * @param {string} [orderBy] due_date or updated_at (Default is updated_at)
         * @param {string} [sort] asc or desc (Default is desc)
         * @param {number} [page] Default is 1.
         * @param {number} [perPage] Default is 100. If it is less than 1, it returns all issues.
         * @param {string} [title] Search by issue title.
         * @param {string} [fromCreatedAt] 
         * @param {string} [toCreatedAt] 
         * @param {string} [fromUpdatedAt] 
         * @param {string} [toUpdatedAt] 
         * @param {boolean} [isAssignedMe] 
         * @param {boolean} [hasNoAssignee] Search issues which has no assignee. If unspecified or false, issue is not filtered.
         * @param {boolean} [hasNoDueDate] Issue has no due date set, when due_date based search done it will run based on with OR logic gate
         * @param {boolean} [isDueDateExceeded] this will filter the exceeded issue with status is not done
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadIssues(isBookmarked?: boolean, status?: Array<number>, assignee?: Array<number>, createdBy?: Array<number>, property?: Array<number>, floor?: Array<number>, dueDateMin?: string, dueDateMax?: string, orderBy?: string, sort?: string, page?: number, perPage?: number, title?: string, fromCreatedAt?: string, toCreatedAt?: string, fromUpdatedAt?: string, toUpdatedAt?: string, isAssignedMe?: boolean, hasNoAssignee?: boolean, hasNoDueDate?: boolean, isDueDateExceeded?: boolean, options?: any): AxiosPromise<string> {
            return localVarFp.downloadIssues(isBookmarked, status, assignee, createdBy, property, floor, dueDateMin, dueDateMax, orderBy, sort, page, perPage, title, fromCreatedAt, toCreatedAt, fromUpdatedAt, toUpdatedAt, isAssignedMe, hasNoAssignee, hasNoDueDate, isDueDateExceeded, options).then((request) => request(axios, basePath));
        },
        /**
         * get issue
         * @summary Get Issue
         * @param {number} issueId issue id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIssue(issueId: number, options?: any): AxiosPromise<IssueDetailResponse> {
            return localVarFp.getIssue(issueId, options).then((request) => request(axios, basePath));
        },
        /**
         * regist issue bookmark
         * @summary Register Issue Bookmark
         * @param {number} issueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerIssueBookmark(issueId: number, options?: any): AxiosPromise<NoneDataResponse> {
            return localVarFp.registerIssueBookmark(issueId, options).then((request) => request(axios, basePath));
        },
        /**
         * search issues by query parameters
         * @summary List Issues
         * @param {boolean} [isBookmarked] 
         * @param {Array<number>} [status] filter: issue status ids
         * @param {Array<number>} [assignee] Filter by assignee. If unspecified, no filtering.
         * @param {Array<number>} [createdBy] 
         * @param {Array<number>} [property] 
         * @param {Array<number>} [floor] 
         * @param {Array<number>} [priority] filter: issue priority ids
         * @param {string} [dueDateMin] 
         * @param {string} [dueDateMax] 
         * @param {string} [orderBy] due_date or updated_at (Default is updated_at)
         * @param {string} [sort] asc or desc (Default is desc)
         * @param {number} [page] Default is 1.
         * @param {number} [perPage] Default is 100. If it is less than 1, it returns all issues.
         * @param {string} [title] Search by issue title.
         * @param {string} [fromCreatedAt] 
         * @param {string} [toCreatedAt] 
         * @param {string} [fromUpdatedAt] 
         * @param {string} [toUpdatedAt] 
         * @param {boolean} [isAssignedMe] 
         * @param {boolean} [hasNoAssignee] Search issues which has no assignee. If unspecified or false, issue is not filtered.
         * @param {boolean} [hasNoDueDate] Issue has no due date set, when due_date based search done it will run based on with OR logic gate
         * @param {boolean} [isDueDateExceeded] this will filter the exceeded issue with status is not done
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchIssues(isBookmarked?: boolean, status?: Array<number>, assignee?: Array<number>, createdBy?: Array<number>, property?: Array<number>, floor?: Array<number>, priority?: Array<number>, dueDateMin?: string, dueDateMax?: string, orderBy?: string, sort?: string, page?: number, perPage?: number, title?: string, fromCreatedAt?: string, toCreatedAt?: string, fromUpdatedAt?: string, toUpdatedAt?: string, isAssignedMe?: boolean, hasNoAssignee?: boolean, hasNoDueDate?: boolean, isDueDateExceeded?: boolean, options?: any): AxiosPromise<IssueListResponse> {
            return localVarFp.searchIssues(isBookmarked, status, assignee, createdBy, property, floor, priority, dueDateMin, dueDateMax, orderBy, sort, page, perPage, title, fromCreatedAt, toCreatedAt, fromUpdatedAt, toUpdatedAt, isAssignedMe, hasNoAssignee, hasNoDueDate, isDueDateExceeded, options).then((request) => request(axios, basePath));
        },
        /**
         * unregist issue bookmark
         * @summary Unregister Issue Bookmark
         * @param {number} issueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unregisterIssueBookmark(issueId: number, options?: any): AxiosPromise<NoneDataResponse> {
            return localVarFp.unregisterIssueBookmark(issueId, options).then((request) => request(axios, basePath));
        },
        /**
         * update issue
         * @summary Update Issue
         * @param {number} issueId issue id
         * @param {IssueUpdateRequest} [issueUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIssue(issueId: number, issueUpdateRequest?: IssueUpdateRequest, options?: any): AxiosPromise<IssueDetailResponse> {
            return localVarFp.updateIssue(issueId, issueUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * update issue comment
         * @summary Update Comment
         * @param {number} issueId issue id
         * @param {number} commentId comment id
         * @param {CommentUpdateRequest} [commentUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIssueComment(issueId: number, commentId: number, commentUpdateRequest?: CommentUpdateRequest, options?: any): AxiosPromise<CommentResponse> {
            return localVarFp.updateIssueComment(issueId, commentId, commentUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IssueApi - object-oriented interface
 * @export
 * @class IssueApi
 * @extends {BaseAPI}
 */
export class IssueApi extends BaseAPI {
    /**
     * create issue
     * @summary Create Issue
     * @param {IssueCreateRequest} [issueCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueApi
     */
    public createIssue(issueCreateRequest?: IssueCreateRequest, options?: AxiosRequestConfig) {
        return IssueApiFp(this.configuration).createIssue(issueCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete issue
     * @summary Delete Issue
     * @param {number} issueId issue id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueApi
     */
    public deleteIssue(issueId: number, options?: AxiosRequestConfig) {
        return IssueApiFp(this.configuration).deleteIssue(issueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete issue comment
     * @summary Delete Comment
     * @param {number} issueId issue id
     * @param {number} commentId comment id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueApi
     */
    public deleteIssueComment(issueId: number, commentId: number, options?: AxiosRequestConfig) {
        return IssueApiFp(this.configuration).deleteIssueComment(issueId, commentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * download csv search issues by query parameters
     * @summary Download Csv Issues
     * @param {boolean} [isBookmarked] 
     * @param {Array<number>} [status] filter: issue status ids
     * @param {Array<number>} [assignee] Filter by assignee. If unspecified, no filtering.
     * @param {Array<number>} [createdBy] 
     * @param {Array<number>} [property] 
     * @param {Array<number>} [floor] 
     * @param {string} [dueDateMin] 
     * @param {string} [dueDateMax] 
     * @param {string} [orderBy] due_date or updated_at (Default is updated_at)
     * @param {string} [sort] asc or desc (Default is desc)
     * @param {number} [page] Default is 1.
     * @param {number} [perPage] Default is 100. If it is less than 1, it returns all issues.
     * @param {string} [title] Search by issue title.
     * @param {string} [fromCreatedAt] 
     * @param {string} [toCreatedAt] 
     * @param {string} [fromUpdatedAt] 
     * @param {string} [toUpdatedAt] 
     * @param {boolean} [isAssignedMe] 
     * @param {boolean} [hasNoAssignee] Search issues which has no assignee. If unspecified or false, issue is not filtered.
     * @param {boolean} [hasNoDueDate] Issue has no due date set, when due_date based search done it will run based on with OR logic gate
     * @param {boolean} [isDueDateExceeded] this will filter the exceeded issue with status is not done
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueApi
     */
    public downloadIssues(isBookmarked?: boolean, status?: Array<number>, assignee?: Array<number>, createdBy?: Array<number>, property?: Array<number>, floor?: Array<number>, dueDateMin?: string, dueDateMax?: string, orderBy?: string, sort?: string, page?: number, perPage?: number, title?: string, fromCreatedAt?: string, toCreatedAt?: string, fromUpdatedAt?: string, toUpdatedAt?: string, isAssignedMe?: boolean, hasNoAssignee?: boolean, hasNoDueDate?: boolean, isDueDateExceeded?: boolean, options?: AxiosRequestConfig) {
        return IssueApiFp(this.configuration).downloadIssues(isBookmarked, status, assignee, createdBy, property, floor, dueDateMin, dueDateMax, orderBy, sort, page, perPage, title, fromCreatedAt, toCreatedAt, fromUpdatedAt, toUpdatedAt, isAssignedMe, hasNoAssignee, hasNoDueDate, isDueDateExceeded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get issue
     * @summary Get Issue
     * @param {number} issueId issue id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueApi
     */
    public getIssue(issueId: number, options?: AxiosRequestConfig) {
        return IssueApiFp(this.configuration).getIssue(issueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * regist issue bookmark
     * @summary Register Issue Bookmark
     * @param {number} issueId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueApi
     */
    public registerIssueBookmark(issueId: number, options?: AxiosRequestConfig) {
        return IssueApiFp(this.configuration).registerIssueBookmark(issueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * search issues by query parameters
     * @summary List Issues
     * @param {boolean} [isBookmarked] 
     * @param {Array<number>} [status] filter: issue status ids
     * @param {Array<number>} [assignee] Filter by assignee. If unspecified, no filtering.
     * @param {Array<number>} [createdBy] 
     * @param {Array<number>} [property] 
     * @param {Array<number>} [floor] 
     * @param {Array<number>} [priority] filter: issue priority ids
     * @param {string} [dueDateMin] 
     * @param {string} [dueDateMax] 
     * @param {string} [orderBy] due_date or updated_at (Default is updated_at)
     * @param {string} [sort] asc or desc (Default is desc)
     * @param {number} [page] Default is 1.
     * @param {number} [perPage] Default is 100. If it is less than 1, it returns all issues.
     * @param {string} [title] Search by issue title.
     * @param {string} [fromCreatedAt] 
     * @param {string} [toCreatedAt] 
     * @param {string} [fromUpdatedAt] 
     * @param {string} [toUpdatedAt] 
     * @param {boolean} [isAssignedMe] 
     * @param {boolean} [hasNoAssignee] Search issues which has no assignee. If unspecified or false, issue is not filtered.
     * @param {boolean} [hasNoDueDate] Issue has no due date set, when due_date based search done it will run based on with OR logic gate
     * @param {boolean} [isDueDateExceeded] this will filter the exceeded issue with status is not done
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueApi
     */
    public searchIssues(isBookmarked?: boolean, status?: Array<number>, assignee?: Array<number>, createdBy?: Array<number>, property?: Array<number>, floor?: Array<number>, priority?: Array<number>, dueDateMin?: string, dueDateMax?: string, orderBy?: string, sort?: string, page?: number, perPage?: number, title?: string, fromCreatedAt?: string, toCreatedAt?: string, fromUpdatedAt?: string, toUpdatedAt?: string, isAssignedMe?: boolean, hasNoAssignee?: boolean, hasNoDueDate?: boolean, isDueDateExceeded?: boolean, options?: AxiosRequestConfig) {
        return IssueApiFp(this.configuration).searchIssues(isBookmarked, status, assignee, createdBy, property, floor, priority, dueDateMin, dueDateMax, orderBy, sort, page, perPage, title, fromCreatedAt, toCreatedAt, fromUpdatedAt, toUpdatedAt, isAssignedMe, hasNoAssignee, hasNoDueDate, isDueDateExceeded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * unregist issue bookmark
     * @summary Unregister Issue Bookmark
     * @param {number} issueId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueApi
     */
    public unregisterIssueBookmark(issueId: number, options?: AxiosRequestConfig) {
        return IssueApiFp(this.configuration).unregisterIssueBookmark(issueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update issue
     * @summary Update Issue
     * @param {number} issueId issue id
     * @param {IssueUpdateRequest} [issueUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueApi
     */
    public updateIssue(issueId: number, issueUpdateRequest?: IssueUpdateRequest, options?: AxiosRequestConfig) {
        return IssueApiFp(this.configuration).updateIssue(issueId, issueUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update issue comment
     * @summary Update Comment
     * @param {number} issueId issue id
     * @param {number} commentId comment id
     * @param {CommentUpdateRequest} [commentUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueApi
     */
    public updateIssueComment(issueId: number, commentId: number, commentUpdateRequest?: CommentUpdateRequest, options?: AxiosRequestConfig) {
        return IssueApiFp(this.configuration).updateIssueComment(issueId, commentId, commentUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IssueSettingApi - axios parameter creator
 * @export
 */
export const IssueSettingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get global issue setting
         * @summary Get GlobalIssueSetting
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getGlobalIssueSetting: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/issue_settings/global`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update GlobalIssueSetting
         * @param {GlobalIssueSettingUpdateRequest} [globalIssueSettingUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        patchGlobalIssueSettings: async (globalIssueSettingUpdateRequest?: GlobalIssueSettingUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/issue_settings/global`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(globalIssueSettingUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IssueSettingApi - functional programming interface
 * @export
 */
export const IssueSettingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IssueSettingApiAxiosParamCreator(configuration)
    return {
        /**
         * Get global issue setting
         * @summary Get GlobalIssueSetting
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getGlobalIssueSetting(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlobalIssueSettingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGlobalIssueSetting(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update GlobalIssueSetting
         * @param {GlobalIssueSettingUpdateRequest} [globalIssueSettingUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async patchGlobalIssueSettings(globalIssueSettingUpdateRequest?: GlobalIssueSettingUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlobalIssueSettingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchGlobalIssueSettings(globalIssueSettingUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IssueSettingApi - factory interface
 * @export
 */
export const IssueSettingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IssueSettingApiFp(configuration)
    return {
        /**
         * Get global issue setting
         * @summary Get GlobalIssueSetting
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getGlobalIssueSetting(options?: any): AxiosPromise<GlobalIssueSettingResponse> {
            return localVarFp.getGlobalIssueSetting(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update GlobalIssueSetting
         * @param {GlobalIssueSettingUpdateRequest} [globalIssueSettingUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        patchGlobalIssueSettings(globalIssueSettingUpdateRequest?: GlobalIssueSettingUpdateRequest, options?: any): AxiosPromise<GlobalIssueSettingResponse> {
            return localVarFp.patchGlobalIssueSettings(globalIssueSettingUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IssueSettingApi - object-oriented interface
 * @export
 * @class IssueSettingApi
 * @extends {BaseAPI}
 */
export class IssueSettingApi extends BaseAPI {
    /**
     * Get global issue setting
     * @summary Get GlobalIssueSetting
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof IssueSettingApi
     */
    public getGlobalIssueSetting(options?: AxiosRequestConfig) {
        return IssueSettingApiFp(this.configuration).getGlobalIssueSetting(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update GlobalIssueSetting
     * @param {GlobalIssueSettingUpdateRequest} [globalIssueSettingUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof IssueSettingApi
     */
    public patchGlobalIssueSettings(globalIssueSettingUpdateRequest?: GlobalIssueSettingUpdateRequest, options?: AxiosRequestConfig) {
        return IssueSettingApiFp(this.configuration).patchGlobalIssueSettings(globalIssueSettingUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MobileClientVersionApi - axios parameter creator
 * @export
 */
export const MobileClientVersionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * check mobile client version
         * @summary Check mobile client version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkMobileClientVersion: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/common/check_client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MobileClientVersionApi - functional programming interface
 * @export
 */
export const MobileClientVersionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MobileClientVersionApiAxiosParamCreator(configuration)
    return {
        /**
         * check mobile client version
         * @summary Check mobile client version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkMobileClientVersion(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckMobileClientVersionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkMobileClientVersion(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MobileClientVersionApi - factory interface
 * @export
 */
export const MobileClientVersionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MobileClientVersionApiFp(configuration)
    return {
        /**
         * check mobile client version
         * @summary Check mobile client version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkMobileClientVersion(options?: any): AxiosPromise<CheckMobileClientVersionResponse> {
            return localVarFp.checkMobileClientVersion(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MobileClientVersionApi - object-oriented interface
 * @export
 * @class MobileClientVersionApi
 * @extends {BaseAPI}
 */
export class MobileClientVersionApi extends BaseAPI {
    /**
     * check mobile client version
     * @summary Check mobile client version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileClientVersionApi
     */
    public checkMobileClientVersion(options?: AxiosRequestConfig) {
        return MobileClientVersionApiFp(this.configuration).checkMobileClientVersion(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationSettingsApi - axios parameter creator
 * @export
 */
export const NotificationSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get NotificationSetting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notification_setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update NotificationSetting
         * @param {NotificationSettingsUpdateRequest} [notificationSettingsUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNotificationSettings: async (notificationSettingsUpdateRequest?: NotificationSettingsUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notification_setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationSettingsUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationSettingsApi - functional programming interface
 * @export
 */
export const NotificationSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get NotificationSetting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotificationSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update NotificationSetting
         * @param {NotificationSettingsUpdateRequest} [notificationSettingsUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchNotificationSettings(notificationSettingsUpdateRequest?: NotificationSettingsUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchNotificationSettings(notificationSettingsUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationSettingsApi - factory interface
 * @export
 */
export const NotificationSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationSettingsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get NotificationSetting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationSettings(options?: any): AxiosPromise<NotificationSettingsResponse> {
            return localVarFp.getNotificationSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update NotificationSetting
         * @param {NotificationSettingsUpdateRequest} [notificationSettingsUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNotificationSettings(notificationSettingsUpdateRequest?: NotificationSettingsUpdateRequest, options?: any): AxiosPromise<NotificationSettingsResponse> {
            return localVarFp.patchNotificationSettings(notificationSettingsUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationSettingsApi - object-oriented interface
 * @export
 * @class NotificationSettingsApi
 * @extends {BaseAPI}
 */
export class NotificationSettingsApi extends BaseAPI {
    /**
     * 
     * @summary Get NotificationSetting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationSettingsApi
     */
    public getNotificationSettings(options?: AxiosRequestConfig) {
        return NotificationSettingsApiFp(this.configuration).getNotificationSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update NotificationSetting
     * @param {NotificationSettingsUpdateRequest} [notificationSettingsUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationSettingsApi
     */
    public patchNotificationSettings(notificationSettingsUpdateRequest?: NotificationSettingsUpdateRequest, options?: AxiosRequestConfig) {
        return NotificationSettingsApiFp(this.configuration).patchNotificationSettings(notificationSettingsUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PanoramaApi - axios parameter creator
 * @export
 */
export const PanoramaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete panorama by id
         * @summary Delete Panorama
         * @param {number} propertyId 
         * @param {number} floorId 
         * @param {number} shotPointId 
         * @param {number} panoramaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePanorama: async (propertyId: number, floorId: number, shotPointId: number, panoramaId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('deletePanorama', 'propertyId', propertyId)
            // verify required parameter 'floorId' is not null or undefined
            assertParamExists('deletePanorama', 'floorId', floorId)
            // verify required parameter 'shotPointId' is not null or undefined
            assertParamExists('deletePanorama', 'shotPointId', shotPointId)
            // verify required parameter 'panoramaId' is not null or undefined
            assertParamExists('deletePanorama', 'panoramaId', panoramaId)
            const localVarPath = `/properties/{property_id}/floors/{floor_id}/shot_points/{shot_point_id}/panoramas/{panorama_id}`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)))
                .replace(`{${"floor_id"}}`, encodeURIComponent(String(floorId)))
                .replace(`{${"shot_point_id"}}`, encodeURIComponent(String(shotPointId)))
                .replace(`{${"panorama_id"}}`, encodeURIComponent(String(panoramaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get panorama by id.
         * @summary Get Panorama
         * @param {number} propertyId 
         * @param {number} floorId 
         * @param {number} shotPointId 
         * @param {number} panoramaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPanorama: async (propertyId: number, floorId: number, shotPointId: number, panoramaId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('getPanorama', 'propertyId', propertyId)
            // verify required parameter 'floorId' is not null or undefined
            assertParamExists('getPanorama', 'floorId', floorId)
            // verify required parameter 'shotPointId' is not null or undefined
            assertParamExists('getPanorama', 'shotPointId', shotPointId)
            // verify required parameter 'panoramaId' is not null or undefined
            assertParamExists('getPanorama', 'panoramaId', panoramaId)
            const localVarPath = `/properties/{property_id}/floors/{floor_id}/shot_points/{shot_point_id}/panoramas/{panorama_id}`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)))
                .replace(`{${"floor_id"}}`, encodeURIComponent(String(floorId)))
                .replace(`{${"shot_point_id"}}`, encodeURIComponent(String(shotPointId)))
                .replace(`{${"panorama_id"}}`, encodeURIComponent(String(panoramaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List Panorama histories of specified ShotPoint
         * @summary List Panorama Histories
         * @param {number} propertyId 
         * @param {number} floorId 
         * @param {number} shotPointId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPanoramaHistories: async (propertyId: number, floorId: number, shotPointId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('listPanoramaHistories', 'propertyId', propertyId)
            // verify required parameter 'floorId' is not null or undefined
            assertParamExists('listPanoramaHistories', 'floorId', floorId)
            // verify required parameter 'shotPointId' is not null or undefined
            assertParamExists('listPanoramaHistories', 'shotPointId', shotPointId)
            const localVarPath = `/properties/{property_id}/floors/{floor_id}/shot_points/{shot_point_id}/panoramas`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)))
                .replace(`{${"floor_id"}}`, encodeURIComponent(String(floorId)))
                .replace(`{${"shot_point_id"}}`, encodeURIComponent(String(shotPointId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PanoramaApi - functional programming interface
 * @export
 */
export const PanoramaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PanoramaApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete panorama by id
         * @summary Delete Panorama
         * @param {number} propertyId 
         * @param {number} floorId 
         * @param {number} shotPointId 
         * @param {number} panoramaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePanorama(propertyId: number, floorId: number, shotPointId: number, panoramaId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoneDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePanorama(propertyId, floorId, shotPointId, panoramaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get panorama by id.
         * @summary Get Panorama
         * @param {number} propertyId 
         * @param {number} floorId 
         * @param {number} shotPointId 
         * @param {number} panoramaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPanorama(propertyId: number, floorId: number, shotPointId: number, panoramaId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PanoramaDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPanorama(propertyId, floorId, shotPointId, panoramaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List Panorama histories of specified ShotPoint
         * @summary List Panorama Histories
         * @param {number} propertyId 
         * @param {number} floorId 
         * @param {number} shotPointId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPanoramaHistories(propertyId: number, floorId: number, shotPointId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PanoramaListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPanoramaHistories(propertyId, floorId, shotPointId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PanoramaApi - factory interface
 * @export
 */
export const PanoramaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PanoramaApiFp(configuration)
    return {
        /**
         * Delete panorama by id
         * @summary Delete Panorama
         * @param {number} propertyId 
         * @param {number} floorId 
         * @param {number} shotPointId 
         * @param {number} panoramaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePanorama(propertyId: number, floorId: number, shotPointId: number, panoramaId: number, options?: any): AxiosPromise<NoneDataResponse> {
            return localVarFp.deletePanorama(propertyId, floorId, shotPointId, panoramaId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get panorama by id.
         * @summary Get Panorama
         * @param {number} propertyId 
         * @param {number} floorId 
         * @param {number} shotPointId 
         * @param {number} panoramaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPanorama(propertyId: number, floorId: number, shotPointId: number, panoramaId: number, options?: any): AxiosPromise<PanoramaDetailResponse> {
            return localVarFp.getPanorama(propertyId, floorId, shotPointId, panoramaId, options).then((request) => request(axios, basePath));
        },
        /**
         * List Panorama histories of specified ShotPoint
         * @summary List Panorama Histories
         * @param {number} propertyId 
         * @param {number} floorId 
         * @param {number} shotPointId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPanoramaHistories(propertyId: number, floorId: number, shotPointId: number, options?: any): AxiosPromise<PanoramaListResponse> {
            return localVarFp.listPanoramaHistories(propertyId, floorId, shotPointId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PanoramaApi - object-oriented interface
 * @export
 * @class PanoramaApi
 * @extends {BaseAPI}
 */
export class PanoramaApi extends BaseAPI {
    /**
     * Delete panorama by id
     * @summary Delete Panorama
     * @param {number} propertyId 
     * @param {number} floorId 
     * @param {number} shotPointId 
     * @param {number} panoramaId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PanoramaApi
     */
    public deletePanorama(propertyId: number, floorId: number, shotPointId: number, panoramaId: number, options?: AxiosRequestConfig) {
        return PanoramaApiFp(this.configuration).deletePanorama(propertyId, floorId, shotPointId, panoramaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get panorama by id.
     * @summary Get Panorama
     * @param {number} propertyId 
     * @param {number} floorId 
     * @param {number} shotPointId 
     * @param {number} panoramaId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PanoramaApi
     */
    public getPanorama(propertyId: number, floorId: number, shotPointId: number, panoramaId: number, options?: AxiosRequestConfig) {
        return PanoramaApiFp(this.configuration).getPanorama(propertyId, floorId, shotPointId, panoramaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List Panorama histories of specified ShotPoint
     * @summary List Panorama Histories
     * @param {number} propertyId 
     * @param {number} floorId 
     * @param {number} shotPointId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PanoramaApi
     */
    public listPanoramaHistories(propertyId: number, floorId: number, shotPointId: number, options?: AxiosRequestConfig) {
        return PanoramaApiFp(this.configuration).listPanoramaHistories(propertyId, floorId, shotPointId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PriorityApi - axios parameter creator
 * @export
 */
export const PriorityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get priorities list of Issue
         * @summary List Issue Priority
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrioritiesListOfIssue: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/priorities/issue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PriorityApi - functional programming interface
 * @export
 */
export const PriorityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PriorityApiAxiosParamCreator(configuration)
    return {
        /**
         * get priorities list of Issue
         * @summary List Issue Priority
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrioritiesListOfIssue(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrioritiesListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrioritiesListOfIssue(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PriorityApi - factory interface
 * @export
 */
export const PriorityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PriorityApiFp(configuration)
    return {
        /**
         * get priorities list of Issue
         * @summary List Issue Priority
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrioritiesListOfIssue(options?: any): AxiosPromise<PrioritiesListResponse> {
            return localVarFp.getPrioritiesListOfIssue(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PriorityApi - object-oriented interface
 * @export
 * @class PriorityApi
 * @extends {BaseAPI}
 */
export class PriorityApi extends BaseAPI {
    /**
     * get priorities list of Issue
     * @summary List Issue Priority
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriorityApi
     */
    public getPrioritiesListOfIssue(options?: AxiosRequestConfig) {
        return PriorityApiFp(this.configuration).getPrioritiesListOfIssue(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PropertyApi - axios parameter creator
 * @export
 */
export const PropertyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Property
         * @param {PropertyCreateRequest} [propertyCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProperty: async (propertyCreateRequest?: PropertyCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/properties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(propertyCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Property
         * @param {number} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProperty: async (propertyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('deleteProperty', 'propertyId', propertyId)
            const localVarPath = `/properties/{property_id}`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Properties of SimpleEntity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertiesList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/simple_properties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get property
         * @summary Get Property
         * @param {number} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProperty: async (propertyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('getProperty', 'propertyId', propertyId)
            const localVarPath = `/properties/{property_id}`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all property directories
         * @param {number} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllPropertyDirectories: async (propertyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('listAllPropertyDirectories', 'propertyId', propertyId)
            const localVarPath = `/properties/{property_id}/directories`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Property Directories And Files
         * @param {number} propertyId 
         * @param {number} directoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPropertyDirectoriesAndFiles: async (propertyId: number, directoryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('listPropertyDirectoriesAndFiles', 'propertyId', propertyId)
            // verify required parameter 'directoryId' is not null or undefined
            assertParamExists('listPropertyDirectoriesAndFiles', 'directoryId', directoryId)
            const localVarPath = `/properties/{property_id}/directories/{directory_id}`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)))
                .replace(`{${"directory_id"}}`, encodeURIComponent(String(directoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Property Directory Bulk Operations
         * @param {number} propertyId 
         * @param {number} directoryId 
         * @param {PropertyDirectoryBulkOperationsRequest} [propertyDirectoryBulkOperationsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyDirectoryBulkOperations: async (propertyId: number, directoryId: number, propertyDirectoryBulkOperationsRequest?: PropertyDirectoryBulkOperationsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('propertyDirectoryBulkOperations', 'propertyId', propertyId)
            // verify required parameter 'directoryId' is not null or undefined
            assertParamExists('propertyDirectoryBulkOperations', 'directoryId', directoryId)
            const localVarPath = `/properties/{property_id}/directories/{directory_id}`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)))
                .replace(`{${"directory_id"}}`, encodeURIComponent(String(directoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(propertyDirectoryBulkOperationsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search Properties
         * @param {string} [name] Search in name and name. (e.g. ?name&#x3D;○○ビル, ?name&#x3D;まるまるびる)
         * @param {Array<number>} [assignee] Filter by assignee. If unspecified, no filtering.
         * @param {boolean} [isAssignedMe] 
         * @param {string} [orderBy] name or property_updated_status (Default is name)
         * @param {number} [page] Default is 1.
         * @param {number} [perPage] Default is 100. If it is less than 1, it returns all issues.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProperties: async (name?: string, assignee?: Array<number>, isAssignedMe?: boolean, orderBy?: string, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/properties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (assignee) {
                localVarQueryParameter['assignee'] = assignee.join(COLLECTION_FORMATS.csv);
            }

            if (isAssignedMe !== undefined) {
                localVarQueryParameter['is_assigned_me'] = isAssignedMe;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Property
         * @param {number} propertyId 
         * @param {PropertyUpdateRequest} [propertyUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProperty: async (propertyId: number, propertyUpdateRequest?: PropertyUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('updateProperty', 'propertyId', propertyId)
            const localVarPath = `/properties/{property_id}`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(propertyUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PropertyApi - functional programming interface
 * @export
 */
export const PropertyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PropertyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Property
         * @param {PropertyCreateRequest} [propertyCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProperty(propertyCreateRequest?: PropertyCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProperty(propertyCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Property
         * @param {number} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProperty(propertyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoneDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProperty(propertyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List Properties of SimpleEntity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPropertiesList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyListSimpleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPropertiesList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get property
         * @summary Get Property
         * @param {number} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProperty(propertyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProperty(propertyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List all property directories
         * @param {number} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAllPropertyDirectories(propertyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyDirectoryListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAllPropertyDirectories(propertyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List Property Directories And Files
         * @param {number} propertyId 
         * @param {number} directoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPropertyDirectoriesAndFiles(propertyId: number, directoryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyDirectoryDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPropertyDirectoriesAndFiles(propertyId, directoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Property Directory Bulk Operations
         * @param {number} propertyId 
         * @param {number} directoryId 
         * @param {PropertyDirectoryBulkOperationsRequest} [propertyDirectoryBulkOperationsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyDirectoryBulkOperations(propertyId: number, directoryId: number, propertyDirectoryBulkOperationsRequest?: PropertyDirectoryBulkOperationsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyDirectoryDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propertyDirectoryBulkOperations(propertyId, directoryId, propertyDirectoryBulkOperationsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search Properties
         * @param {string} [name] Search in name and name. (e.g. ?name&#x3D;○○ビル, ?name&#x3D;まるまるびる)
         * @param {Array<number>} [assignee] Filter by assignee. If unspecified, no filtering.
         * @param {boolean} [isAssignedMe] 
         * @param {string} [orderBy] name or property_updated_status (Default is name)
         * @param {number} [page] Default is 1.
         * @param {number} [perPage] Default is 100. If it is less than 1, it returns all issues.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchProperties(name?: string, assignee?: Array<number>, isAssignedMe?: boolean, orderBy?: string, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchProperties(name, assignee, isAssignedMe, orderBy, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Property
         * @param {number} propertyId 
         * @param {PropertyUpdateRequest} [propertyUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProperty(propertyId: number, propertyUpdateRequest?: PropertyUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProperty(propertyId, propertyUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PropertyApi - factory interface
 * @export
 */
export const PropertyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PropertyApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Property
         * @param {PropertyCreateRequest} [propertyCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProperty(propertyCreateRequest?: PropertyCreateRequest, options?: any): AxiosPromise<PropertyResponse> {
            return localVarFp.createProperty(propertyCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Property
         * @param {number} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProperty(propertyId: number, options?: any): AxiosPromise<NoneDataResponse> {
            return localVarFp.deleteProperty(propertyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Properties of SimpleEntity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertiesList(options?: any): AxiosPromise<PropertyListSimpleResponse> {
            return localVarFp.getPropertiesList(options).then((request) => request(axios, basePath));
        },
        /**
         * get property
         * @summary Get Property
         * @param {number} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProperty(propertyId: number, options?: any): AxiosPromise<PropertyDetailResponse> {
            return localVarFp.getProperty(propertyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all property directories
         * @param {number} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllPropertyDirectories(propertyId: number, options?: any): AxiosPromise<PropertyDirectoryListResponse> {
            return localVarFp.listAllPropertyDirectories(propertyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Property Directories And Files
         * @param {number} propertyId 
         * @param {number} directoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPropertyDirectoriesAndFiles(propertyId: number, directoryId: number, options?: any): AxiosPromise<PropertyDirectoryDetailResponse> {
            return localVarFp.listPropertyDirectoriesAndFiles(propertyId, directoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Property Directory Bulk Operations
         * @param {number} propertyId 
         * @param {number} directoryId 
         * @param {PropertyDirectoryBulkOperationsRequest} [propertyDirectoryBulkOperationsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyDirectoryBulkOperations(propertyId: number, directoryId: number, propertyDirectoryBulkOperationsRequest?: PropertyDirectoryBulkOperationsRequest, options?: any): AxiosPromise<PropertyDirectoryDetailResponse> {
            return localVarFp.propertyDirectoryBulkOperations(propertyId, directoryId, propertyDirectoryBulkOperationsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search Properties
         * @param {string} [name] Search in name and name. (e.g. ?name&#x3D;○○ビル, ?name&#x3D;まるまるびる)
         * @param {Array<number>} [assignee] Filter by assignee. If unspecified, no filtering.
         * @param {boolean} [isAssignedMe] 
         * @param {string} [orderBy] name or property_updated_status (Default is name)
         * @param {number} [page] Default is 1.
         * @param {number} [perPage] Default is 100. If it is less than 1, it returns all issues.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProperties(name?: string, assignee?: Array<number>, isAssignedMe?: boolean, orderBy?: string, page?: number, perPage?: number, options?: any): AxiosPromise<PropertyListResponse> {
            return localVarFp.searchProperties(name, assignee, isAssignedMe, orderBy, page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Property
         * @param {number} propertyId 
         * @param {PropertyUpdateRequest} [propertyUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProperty(propertyId: number, propertyUpdateRequest?: PropertyUpdateRequest, options?: any): AxiosPromise<PropertyResponse> {
            return localVarFp.updateProperty(propertyId, propertyUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PropertyApi - object-oriented interface
 * @export
 * @class PropertyApi
 * @extends {BaseAPI}
 */
export class PropertyApi extends BaseAPI {
    /**
     * 
     * @summary Create Property
     * @param {PropertyCreateRequest} [propertyCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyApi
     */
    public createProperty(propertyCreateRequest?: PropertyCreateRequest, options?: AxiosRequestConfig) {
        return PropertyApiFp(this.configuration).createProperty(propertyCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Property
     * @param {number} propertyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyApi
     */
    public deleteProperty(propertyId: number, options?: AxiosRequestConfig) {
        return PropertyApiFp(this.configuration).deleteProperty(propertyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Properties of SimpleEntity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyApi
     */
    public getPropertiesList(options?: AxiosRequestConfig) {
        return PropertyApiFp(this.configuration).getPropertiesList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get property
     * @summary Get Property
     * @param {number} propertyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyApi
     */
    public getProperty(propertyId: number, options?: AxiosRequestConfig) {
        return PropertyApiFp(this.configuration).getProperty(propertyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all property directories
     * @param {number} propertyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyApi
     */
    public listAllPropertyDirectories(propertyId: number, options?: AxiosRequestConfig) {
        return PropertyApiFp(this.configuration).listAllPropertyDirectories(propertyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Property Directories And Files
     * @param {number} propertyId 
     * @param {number} directoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyApi
     */
    public listPropertyDirectoriesAndFiles(propertyId: number, directoryId: number, options?: AxiosRequestConfig) {
        return PropertyApiFp(this.configuration).listPropertyDirectoriesAndFiles(propertyId, directoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Property Directory Bulk Operations
     * @param {number} propertyId 
     * @param {number} directoryId 
     * @param {PropertyDirectoryBulkOperationsRequest} [propertyDirectoryBulkOperationsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyApi
     */
    public propertyDirectoryBulkOperations(propertyId: number, directoryId: number, propertyDirectoryBulkOperationsRequest?: PropertyDirectoryBulkOperationsRequest, options?: AxiosRequestConfig) {
        return PropertyApiFp(this.configuration).propertyDirectoryBulkOperations(propertyId, directoryId, propertyDirectoryBulkOperationsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search Properties
     * @param {string} [name] Search in name and name. (e.g. ?name&#x3D;○○ビル, ?name&#x3D;まるまるびる)
     * @param {Array<number>} [assignee] Filter by assignee. If unspecified, no filtering.
     * @param {boolean} [isAssignedMe] 
     * @param {string} [orderBy] name or property_updated_status (Default is name)
     * @param {number} [page] Default is 1.
     * @param {number} [perPage] Default is 100. If it is less than 1, it returns all issues.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyApi
     */
    public searchProperties(name?: string, assignee?: Array<number>, isAssignedMe?: boolean, orderBy?: string, page?: number, perPage?: number, options?: AxiosRequestConfig) {
        return PropertyApiFp(this.configuration).searchProperties(name, assignee, isAssignedMe, orderBy, page, perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Property
     * @param {number} propertyId 
     * @param {PropertyUpdateRequest} [propertyUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyApi
     */
    public updateProperty(propertyId: number, propertyUpdateRequest?: PropertyUpdateRequest, options?: AxiosRequestConfig) {
        return PropertyApiFp(this.configuration).updateProperty(propertyId, propertyUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PropertyDirectoryMasterApi - axios parameter creator
 * @export
 */
export const PropertyDirectoryMasterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * bulk update property directory master
         * @summary Bulk Update Property Directory Master
         * @param {PropertyDirectoryMasterBulkUpdateRequest} [propertyDirectoryMasterBulkUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkUpdatePropertyDirectoryMaster: async (propertyDirectoryMasterBulkUpdateRequest?: PropertyDirectoryMasterBulkUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/property_directory_masters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(propertyDirectoryMasterBulkUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create property directory master
         * @summary Create Property Directory Master
         * @param {PropertyDirectoryMasterCreateRequest} [propertyDirectoryMasterCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPropertyDirectoryMaster: async (propertyDirectoryMasterCreateRequest?: PropertyDirectoryMasterCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/property_directory_masters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(propertyDirectoryMasterCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update property directory master
         * @summary Delete Property Directory Master
         * @param {number} directoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePropertyDirectoryMaster: async (directoryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'directoryId' is not null or undefined
            assertParamExists('deletePropertyDirectoryMaster', 'directoryId', directoryId)
            const localVarPath = `/property_directory_masters/{directory_id}`
                .replace(`{${"directory_id"}}`, encodeURIComponent(String(directoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list property directory masters
         * @summary List Property Directory Masters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPropertyDirectoryMasters: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/property_directory_masters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update property directory master
         * @summary Update Property Directory Master
         * @param {number} directoryId 
         * @param {PropertyDirectoryMasterUpdateRequest} [propertyDirectoryMasterUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePropertyDirectoryMaster: async (directoryId: number, propertyDirectoryMasterUpdateRequest?: PropertyDirectoryMasterUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'directoryId' is not null or undefined
            assertParamExists('updatePropertyDirectoryMaster', 'directoryId', directoryId)
            const localVarPath = `/property_directory_masters/{directory_id}`
                .replace(`{${"directory_id"}}`, encodeURIComponent(String(directoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(propertyDirectoryMasterUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PropertyDirectoryMasterApi - functional programming interface
 * @export
 */
export const PropertyDirectoryMasterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PropertyDirectoryMasterApiAxiosParamCreator(configuration)
    return {
        /**
         * bulk update property directory master
         * @summary Bulk Update Property Directory Master
         * @param {PropertyDirectoryMasterBulkUpdateRequest} [propertyDirectoryMasterBulkUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkUpdatePropertyDirectoryMaster(propertyDirectoryMasterBulkUpdateRequest?: PropertyDirectoryMasterBulkUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyDirectoryMasterBulkUpdateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkUpdatePropertyDirectoryMaster(propertyDirectoryMasterBulkUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create property directory master
         * @summary Create Property Directory Master
         * @param {PropertyDirectoryMasterCreateRequest} [propertyDirectoryMasterCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPropertyDirectoryMaster(propertyDirectoryMasterCreateRequest?: PropertyDirectoryMasterCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyDirectoryMasterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPropertyDirectoryMaster(propertyDirectoryMasterCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * update property directory master
         * @summary Delete Property Directory Master
         * @param {number} directoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePropertyDirectoryMaster(directoryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoneDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePropertyDirectoryMaster(directoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * list property directory masters
         * @summary List Property Directory Masters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPropertyDirectoryMasters(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyDirectoryMasterListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPropertyDirectoryMasters(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * update property directory master
         * @summary Update Property Directory Master
         * @param {number} directoryId 
         * @param {PropertyDirectoryMasterUpdateRequest} [propertyDirectoryMasterUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePropertyDirectoryMaster(directoryId: number, propertyDirectoryMasterUpdateRequest?: PropertyDirectoryMasterUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyDirectoryMasterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePropertyDirectoryMaster(directoryId, propertyDirectoryMasterUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PropertyDirectoryMasterApi - factory interface
 * @export
 */
export const PropertyDirectoryMasterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PropertyDirectoryMasterApiFp(configuration)
    return {
        /**
         * bulk update property directory master
         * @summary Bulk Update Property Directory Master
         * @param {PropertyDirectoryMasterBulkUpdateRequest} [propertyDirectoryMasterBulkUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkUpdatePropertyDirectoryMaster(propertyDirectoryMasterBulkUpdateRequest?: PropertyDirectoryMasterBulkUpdateRequest, options?: any): AxiosPromise<PropertyDirectoryMasterBulkUpdateResponse> {
            return localVarFp.bulkUpdatePropertyDirectoryMaster(propertyDirectoryMasterBulkUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * create property directory master
         * @summary Create Property Directory Master
         * @param {PropertyDirectoryMasterCreateRequest} [propertyDirectoryMasterCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPropertyDirectoryMaster(propertyDirectoryMasterCreateRequest?: PropertyDirectoryMasterCreateRequest, options?: any): AxiosPromise<PropertyDirectoryMasterResponse> {
            return localVarFp.createPropertyDirectoryMaster(propertyDirectoryMasterCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * update property directory master
         * @summary Delete Property Directory Master
         * @param {number} directoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePropertyDirectoryMaster(directoryId: number, options?: any): AxiosPromise<NoneDataResponse> {
            return localVarFp.deletePropertyDirectoryMaster(directoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * list property directory masters
         * @summary List Property Directory Masters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPropertyDirectoryMasters(options?: any): AxiosPromise<PropertyDirectoryMasterListResponse> {
            return localVarFp.listPropertyDirectoryMasters(options).then((request) => request(axios, basePath));
        },
        /**
         * update property directory master
         * @summary Update Property Directory Master
         * @param {number} directoryId 
         * @param {PropertyDirectoryMasterUpdateRequest} [propertyDirectoryMasterUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePropertyDirectoryMaster(directoryId: number, propertyDirectoryMasterUpdateRequest?: PropertyDirectoryMasterUpdateRequest, options?: any): AxiosPromise<PropertyDirectoryMasterResponse> {
            return localVarFp.updatePropertyDirectoryMaster(directoryId, propertyDirectoryMasterUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PropertyDirectoryMasterApi - object-oriented interface
 * @export
 * @class PropertyDirectoryMasterApi
 * @extends {BaseAPI}
 */
export class PropertyDirectoryMasterApi extends BaseAPI {
    /**
     * bulk update property directory master
     * @summary Bulk Update Property Directory Master
     * @param {PropertyDirectoryMasterBulkUpdateRequest} [propertyDirectoryMasterBulkUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyDirectoryMasterApi
     */
    public bulkUpdatePropertyDirectoryMaster(propertyDirectoryMasterBulkUpdateRequest?: PropertyDirectoryMasterBulkUpdateRequest, options?: AxiosRequestConfig) {
        return PropertyDirectoryMasterApiFp(this.configuration).bulkUpdatePropertyDirectoryMaster(propertyDirectoryMasterBulkUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create property directory master
     * @summary Create Property Directory Master
     * @param {PropertyDirectoryMasterCreateRequest} [propertyDirectoryMasterCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyDirectoryMasterApi
     */
    public createPropertyDirectoryMaster(propertyDirectoryMasterCreateRequest?: PropertyDirectoryMasterCreateRequest, options?: AxiosRequestConfig) {
        return PropertyDirectoryMasterApiFp(this.configuration).createPropertyDirectoryMaster(propertyDirectoryMasterCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update property directory master
     * @summary Delete Property Directory Master
     * @param {number} directoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyDirectoryMasterApi
     */
    public deletePropertyDirectoryMaster(directoryId: number, options?: AxiosRequestConfig) {
        return PropertyDirectoryMasterApiFp(this.configuration).deletePropertyDirectoryMaster(directoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list property directory masters
     * @summary List Property Directory Masters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyDirectoryMasterApi
     */
    public listPropertyDirectoryMasters(options?: AxiosRequestConfig) {
        return PropertyDirectoryMasterApiFp(this.configuration).listPropertyDirectoryMasters(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update property directory master
     * @summary Update Property Directory Master
     * @param {number} directoryId 
     * @param {PropertyDirectoryMasterUpdateRequest} [propertyDirectoryMasterUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyDirectoryMasterApi
     */
    public updatePropertyDirectoryMaster(directoryId: number, propertyDirectoryMasterUpdateRequest?: PropertyDirectoryMasterUpdateRequest, options?: AxiosRequestConfig) {
        return PropertyDirectoryMasterApiFp(this.configuration).updatePropertyDirectoryMaster(directoryId, propertyDirectoryMasterUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PublicApi - axios parameter creator
 * @export
 */
export const PublicApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get public info of facility
         * @param {number} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilityById: async (facilityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('getFacilityById', 'facilityId', facilityId)
            const localVarPath = `/public/facilities/{facility_id}`
                .replace(`{${"facility_id"}}`, encodeURIComponent(String(facilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get public info for issue
         * @param {number} issueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIssueById: async (issueId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issueId' is not null or undefined
            assertParamExists('getIssueById', 'issueId', issueId)
            const localVarPath = `/public/issues/{issue_id}`
                .replace(`{${"issue_id"}}`, encodeURIComponent(String(issueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get public info property
         * @param {number} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyById: async (propertyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('getPropertyById', 'propertyId', propertyId)
            const localVarPath = `/public/properties/{property_id}`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicApi - functional programming interface
 * @export
 */
export const PublicApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get public info of facility
         * @param {number} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFacilityById(facilityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicFacility>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFacilityById(facilityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get public info for issue
         * @param {number} issueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIssueById(issueId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicIssue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIssueById(issueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get public info property
         * @param {number} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPropertyById(propertyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicProperty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPropertyById(propertyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicApi - factory interface
 * @export
 */
export const PublicApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicApiFp(configuration)
    return {
        /**
         * 
         * @summary Get public info of facility
         * @param {number} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilityById(facilityId: number, options?: any): AxiosPromise<PublicFacility> {
            return localVarFp.getFacilityById(facilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get public info for issue
         * @param {number} issueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIssueById(issueId: number, options?: any): AxiosPromise<PublicIssue> {
            return localVarFp.getIssueById(issueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get public info property
         * @param {number} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyById(propertyId: number, options?: any): AxiosPromise<PublicProperty> {
            return localVarFp.getPropertyById(propertyId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublicApi - object-oriented interface
 * @export
 * @class PublicApi
 * @extends {BaseAPI}
 */
export class PublicApi extends BaseAPI {
    /**
     * 
     * @summary Get public info of facility
     * @param {number} facilityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public getFacilityById(facilityId: number, options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).getFacilityById(facilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get public info for issue
     * @param {number} issueId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public getIssueById(issueId: number, options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).getIssueById(issueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get public info property
     * @param {number} propertyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public getPropertyById(propertyId: number, options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).getPropertyById(propertyId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PvrObjectApi - axios parameter creator
 * @export
 */
export const PvrObjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Unlink PvrObject with Issue or Faciltiy
         * @summary Delete PvrObject
         * @param {number} propertyId 
         * @param {number} floorId 
         * @param {number} shotPointId 
         * @param {number} panoramaId 
         * @param {number} pvrObjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePvrObject: async (propertyId: number, floorId: number, shotPointId: number, panoramaId: number, pvrObjectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('deletePvrObject', 'propertyId', propertyId)
            // verify required parameter 'floorId' is not null or undefined
            assertParamExists('deletePvrObject', 'floorId', floorId)
            // verify required parameter 'shotPointId' is not null or undefined
            assertParamExists('deletePvrObject', 'shotPointId', shotPointId)
            // verify required parameter 'panoramaId' is not null or undefined
            assertParamExists('deletePvrObject', 'panoramaId', panoramaId)
            // verify required parameter 'pvrObjectId' is not null or undefined
            assertParamExists('deletePvrObject', 'pvrObjectId', pvrObjectId)
            const localVarPath = `/properties/{property_id}/floors/{floor_id}/shot_points/{shot_point_id}/panoramas/{panorama_id}/pvr_objects/{pvr_object_id}`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)))
                .replace(`{${"floor_id"}}`, encodeURIComponent(String(floorId)))
                .replace(`{${"shot_point_id"}}`, encodeURIComponent(String(shotPointId)))
                .replace(`{${"panorama_id"}}`, encodeURIComponent(String(panoramaId)))
                .replace(`{${"pvr_object_id"}}`, encodeURIComponent(String(pvrObjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PvrObjectApi - functional programming interface
 * @export
 */
export const PvrObjectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PvrObjectApiAxiosParamCreator(configuration)
    return {
        /**
         * Unlink PvrObject with Issue or Faciltiy
         * @summary Delete PvrObject
         * @param {number} propertyId 
         * @param {number} floorId 
         * @param {number} shotPointId 
         * @param {number} panoramaId 
         * @param {number} pvrObjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePvrObject(propertyId: number, floorId: number, shotPointId: number, panoramaId: number, pvrObjectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoneDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePvrObject(propertyId, floorId, shotPointId, panoramaId, pvrObjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PvrObjectApi - factory interface
 * @export
 */
export const PvrObjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PvrObjectApiFp(configuration)
    return {
        /**
         * Unlink PvrObject with Issue or Faciltiy
         * @summary Delete PvrObject
         * @param {number} propertyId 
         * @param {number} floorId 
         * @param {number} shotPointId 
         * @param {number} panoramaId 
         * @param {number} pvrObjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePvrObject(propertyId: number, floorId: number, shotPointId: number, panoramaId: number, pvrObjectId: number, options?: any): AxiosPromise<NoneDataResponse> {
            return localVarFp.deletePvrObject(propertyId, floorId, shotPointId, panoramaId, pvrObjectId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PvrObjectApi - object-oriented interface
 * @export
 * @class PvrObjectApi
 * @extends {BaseAPI}
 */
export class PvrObjectApi extends BaseAPI {
    /**
     * Unlink PvrObject with Issue or Faciltiy
     * @summary Delete PvrObject
     * @param {number} propertyId 
     * @param {number} floorId 
     * @param {number} shotPointId 
     * @param {number} panoramaId 
     * @param {number} pvrObjectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PvrObjectApi
     */
    public deletePvrObject(propertyId: number, floorId: number, shotPointId: number, panoramaId: number, pvrObjectId: number, options?: AxiosRequestConfig) {
        return PvrObjectApiFp(this.configuration).deletePvrObject(propertyId, floorId, shotPointId, panoramaId, pvrObjectId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SettingsApi - axios parameter creator
 * @export
 */
export const SettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get Global Settings - issue settings - facility settings
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGlobalSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/global`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Global Settings Perspectively - issue settings - facility settings
         * @summary 
         * @param {GlobalSettingPatchRequest} [globalSettingPatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchGlobalSettings: async (globalSettingPatchRequest?: GlobalSettingPatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/global`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(globalSettingPatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingsApi - functional programming interface
 * @export
 */
export const SettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get Global Settings - issue settings - facility settings
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGlobalSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlobalSettingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGlobalSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Global Settings Perspectively - issue settings - facility settings
         * @summary 
         * @param {GlobalSettingPatchRequest} [globalSettingPatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchGlobalSettings(globalSettingPatchRequest?: GlobalSettingPatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlobalSettingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchGlobalSettings(globalSettingPatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SettingsApi - factory interface
 * @export
 */
export const SettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingsApiFp(configuration)
    return {
        /**
         * Get Global Settings - issue settings - facility settings
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGlobalSettings(options?: any): AxiosPromise<GlobalSettingResponse> {
            return localVarFp.getGlobalSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * Get Global Settings Perspectively - issue settings - facility settings
         * @summary 
         * @param {GlobalSettingPatchRequest} [globalSettingPatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchGlobalSettings(globalSettingPatchRequest?: GlobalSettingPatchRequest, options?: any): AxiosPromise<GlobalSettingResponse> {
            return localVarFp.patchGlobalSettings(globalSettingPatchRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingsApi - object-oriented interface
 * @export
 * @class SettingsApi
 * @extends {BaseAPI}
 */
export class SettingsApi extends BaseAPI {
    /**
     * Get Global Settings - issue settings - facility settings
     * @summary Your GET endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public getGlobalSettings(options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).getGlobalSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Global Settings Perspectively - issue settings - facility settings
     * @summary 
     * @param {GlobalSettingPatchRequest} [globalSettingPatchRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public patchGlobalSettings(globalSettingPatchRequest?: GlobalSettingPatchRequest, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).patchGlobalSettings(globalSettingPatchRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShotPointApi - axios parameter creator
 * @export
 */
export const ShotPointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create ShotPoint
         * @summary Create ShotPoint
         * @param {number} propertyId 
         * @param {number} floorId 
         * @param {ShotPointCreateRequest} [shotPointCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createShotPoint: async (propertyId: number, floorId: number, shotPointCreateRequest?: ShotPointCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('createShotPoint', 'propertyId', propertyId)
            // verify required parameter 'floorId' is not null or undefined
            assertParamExists('createShotPoint', 'floorId', floorId)
            const localVarPath = `/properties/{property_id}/floors/{floor_id}/shot_points`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)))
                .replace(`{${"floor_id"}}`, encodeURIComponent(String(floorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shotPointCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete ShotPoint
         * @param {number} propertyId 
         * @param {number} floorId 
         * @param {number} shotPointId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteShotPoint: async (propertyId: number, floorId: number, shotPointId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('deleteShotPoint', 'propertyId', propertyId)
            // verify required parameter 'floorId' is not null or undefined
            assertParamExists('deleteShotPoint', 'floorId', floorId)
            // verify required parameter 'shotPointId' is not null or undefined
            assertParamExists('deleteShotPoint', 'shotPointId', shotPointId)
            const localVarPath = `/properties/{property_id}/floors/{floor_id}/shot_points/{shot_point_id}`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)))
                .replace(`{${"floor_id"}}`, encodeURIComponent(String(floorId)))
                .replace(`{${"shot_point_id"}}`, encodeURIComponent(String(shotPointId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get ShotPoint
         * @param {number} propertyId 
         * @param {number} floorId 
         * @param {number} shotPointId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShotPoint: async (propertyId: number, floorId: number, shotPointId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('getShotPoint', 'propertyId', propertyId)
            // verify required parameter 'floorId' is not null or undefined
            assertParamExists('getShotPoint', 'floorId', floorId)
            // verify required parameter 'shotPointId' is not null or undefined
            assertParamExists('getShotPoint', 'shotPointId', shotPointId)
            const localVarPath = `/properties/{property_id}/floors/{floor_id}/shot_points/{shot_point_id}`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)))
                .replace(`{${"floor_id"}}`, encodeURIComponent(String(floorId)))
                .replace(`{${"shot_point_id"}}`, encodeURIComponent(String(shotPointId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update ShotPoint
         * @param {number} propertyId 
         * @param {number} floorId 
         * @param {number} shotPointId 
         * @param {ShotPointUpdateRequest} [shotPointUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateShotPoint: async (propertyId: number, floorId: number, shotPointId: number, shotPointUpdateRequest?: ShotPointUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('updateShotPoint', 'propertyId', propertyId)
            // verify required parameter 'floorId' is not null or undefined
            assertParamExists('updateShotPoint', 'floorId', floorId)
            // verify required parameter 'shotPointId' is not null or undefined
            assertParamExists('updateShotPoint', 'shotPointId', shotPointId)
            const localVarPath = `/properties/{property_id}/floors/{floor_id}/shot_points/{shot_point_id}`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)))
                .replace(`{${"floor_id"}}`, encodeURIComponent(String(floorId)))
                .replace(`{${"shot_point_id"}}`, encodeURIComponent(String(shotPointId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shotPointUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShotPointApi - functional programming interface
 * @export
 */
export const ShotPointApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShotPointApiAxiosParamCreator(configuration)
    return {
        /**
         * Create ShotPoint
         * @summary Create ShotPoint
         * @param {number} propertyId 
         * @param {number} floorId 
         * @param {ShotPointCreateRequest} [shotPointCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createShotPoint(propertyId: number, floorId: number, shotPointCreateRequest?: ShotPointCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShotPointResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createShotPoint(propertyId, floorId, shotPointCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete ShotPoint
         * @param {number} propertyId 
         * @param {number} floorId 
         * @param {number} shotPointId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteShotPoint(propertyId: number, floorId: number, shotPointId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoneDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteShotPoint(propertyId, floorId, shotPointId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get ShotPoint
         * @param {number} propertyId 
         * @param {number} floorId 
         * @param {number} shotPointId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShotPoint(propertyId: number, floorId: number, shotPointId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShotPointResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShotPoint(propertyId, floorId, shotPointId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update ShotPoint
         * @param {number} propertyId 
         * @param {number} floorId 
         * @param {number} shotPointId 
         * @param {ShotPointUpdateRequest} [shotPointUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateShotPoint(propertyId: number, floorId: number, shotPointId: number, shotPointUpdateRequest?: ShotPointUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShotPointResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateShotPoint(propertyId, floorId, shotPointId, shotPointUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShotPointApi - factory interface
 * @export
 */
export const ShotPointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShotPointApiFp(configuration)
    return {
        /**
         * Create ShotPoint
         * @summary Create ShotPoint
         * @param {number} propertyId 
         * @param {number} floorId 
         * @param {ShotPointCreateRequest} [shotPointCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createShotPoint(propertyId: number, floorId: number, shotPointCreateRequest?: ShotPointCreateRequest, options?: any): AxiosPromise<ShotPointResponse> {
            return localVarFp.createShotPoint(propertyId, floorId, shotPointCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete ShotPoint
         * @param {number} propertyId 
         * @param {number} floorId 
         * @param {number} shotPointId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteShotPoint(propertyId: number, floorId: number, shotPointId: number, options?: any): AxiosPromise<NoneDataResponse> {
            return localVarFp.deleteShotPoint(propertyId, floorId, shotPointId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get ShotPoint
         * @param {number} propertyId 
         * @param {number} floorId 
         * @param {number} shotPointId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShotPoint(propertyId: number, floorId: number, shotPointId: number, options?: any): AxiosPromise<ShotPointResponse> {
            return localVarFp.getShotPoint(propertyId, floorId, shotPointId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update ShotPoint
         * @param {number} propertyId 
         * @param {number} floorId 
         * @param {number} shotPointId 
         * @param {ShotPointUpdateRequest} [shotPointUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateShotPoint(propertyId: number, floorId: number, shotPointId: number, shotPointUpdateRequest?: ShotPointUpdateRequest, options?: any): AxiosPromise<ShotPointResponse> {
            return localVarFp.updateShotPoint(propertyId, floorId, shotPointId, shotPointUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShotPointApi - object-oriented interface
 * @export
 * @class ShotPointApi
 * @extends {BaseAPI}
 */
export class ShotPointApi extends BaseAPI {
    /**
     * Create ShotPoint
     * @summary Create ShotPoint
     * @param {number} propertyId 
     * @param {number} floorId 
     * @param {ShotPointCreateRequest} [shotPointCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShotPointApi
     */
    public createShotPoint(propertyId: number, floorId: number, shotPointCreateRequest?: ShotPointCreateRequest, options?: AxiosRequestConfig) {
        return ShotPointApiFp(this.configuration).createShotPoint(propertyId, floorId, shotPointCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete ShotPoint
     * @param {number} propertyId 
     * @param {number} floorId 
     * @param {number} shotPointId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShotPointApi
     */
    public deleteShotPoint(propertyId: number, floorId: number, shotPointId: number, options?: AxiosRequestConfig) {
        return ShotPointApiFp(this.configuration).deleteShotPoint(propertyId, floorId, shotPointId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get ShotPoint
     * @param {number} propertyId 
     * @param {number} floorId 
     * @param {number} shotPointId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShotPointApi
     */
    public getShotPoint(propertyId: number, floorId: number, shotPointId: number, options?: AxiosRequestConfig) {
        return ShotPointApiFp(this.configuration).getShotPoint(propertyId, floorId, shotPointId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update ShotPoint
     * @param {number} propertyId 
     * @param {number} floorId 
     * @param {number} shotPointId 
     * @param {ShotPointUpdateRequest} [shotPointUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShotPointApi
     */
    public updateShotPoint(propertyId: number, floorId: number, shotPointId: number, shotPointUpdateRequest?: ShotPointUpdateRequest, options?: AxiosRequestConfig) {
        return ShotPointApiFp(this.configuration).updateShotPoint(propertyId, floorId, shotPointId, shotPointUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StaticFileApi - axios parameter creator
 * @export
 */
export const StaticFileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get gemba connect manual
         * @summary Get Gemba Connect manual
         * @param {number} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManual: async (organizationId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/common/document/usage/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StaticFileApi - functional programming interface
 * @export
 */
export const StaticFileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StaticFileApiAxiosParamCreator(configuration)
    return {
        /**
         * get gemba connect manual
         * @summary Get Gemba Connect manual
         * @param {number} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManual(organizationId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManual(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StaticFileApi - factory interface
 * @export
 */
export const StaticFileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StaticFileApiFp(configuration)
    return {
        /**
         * get gemba connect manual
         * @summary Get Gemba Connect manual
         * @param {number} [organizationId] organization_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManual(organizationId?: number, options?: any): AxiosPromise<GetDocumentResponse> {
            return localVarFp.getManual(organizationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StaticFileApi - object-oriented interface
 * @export
 * @class StaticFileApi
 * @extends {BaseAPI}
 */
export class StaticFileApi extends BaseAPI {
    /**
     * get gemba connect manual
     * @summary Get Gemba Connect manual
     * @param {number} [organizationId] organization_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaticFileApi
     */
    public getManual(organizationId?: number, options?: AxiosRequestConfig) {
        return StaticFileApiFp(this.configuration).getManual(organizationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StatusApi - axios parameter creator
 * @export
 */
export const StatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * create status
         * @summary Create Status
         * @param {StatusCreateRequest} [statusCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStatus: async (statusCreateRequest?: StatusCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statusCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete status
         * @summary Delete Status
         * @param {number} statusId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStatus: async (statusId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'statusId' is not null or undefined
            assertParamExists('deleteStatus', 'statusId', statusId)
            const localVarPath = `/status/{status_id}`
                .replace(`{${"status_id"}}`, encodeURIComponent(String(statusId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get status list
         * @summary List Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update status
         * @summary Update Status
         * @param {number} statusId 
         * @param {StatusUpdateRequest} [statusUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStatus: async (statusId: number, statusUpdateRequest?: StatusUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'statusId' is not null or undefined
            assertParamExists('updateStatus', 'statusId', statusId)
            const localVarPath = `/status/{status_id}`
                .replace(`{${"status_id"}}`, encodeURIComponent(String(statusId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statusUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatusApi - functional programming interface
 * @export
 */
export const StatusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatusApiAxiosParamCreator(configuration)
    return {
        /**
         * create status
         * @summary Create Status
         * @param {StatusCreateRequest} [statusCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStatus(statusCreateRequest?: StatusCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createStatus(statusCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete status
         * @summary Delete Status
         * @param {number} statusId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStatus(statusId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoneDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStatus(statusId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get status list
         * @summary List Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * update status
         * @summary Update Status
         * @param {number} statusId 
         * @param {StatusUpdateRequest} [statusUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStatus(statusId: number, statusUpdateRequest?: StatusUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStatus(statusId, statusUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StatusApi - factory interface
 * @export
 */
export const StatusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatusApiFp(configuration)
    return {
        /**
         * create status
         * @summary Create Status
         * @param {StatusCreateRequest} [statusCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStatus(statusCreateRequest?: StatusCreateRequest, options?: any): AxiosPromise<StatusResponse> {
            return localVarFp.createStatus(statusCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * delete status
         * @summary Delete Status
         * @param {number} statusId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStatus(statusId: number, options?: any): AxiosPromise<NoneDataResponse> {
            return localVarFp.deleteStatus(statusId, options).then((request) => request(axios, basePath));
        },
        /**
         * get status list
         * @summary List Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatus(options?: any): AxiosPromise<StatusListResponse> {
            return localVarFp.getStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * update status
         * @summary Update Status
         * @param {number} statusId 
         * @param {StatusUpdateRequest} [statusUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStatus(statusId: number, statusUpdateRequest?: StatusUpdateRequest, options?: any): AxiosPromise<StatusResponse> {
            return localVarFp.updateStatus(statusId, statusUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatusApi - object-oriented interface
 * @export
 * @class StatusApi
 * @extends {BaseAPI}
 */
export class StatusApi extends BaseAPI {
    /**
     * create status
     * @summary Create Status
     * @param {StatusCreateRequest} [statusCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public createStatus(statusCreateRequest?: StatusCreateRequest, options?: AxiosRequestConfig) {
        return StatusApiFp(this.configuration).createStatus(statusCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete status
     * @summary Delete Status
     * @param {number} statusId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public deleteStatus(statusId: number, options?: AxiosRequestConfig) {
        return StatusApiFp(this.configuration).deleteStatus(statusId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get status list
     * @summary List Status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public getStatus(options?: AxiosRequestConfig) {
        return StatusApiFp(this.configuration).getStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update status
     * @summary Update Status
     * @param {number} statusId 
     * @param {StatusUpdateRequest} [statusUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public updateStatus(statusId: number, statusUpdateRequest?: StatusUpdateRequest, options?: AxiosRequestConfig) {
        return StatusApiFp(this.configuration).updateStatus(statusId, statusUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get user division list
         * @summary Get UserDivisions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDivisions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/divisions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get user me
         * @summary Get User Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get user role list
         * @summary Get UserRoles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRoles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get user list
         * @summary Get Users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * get user division list
         * @summary Get UserDivisions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserDivisions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDivisionListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserDivisions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get user me
         * @summary Get User Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserMe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get user role list
         * @summary Get UserRoles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserRoles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRoleListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserRoles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get user list
         * @summary Get Users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * get user division list
         * @summary Get UserDivisions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDivisions(options?: any): AxiosPromise<UserDivisionListResponse> {
            return localVarFp.getUserDivisions(options).then((request) => request(axios, basePath));
        },
        /**
         * get user me
         * @summary Get User Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserMe(options?: any): AxiosPromise<UserResponse> {
            return localVarFp.getUserMe(options).then((request) => request(axios, basePath));
        },
        /**
         * get user role list
         * @summary Get UserRoles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRoles(options?: any): AxiosPromise<UserRoleListResponse> {
            return localVarFp.getUserRoles(options).then((request) => request(axios, basePath));
        },
        /**
         * get user list
         * @summary Get Users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(options?: any): AxiosPromise<UserListResponse> {
            return localVarFp.getUsers(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * get user division list
     * @summary Get UserDivisions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserDivisions(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserDivisions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get user me
     * @summary Get User Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserMe(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get user role list
     * @summary Get UserRoles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserRoles(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserRoles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get user list
     * @summary Get Users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUsers(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUsers(options).then((request) => request(this.axios, this.basePath));
    }
}


